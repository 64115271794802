import React from "react";
import Box from "@mui/material/Box";
import UserLayout from "../../../user";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { Avatar, Table, TableBody, TableCell, TableRow } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router";
import PDF from "../../../../assets/images/pdf.png";
import word from "../../../../assets/images/word.png";
import CSV from "../../../../assets/images/csv.png";
import AppointmentReschedule from "../../../../components/card-appointment/AppointmentReschedule";
import AppointmentSchedule from "../../../../components/card-appointment/AppointmentSchedule";
import {AppointmentAllStatusArray} from "../../../../services/utills";
import {content, heading, wallet} from "../../../../components/card-appointment/CardAppointment";


const AppointmentDetails = ({ appointmentDetails }) => {

  const navigate = useNavigate()
  const handleAppointmentClick = (url) => {
    navigate(url)
  }
  return (
    <Box sx={{ background: "#F1F5F9", padding: {sm:'20px', xs:'10px'} }}>
      {appointmentDetails?.plan?.id &&
        <Grid
          item
          xs={12}
          sx={{
            borderRadius: '7px',
            background: '#fff',
            p: '32px',
            mb: '32px',
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography
                variant="body2"
                color="initial"
                sx={{ color: "#000", textDecoration:'underline', fontSize: '16px', fontFamily: 'helvetica-lt-std-bold' }}
              >
                Bundle Details:
              </Typography>
              {appointmentDetails?.is_plan=== 0 ?(
 <Box sx={{ display: "flex", mt: 2 }}>
 <Typography
   variant="body2"
   color="initial"
   sx={{ 
     minWidth: '100px',
     fontSize: '16px',
     fontFamily: 'helvetica-lt-std-bold',
   }}
 >
   Bundle Name:
 </Typography>
 <Typography variant="body2" color="initial" sx={{
   ml: 1,
   fontSize: '16px',
   fontFamily: 'helvetica-lt-std-bold',
 }}>
   {appointmentDetails?.plan?.title}
 </Typography>
</Box>
              ):( <Box sx={{ display: "flex", mt: 2 }}>
              <Typography
                variant="body2"
                color="initial"
                sx={{
                  minWidth: '100px',
                  fontSize: '16px',
                  fontFamily: 'helvetica-lt-std-bold',
                }}
              >
                Session No:
              </Typography>
              <Typography variant="body2" color="initial" sx={{
                ml: 1,
                fontSize: '16px',
                fontFamily: 'helvetica-lt-std-bold',
              }}>
                {appointmentDetails?.current_session}
              </Typography>
             </Box>) }


             

              {/* appointmentDetails.current_session */}
              <Box sx={{ display: "flex", mt: 1 }}>
                <Typography
                  variant="body2"
                  color="initial"
                  sx={{
                    minWidth: '100px',
                    fontSize: '16px',
                    fontFamily: 'helvetica-lt-std-bold',
                  }}
                > 
                  Completed Session:
                </Typography>
                <Typography variant="body2" color="initial" sx={{
                  ml: 1,
                  fontSize: '16px',
                  fontFamily: 'helvetica-lt-std-bold',
                }}>
                  {appointmentDetails?.completed_session || 0} / {appointmentDetails?.plan?.no_of_bookings}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: '24px',
            }}
          >
            <Grid item lg={3.5} sm={3.5} xs={12} sx={{ borderRight: {sm:"2px dashed #9A9797", xs:"none"} }}>
              <Box>
                <Typography
                  variant="body2"
                  color="initial"
                  sx={{ color: "#000", textDecoration:'underline', fontSize: '16px', fontFamily: 'helvetica-lt-std-bold' }}
                >
                  Bundle Total Amount:
                </Typography>
                <Box sx={{ display: "flex", mt: 2 }}>
                  <Typography variant="body2" color="initial" sx={{ ml: 1 }}>
                    ${appointmentDetails?.plan_total_amount}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={3.5} sm={3.5} xs={12} sx={{ ml: {sm:5, xs:0}, borderRight: "2px dashed #9A9797" }}>
              <Box>
                <Typography
                  variant="body2"
                  color="initial"
                  sx={{ color: "#000", textDecoration:'underline', fontSize: '16px', fontFamily: 'helvetica-lt-std-bold' }}
                >
                  Received Amount:
                </Typography>
                <Box sx={{ display: "flex", mt: 2 }}>
                  <Typography variant="body2" color="initial" sx={{ ml: 1 }}>
                    ${appointmentDetails?.plan_received_amount}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={3.5} sm={3.5} xs={12}>
              <Box>
                <Typography
                  variant="body2"
                  color="initial"
                  sx={{ color: "#000", textDecoration:'underline', fontSize: '16px', fontFamily: 'helvetica-lt-std-bold' }}
                >
                  Remaining Amount:
                </Typography>
                <Box sx={{ display: "flex", mt: 2 }}>
                  <Typography variant="body2" color="initial" sx={{ ml: 1 }}>
                    ${appointmentDetails?.plan_remaining_amount}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <TableContainer
            component={Paper}
            sx={{
              mt: "24px",
            }}
          >
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Session No</TableCell>
                  <TableCell >Status</TableCell>
                  <TableCell >Date & Time</TableCell>
                  <TableCell >Booking Ref</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appointmentDetails?.related_appointments?.length > 0 &&
                  appointmentDetails?.related_appointments.map((row, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell scope="row">
                        <Box
                          sx={{
                            width: "max-content",
                            borderRadius: "8px",
                            p: '5px 10px',
                            border: `1px solid ${row?.status_color || 'black'}`,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            navigate(`/crm/appointment/details/${row?.id}`)
                          }}
                        >
                          <Typography
                            sx={{

                              color: row?.status_color || 'black',
                            }}
                          >
                            Session No {row.current_session}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell >
                        <Typography
                          sx={{
                            background: row?.status_color,
                            p: '2px 22px',
                            borderRadius: '13px',
                            color: '#fff',
                            ml: 1,
                            width:'130px',
                            // minWidth:'50px'
                          }}
                        >
                          {row.status}
                        </Typography>
                      </TableCell>
                      <TableCell >
                        <Typography
                          sx={{
                            color: row.date ? '#000' : '#F25022',
                          }}
                        >
                          {row.date ? moment(row.date).format('MMMM DD, YYYY') :
                              <Grid item lg={10} xl={8} sm={10} md={10} xs={12}>
                                <AppointmentSchedule fwidth={'100%'} appointment={row} buttonText="Book Now" />
                              </Grid>
                          }
                        </Typography>
                        <Typography>
                          {row.date ? row.start_time + " - " + row.end_time : ''}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            width: "max-content",
                            borderRadius: "8px",
                            p: '5px 10px',
                            border: `1px solid ${row?.status_color || 'black'}`,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            navigate(`/crm/appointment/details/${row?.id}`)
                          }}
                        >
                          <Typography
                            sx={{
                              color: row?.status_color || 'black',
                            }}
                          >
                            Booking Ref {row?.id}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      }
      <UserLayout> 
         <Typography
              variant="body2"
              color="initial"
              sx={{
                  color: "#56CCF2",
                  fontSize: '16px',
                  fontFamily: 'helvetica-lt-std-bold'
              }}
              >
                {appointmentDetails?.provider?.name}
              
              </Typography>
        <Box style={{display:"flex",alignItems:"right",marginTop:"1px"}}>


          <Typography
              variant="body2"
              color="initial"
              sx={{
                color: "#000",
                fontSize: '18px',
                fontFamily: 'helvetica-lt-std-bold'
              }}
          >
            Reference Clinic:
          </Typography>
          <Typography
              variant="body2"
              color="initial"
              sx={{
                ml: 1,
                color: "#56CCF2",
                fontSize: '16px',
                fontFamily: 'helvetica-lt-std-bold'
              }}
          >
            {appointmentDetails?.reference_clinic_name}
          </Typography>
        </Box>

        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item lg={4} sm={7} sx={{ borderRight: {sm:"2px dashed #9A9797", xs:"none"}}}>
            <Box>
              <Typography
                variant="body2"
                color="initial"
                sx={{ color: "#000",  textDecoration:'underline',}}
              >
                Appointment Date:
              </Typography>
              <Typography
                
                color="initial"
                sx={{
                  mt: 1,
                  fontSize:{sm:'30px', xs:'20px'},
                  fontFamily: "helvetica-lt-std-bold",
                  color: appointmentDetails.date ? '#000' : '#F25022',
                }}
              >
                {appointmentDetails?.date ? moment(appointmentDetails?.date).format("dddd MMMM D, YYYY") : 'Date and Time Not Set'}
              </Typography>
              {appointmentDetails?.date && <Typography
                variant="h5"
                color="initial"
                sx={{ fontFamily: "helvetica-lt-std-bold" }}
              >
                {appointmentDetails?.start_time} -{" "}
                {appointmentDetails?.end_time}
              </Typography>}
              <Box sx={{ display: "flex", mt: 1 }}>
                <Typography variant="body2" color="initial">
                  Created On:
                </Typography>
                <Typography variant="body2" color="initial">
                  {moment(appointmentDetails?.created_at).format("D MMM, YYYY")} {" "}
                  ({moment(appointmentDetails?.created_at).format("hh:mm A")})
                </Typography>
              </Box>
              <Box sx={{ display: "flex", mt: 1 }}>
                <Typography variant="body2" color="initial">
                  Created By:
                </Typography>
                <Typography variant="body2" color="initial">
                  {appointmentDetails?.created_by}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={appointmentDetails?.plan?.id ? 3.7 : 3.1} xs={12} sm={ 4.5} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <Typography
                variant="body2"
                color="initial"
                sx={{ color: "#000", textDecoration:'underline', fontSize: '16px', fontFamily: 'helvetica-lt-std-bold', mt:1 }}
              >
                Booking Details:
              </Typography>
              <Box sx={{ display: "flex", mt: "4px",cursor: 'pointer' }}
                   onClick={() => handleAppointmentClick(`/crm/appointment/details/${appointmentDetails?.id}`)}

              > 
                <Typography variant="body2" color="initial">
                  Booking ID:
                </Typography>
                <Typography variant="body2" color="initial" sx={{ ml: 1 }} style={{ color: '#2F80ED' }}>
                  #{appointmentDetails?.id}
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Typography variant="body2" color="initial">
                  Invoice ID :
                </Typography>
                <Box sx={{ display: 'flex',cursor: 'pointer' }}
                     onClick={() => handleAppointmentClick(`/crm/invoices/${appointmentDetails?.id}`)}
                >
                  {appointmentDetails?.invoices?.invoices_methods?.length > 0 &&
                    <Typography variant="body2" color="initial" sx={{ ml: 1 }} style={{ color: '#2F80ED' }}>
                      {appointmentDetails?.invoices?.invoices_methods[0]?.id}
                    </Typography>
                  }
                </Box>
              </Box>

              {appointmentDetails?.plan?.id &&
                  <Box sx={{ display: "flex", mt: 2 }}>
                <Typography variant="body2" color="initial" >
                  Booking Status:
                </Typography>
                <Typography 
                  variant="body2"
                  sx={{
                    background: appointmentDetails?.status_color,
                    p: '2px 24px',
                    borderRadius: '13px',
                    color: '#fff',
                    ml: 1,
                    height:"27px",
                    display:'flex',
                    alignItems:'center'

                  }}

                >
                  {appointmentDetails?.status === 'NoShow' ? "No Show" : appointmentDetails?.status}
                </Typography>
              </Box>
              }
            </Box>

          </Grid>
          <Grid
            item
            lg={4}
            
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box>
              <Typography
                variant="body2"
                color="initial"
                sx={{ color: "#000", textDecoration:'underline', fontSize: '16px', fontFamily: 'helvetica-lt-std-bold',mt:1 }}
              >
                Payment Details:
              </Typography>
              <Box sx={{ display: "flex", mt: "4px" ,alignItems:'center'}}>
                <Typography variant="body2" color="initial">
                  Payment Status:
                </Typography>
                <Typography variant="body2" color="initial" sx={{
                  // ml: 1,  
                  background: appointmentDetails?.payment_status === 'Paid' || appointmentDetails?.payment_status === 'Pre Paid' ? "#1C7E46" : '#C64949',
                  borderRadius: '13px',
                  color: '#fff', padding: "2px 13px",display:'flex',alignItems:'center',maxHeight:'40px',
                }}> 
                  {appointmentDetails?.payment_status}
                </Typography> 
              </Box>
              <Box sx={{ display: "flex" }}>
                <Typography variant="body2" color="initial" sx={{ fontFamily: 'helvetica-lt-std-bold' }}>
                  Transaction Id
                </Typography>
                <Typography variant="body2" color="initial" sx={{ ml: 1, fontFamily: 'helvetica-lt-std-bold' }}>
                {appointmentDetails?.invoices?.transaction_id ? appointmentDetails.invoices.transaction_id : 'N/A'}

                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Typography variant="body2" color="initial" sx={{ fontFamily: 'helvetica-lt-std-bold' }}>
                  Grand Total
                </Typography>
                <Typography variant="body2" color="initial" sx={{ ml: 1, fontFamily: 'helvetica-lt-std-bold' }}>
                  ${appointmentDetails?.grand_total}
                </Typography>
              </Box>
              <Box sx={{ display: "flex",alignItems:"baseline" }}>
                <Typography variant="body2" color="initial" >
                  Booking Status:
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                      background: appointmentDetails?.status_color,
                      p: '2px 16px',
                      borderRadius: '13px',
                      color: '#fff',
                      ml: 1,
                      height:"27px",  
                      display:'flex',
                      alignItems:'center'

                    }}

                >
                  {appointmentDetails?.status === 'NoShow' ? "No Show" : appointmentDetails?.status}
                </Typography>
              </Box>
            </Box>

            {appointmentDetails?.plan?.id && <Box
              sx={{
                ml: "10px",
                width: "max-content",
                height: "max-content",
                borderRadius: "8px",
                p: '10px 10px',
                mt:1,
                border: `1px solid ${appointmentDetails?.status_color || 'black'}`,
              }}
            >
              <Typography
                sx={{
                  color: appointmentDetails?.status_color || 'black',
                  textAlign: "center"
                }}
              >
                Session No
              </Typography>
              <Typography
                sx={{
                  color: appointmentDetails?.status_color || 'black',
                  textAlign: 'center',
                }}
              >
                {appointmentDetails.current_session}
              </Typography>
            </Box>}

          </Grid>

        </Grid>
        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between", mt: 7 }}
        >
          <Grid item lg={2.9}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {!appointmentDetails?.plan?.id && <Typography
                variant="body2"
                color="initial"
                sx={{ color: "#000", textDecoration:'underline', fontFamily: 'helvetica-lt-std-bold' }}
              >
                Services Details:
              </Typography>}
            </Box>
          </Grid>
          <Grid
            lg={9}
            item
            sx={{ borderBottom: "1px solid #C4C4C4", }}
          >
            {appointmentDetails?.services?.length > 0 && appointmentDetails?.services.map((service, servicesKey) => (
              <Grid container sx={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', mb: 2 }}
                key={servicesKey}>
                  {appointmentDetails?.is_plan === 0 ?
                  (
                    <>
                  <Grid item lg={5}>
                  <Typography variant="body2" color="initial" sx={{ ml: 1 }}>
                   {service?.name}
                    {appointmentDetails?.type === "Consultation"
                        ? " (In Person Consultation)"
                        : appointmentDetails?.type === "Virtual Consultation"
                            ? " (Virtual)"
                            : ''}
                </Typography>
                  </Grid>
                  <Grid item lg={4} sx={{ display:'flex',justifyContent:'center' }}>
                  <Typography variant="body2" color="initial" sx={{ ml: 1 }}>
                      {service?.service_quantity}
                </Typography>
                  </Grid>
                <Grid item lg={3} sx={{ display:'flex',justifyContent:'end' }}>
                {service?.extra_quantity !== null && (
                        <Typography variant="body2" color="initial" sx={{ ml: 1 }}>
                          Extra Quantity({service?.extra_quantity})
                        </Typography>
                    )}
                  <Typography variant="body2" color="initial" sx={{ ml: 1 }}>
                  ${service?.booking_cost}
                </Typography>
                </Grid>
                   
                    </>
                 
                  ):""
                  }
               
                {/*<Typography variant="body2" color="initial" sx={{ml: 1}}>*/}
                {/*  {service?.service_quantity} */}
                {/*</Typography>*/}
              
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between", }}
        >
          <Grid item lg={2.9}>
            <Box sx={{ display: "flex", flexDirection: "column", mt:2}}>
              <Typography
                variant="body2"
                color="initial"
                sx={{ color: "#000", textDecoration:'underline', fontFamily: 'helvetica-lt-std-bold' }}
              >
                Payment Details: 
              </Typography>
            </Box>
          </Grid>
          <Grid lg={9} xs={12} item
            sx={{ display: 'flex', justifyContent: 'end', flexDirection: 'column', alignItems: {sm:'end', xs:'end'},  }}>
            {appointmentDetails.type === "Consultation" &&
            <Box sx={{ display: "flex", mt: 2 }}>
              <Typography variant="body2" color="initial">
                Deposit Amount:
              </Typography>
              <Typography
                variant="body2"
                color="initial"
                sx={{ ml: 1 }}
              >
                  {appointmentDetails?.upfront_amount !== undefined
        ? `$${appointmentDetails?.upfront_amount}`
        : "$0"}
                {/* ${appointmentDetails?.services_total_cost} */}
              </Typography>
            </Box>}

            <Box sx={{ display: "flex", mt: 2 }}>
              <Typography variant="body2" color="initial">
                Sub Total:
              </Typography>
              <Typography
                variant="body2"
                color="initial"
                sx={{ ml: 1 }}
              >
                  {appointmentDetails?.services_total_cost !== undefined
      ? `$${appointmentDetails?.services_total_cost}`
      : "$0"}
                {/* ${appointmentDetails?.services_total_cost} */}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", mt: 1 }}>
              <Typography variant="body2" color="initial">
                Discount:    
              </Typography>
              <Typography
                variant="body2"
                color="initial"
                sx={{ ml: 1 }}
              >
                {/* {appointmentDetails?.invoices?.discount && parseFloat(appointmentDetails?.invoices?.discount) > 0 ? "-" : ''} ${appointmentDetails?.invoices?.discount} */}
                {appointmentDetails?.invoices?.discount && parseFloat(appointmentDetails?.invoices?.discount) > 0
      ? `-$${appointmentDetails?.invoices?.discount}`
      : "$0"}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", mt: 2 }}>
              <Typography sx={{ fontFamily: 'helvetica-lt-std-bold' }} variant="body2" color="initial">
                Amount:
              </Typography>
              <Typography
                variant="body2"
                color="initial"
                sx={{ ml: 1, fontFamily: 'helvetica-lt-std-bold' }}
              >
                {/* ${appointmentDetails?.invoices?.amount_after_add_discount} */}
                {appointmentDetails?.invoices?.amount_after_add_discount !== undefined
      ? `$${appointmentDetails?.invoices?.amount_after_add_discount}`
      : "$0"}    
              </Typography>  
            </Box>
            <Box sx={{ display: "flex", mt: 1 }}>
              <Typography variant="body2" color="initial">
                Tax:
              </Typography>
              <Typography
                variant="body2"
                color="initial"
                sx={{ ml: 1 }}
              >
                 {appointmentDetails?.invoices?.tax !== undefined
      ? `$${appointmentDetails?.invoices?.tax}`
      : "$0"}
                {/* ${appointmentDetails?.invoices?.tax} */}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", mt: 1 }}>
              <Typography variant="body2" color="initial">
                Tip:
              </Typography>
              <Typography   
                variant="body2"
                color="initial"
                sx={{ ml: 1 }}
              >
                  {appointmentDetails?.invoices?.tip !== undefined
      ? `$${appointmentDetails?.invoices?.tip}`
      : "$0"}
                {/* ${appointmentDetails?.invoices?.tip} */}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                mt: 1,
                width: '100%',
                justifyContent: 'flex-end',
                borderBottom: "1px solid #C4C4C4",
                paddingBottom: '10px',
              }}>
              <Typography sx={{ fontFamily: 'helvetica-lt-std-bold' }} variant="body2" color="initial">
                Grand Total:
              </Typography>
              <Typography
                variant="body2"
                color="initial"
                sx={{ ml: 1, fontFamily: 'helvetica-lt-std-bold' }}
              >
                {appointmentDetails?.invoices?.amount_after_tip_and_tax !== undefined
      ? `$${appointmentDetails?.invoices?.amount_after_tip_and_tax}`
      : "$0"}
              
                {/* ${appointmentDetails?.invoices?.amount_after_tip_and_tax} */}
              </Typography>
            </Box>
  

            {appointmentDetails?.invoices?.invoices_methods?.map((method, i) => (
              <Box sx={{ display: "flex", mt: 1 }} key={i}>
                <Typography variant="body2" color="initial">
                  {method?.payment_methods}:
                </Typography>
                <Typography
                  variant="body2" 
                  color="initial"
                  sx={{ ml: 1 }}  
                >
                  {method?.payment_methods !== 'Future Payment' && "-"} ${method?.amount}
                </Typography>
              </Box>
            ))}
            <Box
              sx={{
                display: "flex",
                mt: 1,
                width: '100%',
                justifyContent: 'flex-end',
                paddingBottom: '10px',
              }}>
              <Typography sx={{ fontFamily: 'helvetica-lt-std-bold' }} variant="body2" color="initial">
                Due Amount:
              </Typography>
              <Typography
                variant="body2"
                color="initial"
                sx={{ ml: 1, fontFamily: 'helvetica-lt-std-bold' }}
              >
                 {appointmentDetails?.due_amount !== undefined
      ? `$${appointmentDetails?.due_amount}`
      : "$0"}
                {/* ${appointmentDetails?.due_amount} */}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {appointmentDetails?.role_id !== 3 && (
                <>
                  <Grid container sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
                    <Grid item lg={2.9}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="body2" sx={{ color: "#000", textDecoration: 'underline', fontFamily: 'helvetica-lt-std-bold' }}>
                          Notes:
                        </Typography>



                        <Typography variant="body2" sx={{ mt:2 }}>
                          Customer Notes
                        </Typography>
                      </Box>
                    </Grid>      
                    <Grid item lg={9}>
                    <Box sx={{ mt: "38px" }}>
  {appointmentDetails?.appointment_customer_note && typeof appointmentDetails.appointment_customer_note === 'object' && appointmentDetails.appointment_customer_note !== null ? (
    <Box  sx={{ mt: 1 }}>
     
      <Typography variant="body2">     <span style={{ fontFamily: "helvetica-lt-std-bold" }}>
                      [{moment(appointmentDetails.appointment_customer_note?.staff?.created_at).format('h:mm A')}, {moment(appointmentDetails.appointment_customer_note?.staff?.created_at).format('YYYY-MM-D')}] {appointmentDetails.appointment_customer_note?.staff?.name}:
                    </span>  {appointmentDetails.appointment_customer_note.description}</Typography>
    </Box>
  ) : (
    <Typography variant="body2" style={{ fontFamily: "helvetica-lt-std-bold"}}>
      None
    </Typography>
  )} 
</Box>

                       
                    </Grid>
                  </Grid>
{/* <Grid container sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                    <Grid item lg={2.9}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          Staff Notes
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={9}>
                      <Box sx={{ mt: '8px' }}>
                        {appointmentDetails?.notes?.Staff?.length > 0 ?
                            appointmentDetails?.notes?.Staff.map((staff, staffIndex) => (
                                <Box key={staffIndex} sx={{ mt: 1 }}>
                                  <Typography variant="body2">
                    <span style={{ fontFamily: "helvetica-lt-std-bold" }}>
                      [{moment(staff?.created_at).format('h:mm A')}, {moment(staff?.created_at).format('YYYY-MM-D')}] {staff?.staff?.name}:
                    </span>
                                    {staff?.description}
                                  </Typography>
                                </Box>
                            ))
                            :
                            <Typography variant="body2">
                              <span style={{ fontFamily: "helvetica-lt-std-bold" }}>None</span>
                            </Typography>
                        }
                      </Box>
                    </Grid>
                  </Grid> */}
                  <Grid container sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                    <Grid item lg={2.9}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          Medical/Staff Notes
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={9}>
                      {appointmentDetails?.notes?.Medical?.length > 0 ?
                          appointmentDetails?.notes?.Medical.map((medical, medicalIndex) => (
                              <Box key={medicalIndex} sx={{ }}>
                                <Typography variant="body2">
                  <span style={{ fontFamily: "helvetica-lt-std-bold" }}>
                    [{moment(medical?.created_at).format('h:mm A')}, {moment(medical?.created_at).format('YYYY-MM-D')}] {medical?.staff?.name}:
                  </span>
                                  {medical?.description}
                                </Typography>
                              </Box>
                          ))
                          :
                          <Typography variant="body2" sx={{ marginTop: '8px'  }}>
                            <span style={{ fontFamily: "helvetica-lt-std-bold" }}>None</span>
                          </Typography>
                      }
                    </Grid>
                  </Grid>
                </>
            )
        }

        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
        >
          <Grid item lg={2.9}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body2"
                color="initial"
                sx={{ mt: 1, color: "#000", textDecoration:'underline', fontFamily: 'helvetica-lt-std-bold' }}
              >
                Attachment
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={9} xs={12}>
            {appointmentDetails?.attachments?.length > 0 ?
              appointmentDetails?.attachments.map((attachment, attactmentIndex) => (
                <Box sx={{ display: "flex", gap: '10px',flexWrap:{sm:'nowrap', xs:'wrap',lg:'wrap',md:'wrap'} }} key={attactmentIndex}>
                  <Box sx={{ display: 'flex', flexWrap:{sm:'nowrap', xs:'wrap',lg:'wrap',md:'wrap'} }}>
                    {attachment?.files?.length && attachment?.files?.map((file, fileIndex) => (
                      <Avatar
                          key={fileIndex}
                          src={file?.type === 'pdf' && PDF || file?.type === 'word' && word || file?.type === 'csv' && CSV || file?.file}
                          onClick={()=>window.open(file?.file,'_blank')}
                          sx={{
                            cursor:'pointer',
                            height: '180px',
                            width: '180px',
                            border:'1px solid #E8E8E8',
                          }}
                          variant="rounded"
                      />
                    ))}
                  </Box>


                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      flexWrap:{sm:'nowrap', xs:'wrap'}
                    }}
                  >
                    <Box sx={{ display: "flex", ml: 2 }}>
                      <Typography
                          variant="body1"
                          color="initial"
                          sx={{ mt: 1,mb: 1, position: "relative",  }}
                      >
                        <i> {moment(attachment?.created_at)?.format('YYYY-MM-DD hh:mm A')}</i>
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", ml: 2 }}>
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{
                          fontFamily: "helvetica-lt-std-bold",
                          background: "#F2F2F2",
                          borderRadius: "3.49275px",
                          maxHeight: '25px',
                        }}
                      >

                        Comments:
                      </Typography>
                      <Box>
                        <Typography variant="body1" color="initial" sx={{ ml: 1 }}>
                          {attachment?.comment}
                        </Typography>
                        {/*<Box sx={{ display:'flex', ml: 2, mt:1 }}>*/}
                        {/*  <Typography variant="body2" color="initial" sx={{ fontFamily:'helvetica-lt-std-bold' }}>*/}
                        {/*    Edit*/}
                        {/*  </Typography>*/}
                        {/*  <Typography variant="body2" color="initial" sx={{ ml:1, fontFamily:'helvetica-lt-std-bold' }}>*/}
                        {/*    Delete*/}
                        {/*  </Typography>*/}
                        {/*</Box>*/}
                      </Box>

                    </Box>
                  </Box>
                </Box>
              ))
              :
              <Box>
                <Typography variant="body2" color="initial" sx={{ marginTop: '8px'  }}>
                  <span style={{ fontFamily: "helvetica-lt-std-bold" }}></span>
                  None
                </Typography>
              </Box>
            }
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
        >
          <Grid item lg={2.9}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body2" color="initial" sx={{ mt: 1, fontFamily: 'helvetica-lt-std-bold' }}>
                Assigned Staff Member
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={9}>
            <Box>
              <Typography variant="body2" color="initial" sx={{ mt: 1, textTransform: 'capitalize' }}>
                {appointmentDetails?.staff?.id ? appointmentDetails?.staff?.name : 'Not Assign Yet'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </UserLayout>
    </Box>
  );
};

export default AppointmentDetails;
