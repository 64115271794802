import { Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Modal, Radio, TextField, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { RxCross1 } from "react-icons/rx";
import CustomButtoncolor from '../custom-button/customColorButton';
// import { sendReminder } from '../../store/crm/pos';
import { sendReminder, voidpassword } from '../../store/crm/pos';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { useFormik } from 'formik';
// import {inputStyle, labelStyle} from "../../components/form/formInput";
import HttpsIcon from '@mui/icons-material/Https';
import { successConfirmation } from '../../services/utills';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',

    borderRadius: '6px',
    '@media (max-width: 480px)': {
        width: "296px",
    },
};
const innerbox = {
    p: '27px 12px',
}
const wrapper = {
    alignItems: "center",
}
const heading = {
    fontSize: "18px",
    fontFamily: 'helvetica-lt-std-roman',
    fontWeight: '400',
    textAlign: "center",
    width: '100%',
    fontWeight: "bold",
    color: "white"

}
export const inputStyle = {
    background: "#EDF2F7",
    borderRadius: '4px',
    ".MuiOutlinedInput-notchedOutline": {
        borderWidth: "0!important"
    },
    ".MuiInputBase-input": {
        borderColor: " #EDF2F7",
        height: "19px",
        minHeight: 'unset',
        "&:focus": {
            borderColor: "#EDF2F7",
            outline: "none",
            "input": {
                height: '19px'
            }
        },
        "&:hover": {
            borderColor: "#EDF2F7",
            outline: "none",
        }
    }
}

const PasswordEditModal = ({id}) => {
    console.log("invoice id",id)
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(""); 
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [message, setMessage] = useState("");
    const validate = (values) => {
        const errors = {};

        if (!values.password) {
            errors.password = "Password is required";
        }

        return errors;
    };

     
    const formik = useFormik({
        initialValues: {
          password: "",
            
        },
        validate,
        onSubmit: async (values) => {
            try {
                const finalPayload = {
                    invoice_id: id,
                    password: values.password,
                };
                setLoading(true)
    
                let {payload} = await dispatch(voidpassword(finalPayload));
                handleClose();
                if (payload.success ==='true'){
                    setLoading(false)
                    navigate(`/crm/appointment/invoice/edit/${id}`)
                }else {
                    setLoading(false)
                    toast.error('Your password is wrong. Please try again.');

                }
            } catch (error) {
                setLoading(false)
                toast.error('An error occurred. Please try again.');

            }
        },
    });
    const handleCloseModal = () => {
        handleClose(); 
      };
      


    // const handleSendReminder = async () => {
    //   try {
    //     const payload = {
    //       customer_id: customer_id,
    //       booking_id: booking_id,
    //       message: formik.values.message
    //     };
    //     dispatch(sendReminder(payload));
    //     toast.success('Send invoice successfully'); // Display success toast
    //     handleClose();
    //   } catch (error) {
    //     console.log('Error sending reminder', error);
    //   }


    // };

    return (
        <>

            <Button
              onClick={handleOpen}
                size="large"
                startIcon={<HttpsIcon sx={{ fontSize: '18px!important' }} />}
                sx={{
                    // borderRadius:"0px",
                    color: "#219653",
                    borderColor: "#219653",
                    textTransform: "capitalize",
                    width: "100%",
                    borderRadius: "6px!important",
                    fontSize: "14px",
                    "&:hover": {
                        backgroundColor: "#219653",
                        borderColor: "#219653",
                        color: "#fff",
                    },
                }}
                variant={"outlined"}
                // onClick={() => handleLockedEditInvoice(POSBooking)}
            >
                {"Invoice"}
            </Button>
           
            <Modal
                open={open}
                onClose={handleClose}
                
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            > 
                <Box sx={style}>
                    <Grid container spacing={1} sx={wrapper}>
                        {/* <Grid item lg={12} style={{ display: "contents" }}> */}
                        <Grid item lg={12} md={12} sm={12} style={{ display: "contents" }}>
                            <Box style={{ width: "100%", background: "#1976D2", padding: "10px 0px", borderRadius: "9px 10px 0px 0px", margin: "0px 0px 0px 7px", display: 'flex', alignItems: 'center' }}>
                                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" style={{ paddingLeft: "20px" }}>
                                    <CloseIcon style={{ color: "#fff" }} />
                                </IconButton>
                                <Typography variant="h6" id="modal-modal-title" sx={{ flexGrow: 1, textAlign: "center", color: "#fff" }}>
                                    Open Invoice
                                </Typography>
                            </Box>
</Grid> 
                        <Box sx={innerbox} >


                            <Grid item lg={12}>
                                <Typography style={{ textAlign: "center" }}>Enter the Password to Open this Invoice</Typography>
                            </Grid>
                            <Grid container  style={{ width: "100%", marginTop: '26px', display: "flex", 
     justifyContent: "space-between",
      width: "100%", padding: "0px 20px", 
    //   flexDirection:{lg:"row",xs:'column'}
     }} >   

                           
     

                                <Grid item lg={4} md={4} sm={4}>
                                <FormControl fullWidth>
<TextField
    id="outlined-basic"
    label="Password"
    name="password"
    type={showPassword ? "text" : "password"}
    placeholder="Password"
    value={formik.values.password}
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    variant="outlined"
/>

                                    </FormControl>
                                    {formik.touched.password && formik.errors.password && (
                                        <Typography color="error">{formik.errors.password}</Typography>
                                    )}
                                </Grid>
                                <Grid item lg={3} md={3}sm={3} style={{ marginLeft: "7px" }}>
                                <FormControlLabel
    control={
        <Checkbox
            checked={showPassword}
            onChange={() => setShowPassword(!showPassword)} // Toggle the state
        />
    }
    label="Show Password"
/>
                                </Grid>
                                <Grid item lg={3} md={3} sm={3} style={{ marginLeft: "3px" }}>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        style={{
                                            height: "50px",
                                            width: "92px",
                                            backgroundColor: 'linear-gradient(180deg, #72D5FF -43.75%, #0496FF 86.52%)',
                                            borderRadius: '21px'
                                        }}
                                        onClick={formik.handleSubmit}
                                    >
                                        Submit
                                    </Button>
                                </Grid>


                                </Grid>
                        </Box>
                    </Grid>
                </Box>
            </Modal>

        </>
    )
}

export default PasswordEditModal;

