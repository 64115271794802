import React, {useEffect, useState} from 'react';
import {Box, Button, Card, Modal, Typography} from "@mui/material";
import {modalStyle} from "../../../components/modal/customModal";
import {useDispatch} from "react-redux";
import successIcon from "../../../assets/icon/checkmark-circle.png";
import visa from "../../../assets/icon/visa.png";
import arrow from "../../../assets/icon/chevron-back-outline.png";
import AddUserCard from "../user-wallet/addUserCard";
import {customerCreateMembership, customerUpdateMembership} from "../../../store/user/user-membership";
import Loader from "../../../components/loader/loader";
import {customConfirmation, successConfirmation} from "../../../services/utills";
import Waveloading from '../../loading/Waveloading';


const UserMembershipPayment = ({user,open,setOpen,selectPlan,cardStores,userMembership,setParentIsOpen}) => {
    const [loading, setLoading] = useState(false)
    const [addPayment, setAddPayment] = useState(false)
    const [allCards, setAllCards] = useState([])
    const [provider, setProvider] = useState(null)
    const [activeProviderPayment, setActiveProviderPayment] = useState(null);



    const dispatch = useDispatch()

    useEffect(() => {
        if (cardStores?.length > 0 && activeProviderPayment?.id) {
            cardStores.forEach((item) => {
                if (item?.payment_gateway === activeProviderPayment?.payment_gateway){
                    if (item?.cards?.length || item?.cards?.cards?.length) {
                        setAllCards(item?.cards?.cards || item?.cards)
                    }
                }
                setProvider(item)
            })
        }
    }, [cardStores,activeProviderPayment])

    useEffect(()=>{
       if (user?.id){
           let res = user?.defaultProvider?.provider_payment?.length > 0 && user?.defaultProvider?.provider_payment
               .filter((item) => item?.status === 1 && item?.payment_method?.is_gateway === 1)
           if (res?.length) {
               setActiveProviderPayment(res[0])
           }
       }
    },[user])


    const handlePay =async (resData)=>{

        if (resData?.token ||resData?.id){
            let finalData={
                payment_method_token :resData?.token || resData?.id,
                provider_id:selectPlan?.provider_id
            }

            if (userMembership?.id){
                finalData['new_plan_id'] = selectPlan?.plan_id
                finalData['old_plan_id'] = userMembership?.plan_id
            let     res = await dispatch(customerUpdateMembership(finalData))
                if (res?.payload?.success === 'true'){
                    handleClose()
                    await successConfirmation("Congratulations",`You have been subscribed to ${selectPlan?.plan_name}`)

                    // setParentIsOpen('upgradeSuccess')
                }
            }else {
                finalData['plan_id'] = selectPlan?.plan_id
               let  res = await dispatch(customerCreateMembership(finalData))
                if (res?.payload?.success === 'true'){
                    handleClose()
                    await successConfirmation("Congratulations",`You have subscribed to ${selectPlan?.plan_name}`)
                }
            }


            setLoading(false)
        }
    }
    const handelPayment =async (item)=>{


        if (await customConfirmation()){
            setLoading(true)
            let finalData={
                payment_method_token :item?.token || item?.id,
                provider_id:selectPlan?.provider_id
            }

            if (userMembership?.id){
                finalData['new_plan_id'] = selectPlan?.plan_id
                finalData['old_plan_id'] = userMembership?.plan_id
                let   res = await dispatch(customerUpdateMembership(finalData))
                if (res?.payload?.success === 'true'){
                    handleClose()

                    await successConfirmation("Congratulations",`You have been subscribed to ${selectPlan?.plan_name}`)
                    // setParentIsOpen('upgradeSuccess')
                }
            }else {
                finalData['plan_id'] = selectPlan?.plan_id
               let  res = await dispatch(customerCreateMembership(finalData))
                if (res?.payload?.success === 'true'){
                    handleClose()
                    await successConfirmation("Congratulations",`You have subscribed to ${selectPlan?.plan_name}`)

                    // setParentIsOpen('upgradeSuccess')
                }
            }

            setLoading(false)
        }
    }
    const handleClose = () => {
        setOpen(false)
        setAddPayment(false)
    }

    return (
        <Box
            sx={{
                backgroundColor: '#fff'
            }}
        >
            {loading ? <Waveloading fullSize /> :""}
            <Modal
                open={open}
                onClose={() => handleClose()}
            >
                {addPayment ?
                    <Box
                        sx={{...modalStyle, width: {sm:"500px", xs:'95%'}, borderRadius: "0px", p: "34px"}}
                    >
                        <Box sx={{mt:"-10px",mb:"15px",width:'max-content',cursor:'pointer'}} onClick={()=>setAddPayment(false)}>
                            <img src={arrow}  alt={'back-arrow'}/>
                        </Box>
                        <Box sx={{mb: "24px"}}>
                            <Typography sx={{fontWeight: '400', fontSize: '20px'}}>
                                Payment
                            </Typography>
                            <Typography sx={{fontWeight: '400', mt: '16px', mb: "24px", fontSize: '16px'}}>
                                Proceed transaction via default payment method
                            </Typography>
                        </Box>
                        {allCards?.length > 0 && allCards.map((item, index) => (
                            <Card
                                key={index}
                                sx={{
                                    mt: 2,
                                    border: `0.8px solid ${item?.default ? "#128D64" : "#C4C4C4"}`,
                                    borderRadius: "4px",
                                    boxShadow: "none",
                                    position: "relative",
                                    overflow: "unset",
                                    cursor: 'pointer'
                                }}
                                onClick={()=>handelPayment(item)}
                            >
                                <Box
                                    sx={{display: "flex", alignItems: "center", width: '100%', px:{sm:'70px', xs:'15px'}}}
                                >
                                    <img
                                        src={visa}
                                        width="50px"
                                        style={{marginLeft: 2}}
                                        alt=""
                                    />
                                    <Typography
                                        variant="h5"
                                        color="initial"
                                        sx={{
                                            ml: 3,
                                            fontSize: "16px",
                                            "@media (max-width:500px)": {
                                                marginLeft: "5px",
                                                textAlign: "center",
                                                fontSize: "15px",
                                            },
                                        }}
                                    >
                                        {item?.cardType || item?.card_brand}-{item?.last4 || item?.last_4}
                                    </Typography>
                                    {item?.default && <Box sx={{ml:"50px"}}>
                                        <img
                                            src={successIcon}
                                            alt=""
                                        />
                                    </Box>}
                                </Box>
                            </Card>
                        ))}
                        {/*<Card*/}
                        {/*    sx={{*/}
                        {/*        mt: 2,*/}
                        {/*        border: `0.8px solid #C4C4C4`,*/}
                        {/*        borderRadius: "4px",*/}
                        {/*        boxShadow: "none",*/}
                        {/*        position: "relative",*/}
                        {/*        overflow: "unset",*/}
                        {/*        py: '8px',*/}
                        {/*        cursor: 'pointer',*/}
                        {/*    }}*/}
                        {/*    // onClick={() => }*/}
                        {/*>*/}
                        {/*    <Box*/}
                        {/*        sx={{display: "flex", alignItems: "center", width: '100%', px: '70px'}}*/}
                        {/*    >*/}
                        
                        {/*        /!*<Box sx={{mt: '3px'}}>*!/*/}
                        {/*        /!*    <img src={add} alt={"balance"} style={{width: '100%'}}/>*!/*/}
                        {/*        /!*</Box>*!/*/}
                        {/*        <Typography*/}
                        {/*            sx={{ml: "16px", color: "#2F80ED", fontWeight: "bold"}}*/}
                        {/*            variant="h6"*/}
                        {/*        >*/}
                        {/*           Procced with Selected card*/}
                        {/*        </Typography>*/}
                        
                        {/*    </Box>*/}
                        {/*</Card>*/}
                    </Box>:
                    <Box>
                        <AddUserCard
                            setIsOpen={()=>setOpen(false)}
                            setLoading={setLoading}
                            providerPayment={user?.defaultProvider?.provider_payment}
                            user={user}
                            active={activeProviderPayment}
                            setAddPayment={setAddPayment}
                            type={'membership'}
                            handlePayment={handlePay}
                            cardStores={cardStores}
                        />
                    </Box>
                }
            </Modal>
        </Box>
    );
};

export default UserMembershipPayment;