import { Avatar,
   Box,
   Button, 
  Checkbox, 
  Grid, 
  IconButton, 
  List, 
  ListItem, 
  ListItemAvatar, 
  ListItemButton, 
  ListItemText,
  Slide, 
  Typography  
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClinic } from '../../store/home';
import { getsubscriptionPlan, AllClinicServices, ServicesPlan, AllStaff } from '../../store/home'; // Import your API call functions
import { Navigate, useNavigate } from 'react-router';
import Loader from '../../components/loader/loader';
import LoaderHome from '../../components/loader/loaderHome';
import Waveloading from '../loading/Waveloading';

const ClinicLocation = () => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [provider, setProvider] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const homeStore = useSelector(state => state?.homeStore);
  const { clinic } = homeStore;


  useEffect(() => {
    (async () => {
      await dispatch(getAllClinic());
    })();
  }, []);

  // Function to handle checkbox selection
  const handleLocationClick = (item) => {
    setProvider(item);
    setSelectedLocation(item);
  };

  // Function to fetch data and redirect to /home
  const fetchDataAndRedirect = async () => {
    console.log("selectedLocation",selectedLocation.id)
    if (selectedLocation) {
      // Fetch data based on the selected location
      setLoading(true);
      await dispatch(getsubscriptionPlan(selectedLocation.id));
      await dispatch(AllClinicServices(selectedLocation.id));
      await dispatch(ServicesPlan(selectedLocation.id));
      await dispatch(AllStaff(selectedLocation.id));
      setLoading(false);
      localStorage.setItem('selectedLocationId', selectedLocation.id);
      // Redirect to /home after fetching data
      navigate(`/home`, { state: { selectedLocation } });
    } else {
      console.log('Please select a location before continuing.');
    }
  };

  return (
    <Box
      sx={{
        position: "absolute", 
        width: "100%",
        height: "100vh",
        overflowY: "auto",
      }}
    >  {loading ? <Waveloading fullSize /> : ""}
     
        <Grid container sx={{ p: "48px 20px" }}>
          <Grid item xs={12} md={10} lg={7} sx={{ mx: "auto" }}>
            <Typography
              variant={"h3"}
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "22px",
                mb: "16px",
              }}
            >
              Choose Your Default Spa Location
            </Typography>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {clinic?.data?.length > 0 &&
                clinic?.data.map((item, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      mt: "10px",
                      background:
                        item?.id === provider?.id ? "#f4ede3" : "#fff",
                      p: 0,
                      borderRadius: "5px",
                      color: item?.id === provider?.id ? "#000" : "#000",
                    }}
                    secondaryAction={
                      <IconButton
                        sx={{
                          ".Mui-checked": {
                            color:
                              item?.id === provider?.id
                                ? "#626262!important"
                                : "#626262",
                          },
                        }} 
                        onClick={() => handleLocationClick(item)}
                        edge="end"
                      >
                        <Checkbox
                          checked={item?.id === provider?.id}
                          disableRipple
                        />
                      </IconButton>
                    }
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={() => handleLocationClick(item)}
                      dense
                    >
                      <ListItemAvatar>
                        <Avatar  src={item?.profile_image} alt={item?.profile_image} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={item?.name}
                        secondary={
                          item?.location ? (
                            <React.Fragment>
                              <Box as={"span"} sx={{ display: "block" }}>
                              
                                <Typography
                                  as={"span"}
                                  sx={{
                                    display: "block",
                                    color:
                                      item?.id === provider?.id
                                        ? "#000"
                                        : "#626262",
                                  }}
                                  variant="body2"
                                >
                                  {item?.location[0]?.address}
                                </Typography>
                                <Typography
                                  as={"span"}
                                  sx={{
                                    display: "block",
                                    color:
                                      item?.id === provider?.id
                                        ? "#000"
                                        : "#626262",
                                  }}
                                  variant="body2"
                                >
                                  {item?.location[0]?.state},
                                  {item?.location[0]?.postal_code}
                                </Typography>
                              </Box>
                            </React.Fragment>
                          ) : null
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
              {selectedLocation && (
                  <Button
                      fullWidth
                      onClick={fetchDataAndRedirect}
                      variant={"contained"}
                      sx={{
                          backgroundColor: "#aa2167",
                          mt: "32px",
                          height: "40px",
                          color:'#fff',
                          position: 'fixed',
                          bottom: '10px',
                          right: '20px',
                          width: '90%',
                          "&:hover":{
                              backgroundColor: "#f4ede3",
                          }
                      }}
                  >
                      Continue
                  </Button>
              )}
          </Grid>
        </Grid>
      
    </Box>
  );
};

export default ClinicLocation;
