import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import SendIcon from '@mui/icons-material/Send';
import {IconButton} from "@mui/material";
import { getAllMessages, getUserDetails, SendMsgss } from '../../../store/user/Chat-modue';
import { useSelector } from 'react-redux';
// import { SendMsgs, SendMsgss } from '../../../store/crm/chat-module';


const Form = styled('form')(({ theme }) => ({
    padding: theme.spacing(1.1, 5, 5),
    display:'flex', 
    alignItems:'center',
}))


const SendMsgForm = (props) => {
    const {store, dispatch, SendMsg} = props
// ** State 
  const authStore = useSelector(state => state?.authStore);
    const { user } = authStore;

    const [msg, setMsg] = useState('')
    const [messages, setMessages] = useState([]);
    const [hideButton, setHideButton] = useState(false);

    const handleSendMsg = async (e) => {
        e.preventDefault();
        if (store && msg.trim().length) {
            setHideButton(true);
            // setMsg('');
            // // After 20 seconds, show the button again
            // setTimeout(() => {
            //     setHideButton(false);
            // }, 6000);
            // const provider_id = store?.userProfile?.default_provider_id;
            setMsg("");
       
            const provider_id = user?.default_provider_id;
            const newMessage = {
                provider_id: provider_id,
                message: msg,
                timestamp: new Date().toISOString(),
                sending: true, // Mark the message as sending 
            };
    
            // Update local state and sessionStorage
            setMessages((prevMessages) => {
                const updatedMessages = [...prevMessages, newMessage];
                sessionStorage.setItem("unsentMessages", JSON.stringify(updatedMessages)); // Save to session storage
                return updatedMessages;
            });
    
            try {
                await dispatch(SendMsgss(newMessage)).unwrap();
    
                // Mark the message as sent and update state/sessionStorage
                setMessages((prevMessages) => { 
                    const updatedMessages = prevMessages.map((m) =>
                        m.timestamp === newMessage.timestamp ? { ...m, sending: false } : m
                    );
                    sessionStorage.setItem("unsentMessages", JSON.stringify(updatedMessages)); // Update session storage
                    return updatedMessages;
                });
    
                // Fetch updated messages and clear session storage only if all are sent
                const response = await dispatch(getAllMessages(provider_id));
                if (response?.length > 0) {
                    sessionStorage.removeItem("unsentMessages");
                }
            } catch (error) {
                console.error("Failed to send message:", error);
            } finally {
                setHideButton(false);
            }
        }
    };
    
    
    useEffect(() => {
        const savedMessages = JSON.parse(sessionStorage.getItem("unsentMessages")) || [];
        setMessages(savedMessages);
    }, []);
    

  
    return (  
        <Box sx={{background: '#F6F9FB',borderTop:'1px solid #E2E8F0',pt:'7px'}}>
            <Form onSubmit={handleSendMsg}>
                    <Box sx={{flexGrow: 1,borderRadius: '20px',px:'20px',background:'#fff', display: 'flex', alignItems: 'center'}}>
                        <TextField
                            fullWidth
                            value={msg}
                            size='small'           
                            placeholder='Type your message here…'
                            onChange={e => setMsg(e.target.value)}
                            sx={{'& .MuiOutlinedInput-input': {pl: 0}, '& fieldset': {border: '0 !important'}}}
                        />
                    </Box>
                <Box sx={{display: 'flex', alignItems: 'center',ml:'15px'}}>
                {!hideButton && (
                        <IconButton onClick={handleSendMsg}>
                <SendIcon                 sx={{
                  color: msg.trim() ? "#818CF8" : "gray",  
                  transition: "color 0.3s",
                }}
 />
                        </IconButton>
                    )}
                </Box>  
            </Form>
        </Box>
    )
}
export default SendMsgForm;  