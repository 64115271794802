import React, { useState} from 'react';
import Box from "@mui/material/Box";
import {
    Typography,
    Avatar,
    Grid,
    FormLabel,
    Select,
    MenuItem,
    FormControl,
    TextField,
    IconButton, Tooltip
} from "@mui/material";
import ImageUploader from "../../../../../components/image-uploader/imageUploader";
import CustomButton from "../../../../../components/custom-button/customButton";
import {useDispatch} from "react-redux";
import {successConfirmation} from "../../../../../services/utills";
import Loader from '../../../../../components/loader/loader';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {storeCrmAttachment} from "../../../../../store/crm/crm/crm-details";
import {useParams} from "react-router-dom";
import Waveloading from '../../../../loading/Waveloading';

const labelStyles ={
    color:'#000',
    fontSize:'16px',
    mb:'10px',
    "&.Mui-focused": {
        color:'#000!important',
    }
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const CrmAddAttachment = ({appointmentDetails}) => {
    const [files,setFiles] = useState([])
    const [loading,setLoading] = useState(false)
    const [selectedServices,setSelectedServices] = useState({})
    const [comments,setComments] = useState('')

    const dispatch = useDispatch()
    const {user_id} = useParams()


    const handleSubmit = async () => {
      if (files?.length  ){

          setLoading(true) 
          const formData = new FormData();

          formData.append("customer_id", user_id);
          // formData.append("service_id", selectedServices?.id);
          formData.append("comment", comments);

          files.forEach(value => formData.append("files" + '[]', value))
          let res = await  dispatch(storeCrmAttachment({data:formData,customer_id:user_id}))

          setLoading(false)
          if (res?.payload?.success === 'true'){
              setSelectedServices({})
              setFiles([])
              setComments('')
              await successConfirmation(`Attachment Added Successfully`)
          }
      }else {
          await successConfirmation("Error",`All Fields are required`,'error')

      }
    }

    const handleRemove = (file) => {
        if (files?.length) {
            let finalData = files.filter((item) => file?.path !== item?.path && file?.size !== item?.size)
            setFiles(finalData)
        }
    }

    const handleSave = (item) => {


        let finalData = [...files,...item]

        let uniqueFiles = finalData.filter((file, index, self) =>
                index === self.findIndex((f) => (
                    f.name === file.name && f.size === file.size
                ))
        );

        setFiles(uniqueFiles)

    }

    return (
        <Box sx={{background:"#fff",minHeight:'100px',borderRadius:'8px',p:{sm:'24px', xs:'8px'}}}>
            <Typography sx={{fontSize:'20px'}}>Add Attachment</Typography>
            {loading? <Waveloading fullSize/>:''}
            <Box sx={{display:'flex',gap:"20px",flexWrap:'wrap',my:'24px'}}>
                {files?.length > 0 && files.map((file,key)=>(
                    <Box key={key}>
                        <IconButton
                            sx={{
                                fontSize: "15px",
                                position: "relative",
                                p:"5px",
                                zIndex:'1',
                                background:"#EB5757!important",
                                m:'5px',
                                right:'-111px'
                            }}
                            onClick={() => handleRemove(file)
                        }
                        >
                            <DeleteOutlineIcon sx={{color:'#fff',fontSize:'16px'}}/>
                        </IconButton>
                        <Tooltip title={file?.path}>
                        <Avatar
                            src={URL.createObjectURL(file)}
                            sx={{
                                borderRadius:'8px' ,
                                height:'149px',
                                width:'149px',
                                mt:'-36px'
                            }}
                            alt={file?.name}
                        />
                        </Tooltip>
                    </Box>
                ))}
                <ImageUploader
                   acceptFiles={{
                       'application/pdf': ['.pdf'],
                       'application/msword': [],
                       'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
                       'text/csv': ['.csv'],
                       'application/vnd.ms-excel': ['.csv'],
                   }}
                    handleSave={handleSave}
                />
            </Box>
            <Grid container spacing={3}>
                {/*<Grid item xs={12} xl={6}>*/}
                {/*    <FormControl fullWidth>*/}
                {/*        <FormLabel sx={{...labelStyles}}>Service Name</FormLabel>*/}
                {/*        <Select*/}
                {/*            size={'small'}*/}
                {/*            value={selectedServices?.id || ''}*/}
                {/*            displayEmpty*/}
                {/*            MenuProps={MenuProps}*/}
                {/*        >*/}
                {/*            {appointmentDetails?.services?.length > 0 &&appointmentDetails?.services.map((service,key) => (*/}
                {/*                <MenuItem*/}
                {/*                    key={key}*/}
                {/*                    value={service?.id}*/}
                {/*                    onClick={()=>setSelectedServices(service)}*/}
                {/*                >*/}
                {/*                    {service?.name}*/}
                {/*                </MenuItem>*/}
                {/*            ))}*/}
                {/*        </Select>*/}
                {/*    </FormControl>*/}
                {/*</Grid>*/}
                <Grid item xs={12} xl={6}>
                    <FormControl fullWidth>
                        <FormLabel sx={{...labelStyles}}>Comments</FormLabel>
                        <textarea
            className="MuiInputBase-input MuiOutlinedInput-input"
            rows={3}
            placeholder=""
            value={comments}
            onChange={(e)=>setComments(e.target.value)}
            style={{
                width: '100%',
                resize: 'vertical', // Allow vertical resizing
                padding: '8px',  
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '4px',
                fontFamily: 'inherit', // Use the same font as Material-UI inputs
                fontSize: '1rem', // Use the same font size as Material-UI inputs
            }}
        />
                    </FormControl>
                </Grid>
                <Grid xs={12} item>
                    <CustomButton val={'Save'} handleClick={handleSubmit}/>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CrmAddAttachment;