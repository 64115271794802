import React, {useState} from 'react';
import {Box, Card, Container, Divider, IconButton,Link as MuiLink,} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useStyles from "./styles";
import {useNavigate} from "react-router";
import {customConfirmation} from "../../services/utills";
import {cancelUserBooking} from "../../store/booking-widget";
import {useDispatch, useSelector} from "react-redux";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ReactComponent as Close} from "../../assets/icon/close-outline.svg";
import Loader from "../../components/loader/loader";
import CustomModal from "../../components/modal/customModal";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import RescheduleCalender from "./rescheduleCalender";
import CloseTab from "./closeTab";
import moment from "moment/moment";
import Waveloading from '../loading/Waveloading';
// import useStyles from  "../booking-widget/styles"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
export const content = {
    fontFamily:"helvetica-lt-std-bold",
    fontSize: "13px",
    color: "#000000",
    fontWeight: "400",
    overflowWrap:"anywhere"
  
  };
export const heading = {
    fontSize: "16px",
    color: "#000",
    fontWeight: "400",
  };
  export const textul = {
    color: "#000000",
    fontSize: "25px",
    fontWeight: "700",
    '@media (max-width: 600px)':{
        fontSize: "20px",
    }
  };
  export const wallet = {
    display: "flex",
    alignItems:'center',
    marginTop: "8%",
    gap: "6px",
  };
const Confirmation = ({confirmation,setConfirmation,handleReset}) => {
    const [loading,setLoading] = useState(false)
    const [rescheduleData, setRescheduleData] = React.useState(null);
    const classes = useStyles();

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector((state) => state?.authStore?.user);
    const handleBook = () => {
        if (window.innerWidth >= 768) { // Assuming 768 pixels is the breakpoint for desktop screens
            window.location.href = user?.explore_services_url + "/#services";
          } else {
            window.location.href = "/home";
          }
    }
    const handleRescheduleAppointment = async () => {

        if (confirmation?.id) {

            if (await customConfirmation("Reschedule Appointment", " Would you like to reschedule?", "Yes, Reschedule", "Cancel")) {
                setRescheduleData(confirmation)
                // let res = await dispatch(cancelUserBooking())
            }
        }
    }

    const handleScheduleClose = () => {
        setRescheduleData(null)
    }

    const handleCancel = async (item) => {
        if (confirmation?.id) {
            if (await customConfirmation("Cancel Appointment", "Are you sure you want to cancel your appointment?")) {
                setLoading(true)
                let res = await dispatch(cancelUserBooking({booking_id: confirmation?.id,type:false}))
                setLoading(false)
                if (res?.payload?.success ==='true'){
                    const MySwal = withReactContent(Swal);
                    const result = await MySwal.fire({
                        title: '',
                        text: '',
                        showConfirmButton: false,
                        didOpen: () => {
                            // Directly access the swal popup and add styles
                            const popup = document.querySelector('.swal2-popup');
                            if (popup) {
                                popup.style.maxWidth = '700px';
                                popup.style.width = '100%';
                                // Add more styles as needed
                            }
                        },
                        html:
                            <Box>
                                <Box>
                                    <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', p: '15px 15px'}}>
                                        <IconButton
                                            size="small"
                                            onClick={() => MySwal.close()}
                                        >
                                            <Close/>
                                        </IconButton>
                                    </Box>
                                </Box>
                                <Box sx={{p:'40px 20px'}}>
                                    <Typography  sx={{textAlign: "center",fontFamily: "helvetica-lt-std-bold", fontSize:'30px',fontWeight: '600'}}>
                                        Your appointment has been cancelled successfully!
                                    </Typography>
                                </Box>
                            </Box>
                    });

                }
            }
        }
    }
    const handleClose = () => {
        setRescheduleData(null)
    }

    const handleOpenGoogleMaps = (location) => {
        const encodedLocation = encodeURIComponent(location);
        window.open(`https://www.google.com/maps/search/?api=1&query=${encodedLocation}`, '_blank');
    };
    
    const handleCallPhoneNumber = (phoneNumber) => {
        window.location.href = `tel:${phoneNumber}`;
    };
    

    return (
        <Box className={classes.main}>
            {loading? <Waveloading fullSize/> :""}
            <CustomModal
                customWidth={'700px'}
                setOpen={() => handleClose()}
                open={!!rescheduleData?.id}>
                <Box>
                    <RescheduleCalender
                        handleClose={()=>handleScheduleClose()}
                        setLoading={setLoading}
                        loading={loading}
                        setConfirmation={setConfirmation}
                        rescheduleData={rescheduleData}
                    />
                </Box>

            </CustomModal>
            <Container maxWidth="md">
                <Typography
                    variant="h4"
                    color="initial"
                    sx={{ fontFamily: "helvetica-lt-std-bold" ,  textAlign:'center'}}
                >
                    Appointment Confirmed!
                </Typography>
                <Typography
                    variant="h6"
                    color="initial"
                    sx={{ fontFamily: "helvetica-lt-std-roman", color: "#000", mt: 1,  textAlign:'center' }}
                >
                    We are looking forward to seeing you on{" "}
                    <span>{confirmation?.date && confirmation?.date} at {confirmation?.start_time}</span>
                </Typography>
                <CloseTab/>

                {/* Card */}
                <Card
                        sx={{
                            mt: 3,
                            border:"1px solid red"
                        }}
                        className={classes.card}
                    >
                        <Container
                            sx={{
                                p: '24px',
                                display: 'flex',
                                justifyContent: 'space-around',
                                flexWrap: { xs: 'wrap', sm: 'nowrap' }
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                   alignItems:"flex-start",
                                    flexBasis: { xs: '100%', sm: 'auto' }
                                }}
                            >
                                <Box>
                                    <Typography sx={heading}>Customer Name:</Typography>
                                    <Typography sx={{ ...textul, textTransform: 'capitalize' }}>
                                        {confirmation?.customer?.name}
                                    </Typography>
                                </Box>
                             
                                <Box>
                                    <MuiLink href="#" color="inherit">
                                        Member ID {confirmation?.customer?.id}
                                    </MuiLink>
                                </Box>
                            </Box>
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                <Divider
                                    orientation="vertical"
                                    style={{ borderColor: "#9A9797", height: "100%", width: "2px" }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    flexBasis: { xs: '100%', sm: 'auto' },
                                    mt: { xs: 3, sm: 0 }
                                }}
                            >
                                <Box sx={{ ...wallet, mt: '0' }}>
                                    <Typography sx={heading}>Wallet:</Typography>
                                    <Typography sx={content}>${confirmation?.customer?.wallet_point}</Typography>
                                </Box>
                                <Box sx={{ ...wallet, mt: '0' }}>
                                    <Typography sx={heading}>Phone:</Typography>
                                    <Typography sx={content} style={{cursor:"pointer"}}  onClick={() => handleCallPhoneNumber(confirmation?.customer?.phone)}>{confirmation?.customer?.phone}</Typography>
                                </Box>
                                <Box sx={{ ...wallet, mt: '0' }}>
                                    <Typography sx={heading}>Email:</Typography>
                                    <Typography sx={content}>{confirmation?.customer?.email}</Typography>
                                </Box>
                                <Box sx={{ ...wallet, mt: '0' }}>
                                    <Typography sx={heading}>Location:</Typography>
                                    <Typography sx={content} style={{cursor:"pointer"}} onClick={() => handleOpenGoogleMaps(confirmation?.provider?.name)}>{confirmation?.provider?.name}</Typography>
                                </Box>
                            </Box> 
                        </Container>
                    </Card>
               
                <Card sx={{ textAlign: "center", mt: 3, paddingBottom:{sm:0, xs:'160px'} }} className={classes.card}>
              

                    <Container maxWidth="sm">
                        {confirmation?.staff?.name &&  <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mt: 3,
                            }}
                        >
                            <Typography
                                variant="h6"
                                color="initial"
                                className={classes.roundedName}
                            >
                                {confirmation?.staff?.name.charAt(0)}
                            </Typography>
                            <Typography
                                variant="h5"
                                color="initial"
                                sx={{fontFamily: "helvetica-lt-std-bold"}}
                            >
                                {confirmation?.staff?.name}
                            </Typography>
                        </Box>}
                        <Box sx={{ mt: 4 }}>
                            <Typography
                                variant="h5"
                                color="initial"
                                sx={{ fontFamily: "helvetica-lt-std-bold" }}
                            >
                             <CalendarMonthIcon sx={{marginRight:'3px', marginTop:'-4px'}}/>   {moment(confirmation?.date).format("dddd, MMMM D, YYYY")}

                            </Typography>
                            <Typography
                                variant="h5"
                                color="initial"
                                sx={{ fontFamily: "helvetica-lt-std-bold", mt: "5px" }}
                            >
                            <AccessTimeFilledIcon sx={{marginRight:'3px', marginTop:'-4px'}}/>    {confirmation?.start_time} - {confirmation?.end_time}
                            </Typography>
                        </Box>
                        {confirmation?.services?.length > 0 && confirmation?.services.map((item,index)=>(
                            <Typography
                                key={index}
                                variant="h4"
                                color="initial"
                                sx={{ mt: 2, fontFamily: "helvetica-lt-std-bold" ,marginTop:"6px"}}
                            >
                                {item?.name}
                            </Typography>
                        ))}

                         {
  Array.isArray(confirmation?.services?.staff) && confirmation?.services?.staff.length > 0 ? (
    confirmation?.services?.staff.map((item, index) => (
      <Box key={index} style={{display: "flex", alignItems: "center",marginTop:"6px"}}>
        <AccountCircleIcon  style={{fontSize:"44px"}}/>
        <Typography
          variant="h6"
          color="initial"
          sx={{  fontFamily: "helvetica-lt-std-bold", }}
        >
          {item?.name}
        </Typography>
      </Box>
    ))
  ) : (

    <Typography
      variant="h6"
      color="initial"
      sx={{  fontFamily: "helvetica-lt-std-bold", }}
    >
No Preference On Staff
    </Typography>

  )
}



                        <Box sx={{ mt: 3 }
                    }>
                            {confirmation?.provider?.address?.address && <Typography
                                variant="h6"
                                color="initial"
                                sx={{ color:'#000' }}
                            >
                                {confirmation?.provider?.address?.address}
                            </Typography>}

                            {confirmation?.provider?.phone && <Typography
                                variant="h6"
                                color="initial"
                                sx={{ color:'#000' }}
                            >
                                {confirmation?.provider?.phone}
                            </Typography>}
                        </Box>

                        {/* Button */}

                        <Box sx={{ mt: 2 }}>
                            {/*<Button*/}
                            {/*    variant="contained"*/}
                            {/*    className={classes.buttonStyle}*/}
                            {/*    sx={{*/}
                            {/*        bgcolor: "#1565c0",*/}
                            {/*        fontSize:{xs:'15px', md:'20px'}*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    Add on Calender*/}
                            {/*</Button>*/}
                            {/*<Button*/}
                            {/*    variant="outlined"*/}
                            {/*    className={classes.buttonStyle}*/}
                            {/*    sx={{*/}
                            {/*        bgcolor: "tranparent",*/}
                            {/*        fontSize:{xs:'15px', md:'20px'}*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    Share on Twitter*/}
                            {/*</Button>*/}
                        </Box>

                        {/* Relevent Links */}

                        <Box sx={{ mt:3, pb:{xs:3, md:6} }}>
                            <Typography onClick={()=>handleRescheduleAppointment()} variant="h6" color="initial" className={classes.blueColor}>
                                Reschedule Appointment
                            </Typography>
                            {/*<Typography */}
                            {/*    variant="h6"*/}
                            {/*    color="initial"*/}
                            {/*    className={classes.blueColor}*/}
                            {/*    onClick={()=>handleCancel()}*/}
                            {/*>*/}
                            {/*    Cancel Appointment*/}
                            {/*</Typography>*/}
                            <Typography
                                variant="h6"
                                color="initial"
                                className={classes.blueColor}
                                onClick={()=>handleBook()}
                            >
                                Book another Appointment
                            </Typography>
                        </Box>
                    </Container>
                    <Box></Box>
                </Card>
            </Container>
        </Box>
    );
};

export default Confirmation;