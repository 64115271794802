import React from 'react';
import {Box, Grid, useMediaQuery} from "@mui/material";
// import {ReactComponent as Loading} from "../../assets/icon/loading.svg";
// import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';
import logo  from '../../assets/logo/3d-logo.png'

const loaderCss= {
    // border:"1px solid black",
    width:'100%',
    height:'100%',
    top: "0",
    bottom: "0",
    left: "0",
    backdropFilter:'blur(2px)',
}
const defaultCss ={ 
   position: "fixed",
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    zIndex:'99999999999999999',
    background:'transparent',
}
const LoaderHome = ({fullSize}) => {
     const isMobile = useMediaQuery("(max-width:768px)");
    return (
        <Box
            sx={fullSize ? {...defaultCss,...loaderCss }: {}}
        >
           <Grid container sx={{justifyContent:'center'}}>
               <Grid item xs={isMobile ? 6 : fullSize ? 3 : 4}>
                   {/*<Fade   forever={true}>*/}
                   {/*    <img  src={logo} alt={"loading"}/>*/}
                   {/*    /!*<Loading/>*!/*/}
                   {/*</Fade>*/}
                   {/* <Pulse  forever={true}> */}
                       <img   src={logo} alt={"loading"} width={'100%'}/>
                       {/*<Loading/>*/}
                   {/* </Pulse> */}
               </Grid>
           </Grid>
        </Box>
    );
};

export default LoaderHome;