import React from 'react';
import {
    Autocomplete,
    Checkbox,
    Chip,
    FormControl,
    FormHelperText,
    FormLabel,
    MenuItem,
    TextField
} from "@mui/material";
import {inputStyle} from "./formInput";

/* eslint-disable */
const FormAutoComplete = ({ options = [],mb,values,multiple,name,errors,touched,setFieldValue,label}) => {
    const handleSelect = (optionToRemove) => {
        const newValue = values[name].filter((option) => option !== optionToRemove);
        setFieldValue(name, newValue);
      };

    const renderOptions =(props,option,{selected}) => {


        if (option?.name === 'Select All') {
            return (
                <MenuItem
                    key={props?.id}
                    onClick={() => {
                        if (values[name]?.length === options?.length){
                            setFieldValue(name,[])
                        }else {
                            setFieldValue(name,options);
                        }
                    }}
                >      
                        <Checkbox
                            checked={values[name]?.length === options?.length }
                            style={{marginRight: 8}}
                        />
                   {values[name]?.length === options?.length ? "UnSelect All" :"Select All"}
                </MenuItem>
            );
        }
 
        return (
            <MenuItem  {...props}>
                <Checkbox
                    style={{marginRight: 8}}  
                    checked={selected}
                />
                {option?.name||option?.title || option}
            </MenuItem>
        )
    }
    const renderTags = (value, getTagProps) => {
        return value.slice(0, 2).map((option, index) => (
          <Chip
            label={getOptionLabel(option)} // Use the getOptionLabel function to get the correct label
            {...getTagProps({ index })}
            onDelete={() => {
              if (option.child && option.child.length > 0) {
                handleSelect(option, true);
              } else {
                handleSelect(option);
              }
            }}
          />
        )).concat(
          value.length > 2 ? [<Chip label="..." key="more" />] : [] // Changed "..." to "+" for clarity
        );
      };
    
      // Use the same function for the option label here
      const getOptionLabel = (option) => {
        return option?.name || option?.title || option.toString();
      };
    
     
    return (
        <FormControl fullWidth sx={{
            mb: mb ||"16px",
        }}>
            <FormLabel sx={{fontSize:"16px",color:'#27272e',letterSpacing:'.3px'}}>{label}</FormLabel> 
            <Autocomplete
                options={ multiple ? [{name:"Select All"},...options]:options}
                multiple={multiple}
                value={values[name]}
                clearIcon={null} 
                disableCloseOnSelect={!!multiple}
                isOptionEqualToValue={(option, value) => {
                    if (multiple){
                        return option.id === value.id
                    }else {
                        return option === value
                    }
                }}
                getOptionLabel={(option) => option?.name || option?.title  || option.toString()}
                renderInput={(params) => {
                    const { inputProps, helperText, error,...rest } = params;
                    return(<TextField   {...rest}    inputProps={{ ...inputProps }} sx={inputStyle} placeholder={"Select"}/>
                    )}}

                onChange={(event, newValue) => {
                    setFieldValue(name, newValue);
                }}
                renderOption={renderOptions}
                renderTags={renderTags}
                ListboxProps={{
                    sx: {
                        maxHeight: '250px', // Adjust this value as needed
                        overflow: 'auto',
                    },
                }}
            />
            {errors[name] && touched[name] && <FormHelperText sx={{color: '#CB3838',width:'max-content',mx:0}}>{errors[name]}</FormHelperText>}

        </FormControl>
    );
};

export default FormAutoComplete;