import React, {useEffect, useRef} from 'react';
import PerfectScrollbarComponent from 'react-perfect-scrollbar'
import {styled} from "@mui/material/styles";
import {Check} from "@mui/icons-material";
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import Box from "@mui/material/Box";
import {getInitials} from "./Chat";
import {Avatar, Typography} from "@mui/material";
import { getAllMessages } from '../../../store/user/Chat-modue';
import { useSelector } from 'react-redux';

const PerfectScrollbar = styled(PerfectScrollbarComponent)(({ theme }) => ({
    padding: theme.spacing(5)
}))
const ChatLog = (props) => {
    const { data, hidden } = props
     const authStore = useSelector(state => state?.authStore);
        const { user } = authStore;
    console.log("get chat in chat",data)


    // ** Ref
    const chatArea = useRef(null)
    
    const scrollToBottom = () => { 
        if (chatArea.current) {
            if (hidden) { 
                // @ts-ignore
                chatArea.current.scrollTop = Number.MAX_SAFE_INTEGER
            } else {
                // @ts-ignore
                chatArea.current._container.scrollTop = Number.MAX_SAFE_INTEGER
            }    
        }
    }
    

    const formattedChatData = () => {
        let chatLog = [];
    
        // Retrieve unsent messages from session storage
        const sessionMessages = JSON.parse(sessionStorage.getItem("unsentMessages")) || [];
        console.log("Data from sessionStorage:", sessionMessages);
    
        if (data?.chat && data.chat.length > 0) {
            // Use server data and append unsent messages if they are not yet sent
            const unsentMessages = sessionMessages.filter((m) => m.sending);
            chatLog = [...data.chat, ...unsentMessages];
    
            if (unsentMessages.length === 0) {
                sessionStorage.removeItem("unsentMessages"); // Clear session storage if all are sent
            }
        } else {
            // Use session messages if server data is not available
            chatLog = [...sessionMessages];
        }
    
        console.log("Final Chat Log:", chatLog);
    
        const formattedChatLog = [];
        let chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : 11;
    
        let msgGroup = {
            senderId: chatMessageSenderId,
            messages: [],
        };
    
        chatLog.forEach((msg, index) => {
            if (chatMessageSenderId === msg.senderId) {
                msgGroup.messages.push({
                    time: msg.time,
                    msg: msg.message,
                    feedback: msg.feedback,
                    sending: msg.sending || false,
                    failed: msg.failed || false,
                });
            } else {
                chatMessageSenderId = msg.senderId;
                formattedChatLog.push(msgGroup);
                msgGroup = {
                    senderId: msg.senderId,
                    messages: [
                        {
                            time: msg.time,
                            msg: msg.message,
                            feedback: msg.feedback,
                            sending: msg.sending || false,
                            failed: msg.failed || false,
                        },
                    ],
                };
            }
            if (index === chatLog.length - 1) formattedChatLog.push(msgGroup);
        });
    
        return formattedChatLog;
    };
    

    const renderMsgFeedback = (isSender, feedback) => {
        if (isSender) {
            if (feedback.isSent && !feedback.isDelivered) {
                return <Check sx={{ mr: 2, fontSize: '1rem', color: 'text.secondary' }} />
            } else if (feedback.isSent && feedback.isDelivered) {
                return <DoneAllOutlinedIcon sx={{ mr: 2, fontSize: '1rem', color: feedback.isSeen ? 'success.main' : 'text.secondary' }} />
            } else {
                return null
            }
        }
    }
    useEffect(() => {
        if (data && data.chat && Array.isArray(data.chat) && data.chat.length) {
            scrollToBottom();
        }
    }, [data]);
    

    const renderChats = () => {
        return formattedChatData().map((item, index) => {
           const isSender = item.senderId === data.userContact.id
           const hasSendingMessage = item.messages.some((chat) => chat.sending);


           const flexDirection = isSender || hasSendingMessage ? "row-reverse" : "row";
        
            return (
                <Box    
                    key={index}
                    sx={{
                        display: "flex",
                        flexDirection: flexDirection,
                        mb: index !== formattedChatData().length - 1 ? 5.75 : undefined,
                    }}
                >
                    <Box> 
                        <Avatar
                            skin="light" 
                            sx={{
                                width: "2rem", 
                                height: "2rem",
                                fontSize: "0.875rem",
                                ml: isSender ? 2 : undefined,
                                mr: !isSender ? 2 : undefined,
                            }}
                            {...(isSender
                                ? { src: data?.userContact?.profile_image || "", alt: "You" }
                                : { src: user?.defaultProvider?.profile_image || "", alt: user?.defaultProvider?.name || "test" })}
                        >
                            {getInitials(isSender ? data?.userContact?.name || 'Send' : user?.defaultProvider?.name || 'Received')}

                        </Avatar>
                    </Box>   
    
                    <Box className="chat-body" sx={{ maxWidth: ["calc(100% - 5.75rem)", "75%", "65%"] }}>
                        {item.messages.map((chat, index) => {
                            const time = new Date(chat.time);
     
                            return (
                                <Box key={index} sx={{ "&:not(:last-of-type)": { mb: 3.5 } }}>
                                    <Box>
                                        <Typography
                                            sx={{
                                                boxShadow: 1,
                                                borderRadius: "6px",
                                                width: "fit-content",
                                                fontSize: "0.875rem",
                                                p: "16px",
                                                ml: isSender ? "auto" : undefined,
                                                color: "black",
                                                backgroundColor: isSender || hasSendingMessage ? "#64748b42" : "#E6DAD1",
                                                
                                            }}
                                        >
                                            {chat.msg}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            mt: 1,   
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: isSender ? "flex-end" : "flex-start",
                                        }}
                                    >
                                        {chat.sending ? (
                                            <Typography variant="caption" sx={{ color: "text.disabled" }}>
                                                Sending...
                                            </Typography>
                                        ) : (
                                            <Typography variant="caption" sx={{ color: "text.disabled" }}>
                                               {time ? new Date(time).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) : null}
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>



                </Box>
            );
        });
    };
    

    const ScrollWrapper = ({ children }) => {
        if (hidden) {
            return (
                <Box ref={chatArea} sx={{ p: 5, height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
                    {children}
                </Box>
            )
        } else {
            return (
                <PerfectScrollbar ref={chatArea} options={{ wheelPropagation: false }}>
                    {children}
                </PerfectScrollbar>
            )
        }
    }

    return (
        <Box sx={{ height: 'calc(100% - 135px)' }}>
            <ScrollWrapper>{renderChats()}</ScrollWrapper>
        </Box>
    )
};

export default ChatLog;