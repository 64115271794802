import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  Container,
  Grid,
  CardMedia,
  InputAdornment,
  Slide,
  Button,
} from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import CustomModal from "../../components/modal/customModal";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import threeDlogo from "../../assets/logo/3dlogo-transparant.webp";
import EmailIcon from "@mui/icons-material/Email";
import useStyles from "./style";
import PersonIcon from "@mui/icons-material/Person";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import planImage from "../../assets/images/plan image1.png";
import planImage1 from "../../assets/images/plan image2.png";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import thread from "../../assets/images/thread-lift.png";
import medical from "../../assets/images/medical.png";
import skin from "../../assets/images/skin-condition.png";
import PhoneIcon from "@mui/icons-material/Phone";

import fb from "../../assets/icon/fb-tranparant.png";
import insta from "../../assets/icon/instagram-transparant.webp";
import tiktok from "../../assets/icon/png-transparent-tiktok-logo.png";
import laser from "../../assets/images/laser-hair.png";
import wellness from "../../assets/images/wellness.png";
import tattoo from "../../assets/images/tattoo-removal.png";
import surgical from "../../assets/images/non-surgical.png";
import therapeutic from "../../assets/images/therapeutic.png";
import ppr from "../../assets/images/ppr-hair.png";
import carbon from "../../assets/images/carbon-laser.png";
import staff1 from "../../assets/images/fozia-johri.png";
import location from "../../assets/images/vancouver.png";
import homeoutline from "../../assets/images/homeoutline.png";
import booking from "../../assets/images/Vector (Stroke).png";
import menu from "../../assets/images/homeIcon.png";
import plan from "../../assets/images/wallet2.png";
import serviceDefault from "../../assets/images/serviceDefault.jpg";
import defaultservice from "../../assets/images/defaultservice.jpg";
import profile from "../../assets/images/user.png";
import Slider from "react-slick";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./style.css";
import {
  AllClinicServices,
  AllStaff,
  Servicepromo,
  ServicesPlan,
  SubCategory,
  getAllClinic,
  getsubscriptionPlan,
} from "../../store/home";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Login from "../login";
import Waveloading from "../loading/Waveloading";
import Footer from "./Footer";
import { InView } from "react-intersection-observer";
import {YouTube} from "@mui/icons-material";

const Home = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const selectedLocation = location?.state?.selectedLocation;
  const homeStore = useSelector((state) => state?.homeStore);
  const [loginOpen, setOpenLogin] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const { clinic, SubscriptionPlan, Services, ServicePlan, staff } = homeStore;
  const selectedname = location?.state?.selectedLocation;
  const [showLogin, setShowLogin] = useState(false);
console.log("clinics data comming ",clinic)
  console.log("clinic name of all", selectedname);
  const getSelectedClinic = () => {
    // Retrieve the clinic information as a string
    const clinicInfoStr = localStorage.getItem("selected");

    // Parse the string back into an object
    const clinicInfo = JSON.parse(clinicInfoStr);

    if (clinicInfo) {
      console.log("Selected Clinic:", clinicInfo);
      // Now you have access to the entire object
      return clinicInfo;
    } else {
      // Handle the case where no clinic has been selected yet
      return null;
    }
  };

  const selected = getSelectedClinic();
  console.log("Selected Clinic Info:", selected);
  // const { id,name ,clinicInfo } = getSelectedClinic() || {};
  const id = localStorage.getItem("selectedLocationId");
  const [activeButton, setActiveButton] = useState(
    localStorage.getItem("activeButton") || "home"
  );
  
  const selectedLocationString = localStorage.getItem("selectedLocation");
  const SelectedLocationname = selectedLocationString
    ? JSON.parse(selectedLocationString)
    : null;
    console.log("location coming from booking widget",selectedLocation)
  const [selectedClinic, setSelectedClinic] = useState(
    selectedLocation || selected || selectedname || SelectedLocationname || null
  );
 
  const [loading, setLoading] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const localStorageKey = "selectedLocation";
  const newValue = selectedClinic;
  const authStore = useSelector((state) => state?.authStore);
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase()); 
  };
  const { user } = authStore;
  if (user?.id) {
    localStorage.setItem("selectedLocationId", user?.defaultProvider?.id);
  }

   var settings = {
    dots: true,
    infinite: false,
    speed: 100,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    padding: 10,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1.5,
          initialSlide: 1.5,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1.2,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const backhandler = (selectedClinic) => {
    navigate(`/location`);
  };
  localStorage.setItem(localStorageKey, JSON.stringify(newValue));
    // Helper function to match the clinic 
    const matchClinic = (clinicData, servicePrefix) => { 
      console.log("data matching",clinicData, servicePrefix)
      if (servicePrefix && clinicData?.length > 0) {
   
        const matchingClinic = clinicData.find(
          (item) => item.loc === servicePrefix  
        );
        console.log("Matching clinic: ", matchingClinic);   
        return matchingClinic; 
      }
      return null; 
    };
  
    useEffect(() => {
   
      // Fetch clinics and match
      (async () => {
        setLoading(true);
        await dispatch(getAllClinic());
  
        setTimeout(() => {
          setLoading(false);
        }, 20000);
      })();
    }, [dispatch]);

    useEffect(() => {
      // Extract `service_prefix` from the URL 
      const urlParams = new URLSearchParams(window.location.search);
      const servicePrefix = urlParams.get("service_prefix");
  

        console.log("Service Prefix: ", servicePrefix);
        console.log("Home Store: ", homeStore)
  
        const matchingClinic = matchClinic(clinic?.data, servicePrefix);
        console.log("Matching Clinic: ", matchingClinic);
  
        if (matchingClinic) {
          setSelectedClinic(matchingClinic);
        }

     
    }, [homeStore,]);
  const clinicId = selectedClinic ? selectedClinic.id : null; 

  const shouldRedirectToLogin = !user?.id || user?.role_id !== 3; 
  useEffect(() => {
    if ((selectedClinic && selectedClinic.id) || id) {
      setLoading(true);
      dispatch(getsubscriptionPlan(clinicId || id))
        // AllStaff
        .then((response) => {
          setLoading(false);
          setDataFetched(true);
        })
        .catch((error) => {
          console.error("Error fetching subscription plan:", error);
        });
    }
  }, [dispatch, selectedClinic]);

  const targetRef = useRef(null);
  const servicePlanRef = useRef(null);
  const StaffRef = useRef(null);
  useEffect(() => {
    // const observer = new IntersectionObserver(
      // (entries) => { 
      //   entries.forEach((entry) => {
      //     if (entry.isIntersecting) {
            if (selectedClinic && selectedClinic.id) {
              setLoading(true);
              dispatch(AllClinicServices(clinicId || id));
              setTimeout(() => {
                setLoading(false)
              }, 10000);
     
            }
      //     }
      //   });
      // },
    //   {
    //     root: null, 
    //     rootMargin: "0px",
    //     threshold: 0.1,
    //   }
    // );
 
    // if (targetRef.current) {
    //   observer.observe(targetRef.current);
    // }

    // return () => {
    //   if (targetRef.current) {
    //     observer.unobserve(targetRef.current);
    //   }
    // };
  }, [dispatch, selectedClinic]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Trigger API calls when the <Grid> container comes into view
            if (selectedClinic && selectedClinic.id) {
              dispatch(ServicesPlan(clinicId || id));
            }
          } 
        });
      },
      {
        root: null, // assuming the viewport; adjust as necessary
        rootMargin: "0px",
        threshold: 0.1, // Adjust this value based on when you want the callback to fire
      }
    );

    if (servicePlanRef.current) {
      observer.observe(servicePlanRef.current);
    }

    return () => {
      if (servicePlanRef.current) {
        observer.unobserve(servicePlanRef.current);
      }
    };
  }, [dispatch, selectedClinic]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Trigger API calls when the <Grid> container comes into view
            if (selectedClinic && selectedClinic.id) {
              setLoading(true);
              dispatch(AllStaff(clinicId || id));
              setLoading(false);
            }
          }
        });
      },
      {
        root: null, // assuming the viewport; adjust as necessaryg
        rootMargin: "0px",
        threshold: 0.1, // Adjust this value based on when you want the callback to fire
      }
    );

    if (StaffRef.current) {
      observer.observe(StaffRef.current);
    }

    return () => {
      if (StaffRef.current) {
        observer.unobserve(StaffRef.current);
      }
    };
  }, [dispatch, selectedClinic]);

  const subscriptionPlanHandel = (plan) => {
    console.log("check", plan);
    setActiveButton("member");
    if (shouldRedirectToLogin) {
      setOpenLogin(true);
    } else {
      navigate(`/user/membership`, { state: { plan } });
    }
  };

  useEffect(() => {
    setLoading(true);
    const handlePlanRedirection = () => {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.has("plan")) {
        const planprice = queryParams.get("plan");
        const planObject = SubscriptionPlan.find(
          (plan) => plan.plan_price === planprice
        );
        if (planObject) {
          setLoading(false); 
          subscriptionPlanHandel(planObject);
        }
      }
    };

    if (SubscriptionPlan && SubscriptionPlan.length > 0) {
      handlePlanRedirection();
    }
  }, [SubscriptionPlan, shouldRedirectToLogin]);

  const handleCategoryClick = (Slug) => {
    navigate(`/categories/${Slug}/${clinicId || id}`, {
      state: { selectedClinic },
    });
  }

  useEffect(() => {
    const savedLocation = localStorage.getItem(localStorageKey);
    console.log("saved location", savedLocation);
    if (savedLocation) {
      setSelectedClinic(JSON.parse(savedLocation));
    }
  }, []);
 
  let capitalizedUserName = "";
  if (user?.name) {
    capitalizedUserName = 
      user.name.charAt(0).toUpperCase() + user.name.slice(1);
  }

  const clinicOptions =
    clinic.data && Array.isArray(clinic.data)
      ? clinic.data.map((clinic) => ({
          name: clinic?.name,
          id: clinic?.id,
          facebook: clinic?.facebook,
          twitter: clinic?.twitter,
          tiktok: clinic?.tiktok,
          youtube: clinic?.youtube,
          instagram: clinic?.instagram,
          phone: clinic?.phone,
          email: clinic?.email,
        }))
      : [];
  
  const handleClinicSelect = (event, newValue) => {
    localStorage.setItem("selectedLocationId", newValue?.id); 
    setSelectedClinic(newValue);
    localStorage.setItem(localStorageKey, JSON.stringify(newValue));
  };
  const selectedLocationId = localStorage.getItem("selectedLocationId")
  if (selectedClinic === null && id === null) {
    navigate("/location");
    return null;
  }

  console.log("selected clinic", selectedClinic);

  const handleBoxClick = () => {
    if (shouldRedirectToLogin) {
      setOpenLogin(true);
    } else {
      navigate("/user/bookings");
    }
    setActiveButton("booking");
    localStorage.setItem("activeButton", "booking");
  };

  const handleplan = () => {
    setActiveButton("member");
    if (shouldRedirectToLogin) {
      setOpenLogin(true);
    } else {
      setActiveButton("member");
      navigate(`/user/membership`, { state: { selectedClinic } }); 
    }
  };
  const handlePlanClick = (url) => {
    // Navigate to the URL
    navigate(url);
  };
  const handleHome = () => {
    setActiveButton("home");
    if (shouldRedirectToLogin) {
      setOpenLogin(true);
    } else {
      navigate("/home");
    }
    setActiveButton("home");
    localStorage.setItem("activeButton", "home");
  };
  const handleprofile = () => {
    if (shouldRedirectToLogin) {
      setOpenLogin(true);
    } else {
      navigate("/user/user-account");
    }
    setActiveButton("profile");
    localStorage.setItem("activeButton", "profile");
  };
  const promohandel = () => {
    dispatch(Servicepromo(clinicId || id));
    navigate(`/promo`, { state: { selectedClinic } });
  };
  function openSocialMedia(platform, url) {
    console.log("selected url",url,platform)
    let deepLink = '';
    switch (platform) {
        case 'facebook':
            deepLink = url;//profile/${url};
            break;
        case 'instagram':
            deepLink = url;//user?username=${url};
            break;
        case 'tiktok':
            deepLink = url;//@${url};
            break;
        default:
            return window.open(url, '_blank');
    }
    
    if (window.location.hostname !== 'localhost') {
        window.open(deepLink, '_blank');
    } else {
        window.open(url, '_blank');
    }
}

  return (
    <>
      {loginOpen ? (
        <Box
          sx={{
            position: "fixed",
            left: "0",
            right: "0",
            bottom: "0",
            top: 0,
            zIndex: "1111111111111!important",
          }}
        >
          <Slide top>
            <Login
              loginOpen={loginOpen}
              setOpenLogin={setOpenLogin}
              selectedClinic={selectedClinic}
              showFooter={true}
            />
          </Slide>
        </Box>
      ) : (
        ""
      )}

      <Box
        sx={{
          overflow: "hidden",
          position: "relative",
          display: { sm: "block", md: "none" },
        }}
      >
<CustomModal open={isOpen} setOpen={setIsOpen} background={"#f4ede3"}>
  <Autocomplete
    className={classes.selectParent}
    clearIcon={null}
    disablePortal
    id="combo-box-demo"
    options={clinicOptions}
    value={selectedClinic}
    onChange={(event, newValue) => {
      handleClinicSelect(event, newValue);
      if (newValue) {
        setSelectedClinic(newValue); // Update the selected clinic state
        setIsOpen(false); // Close the modal
      }
    }}
    sx={{ mt: 4 }}
    renderInput={(params) => (
      <TextField
        {...params}
        sx={{ label: { mt: "-10px", zIndex: 0 } }}
        label="Select Your Default Clinic"
      />
    )}
    getOptionLabel={(option) => option.name}
    getOptionSelected={(option, value) =>
      option.id === (value.id || id)
    }
  />
</CustomModal>


        <Box sx={{ backgroundColor: "#f4ede3" }}>
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #AA2167",
                pb: 1,
              }}
            >
              <Box
                component="img"
                src={threeDlogo} 
                alt="3D Logo"
                sx={{ width: "120px" }}
              />

              <Box>
                {/*{selectedClinic?.facebook && (*/}
                {/*    <div*/}
                {/*        onClick={() => window.open(selectedClinic?.facebook, '_blank')}*/}
                {/*        style={{ display: 'inline-block', cursor: 'pointer', width:'40px', marginLeft:'10px', position:'relative',top:'-2px' }}*/}
                {/*    >*/}
                {/*      <img*/}
                {/*          src={fb}*/}
                {/*          width={"100%"}*/}
                {/*          style={{ borderRadius: "8px" }}*/}
                {/*          alt="Facebook"*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*)}*/}
                {/*{selectedClinic?.instagram && (*/}
                {/*    <div*/}
                {/*        onClick={() => window.open(selectedClinic?.instagram, '_blank')}*/}
                {/*        style={{ display: 'inline-block', cursor: 'pointer', width:'40px', marginLeft:'10px', position:'relative',top:'-1px' }}*/}
                {/*    >*/}
                {/*      <img*/}
                {/*          src={insta}*/}
                {/*          width={"100%"}*/}
                {/*          style={{ borderRadius: "8px" }}*/}
                {/*          alt="Instagram"*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*)}*/}
                {/*{selectedClinic?.tiktok && (*/}
                {/*    <div*/}
                {/*        onClick={() => window.open(selectedClinic?.tiktok, '_blank')}*/}
                {/*        style={{ display: 'inline-block', cursor: 'pointer', width:'65px', }}*/}
                {/*    >*/}
                {/*      <img*/}
                {/*          src={tiktok}*/}
                {/*          width={"100%"}*/}
                {/*          style={{ borderRadius: "8px" }}*/}
                {/*          alt="TikTok"*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*)}*/}
                {selectedClinic?.facebook && (
                    <a
                        href={selectedClinic?.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          display: 'inline-block',
                          cursor: 'pointer',
                          width: '40px',
                          marginLeft: '10px',
                          position: 'relative',
                          top: '-2px',
                        }}
                    >
                      <img
                          src={fb}
                          width="100%"
                          style={{ borderRadius: '8px' }}
                          alt="Facebook"
                      />
                    </a>
                )}

                {selectedClinic?.instagram && (
                    <a
                        href={selectedClinic?.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          display: 'inline-block',
                          cursor: 'pointer',
                          width: '40px',
                          marginLeft: '10px',
                          position: 'relative',
                          top: '-1px',
                        }}
                    >
                      <img
                          src={insta}
                          width="100%"
                          style={{ borderRadius: '8px' }}
                          alt="Instagram"
                      />
                    </a>
                )}

                {selectedClinic?.tiktok && (
                    <a
                        href={selectedClinic?.tiktok}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          display: 'inline-block',
                          cursor: 'pointer',
                          width: '65px',
                        }}
                    >
                      <img
                          src={tiktok}
                          width="100%"
                          style={{ borderRadius: '8px' }}
                          alt="TikTok"
                      />
                    </a>
                )}

              </Box>
        



            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 1,   flexWrap:'wrap',
                flexDirection:'column', }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <PhoneIcon sx={{ fontSize: "20px" }} />
                <Typography variant="subtitle1" color="initial">
                  <a
                      href={`tel:${selectedClinic?.phone || '+416 850 0231'}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {selectedClinic?.phone || '+416 850 0231'}
                  </a>
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <EmailIcon sx={{ fontSize: "20px" }} />
                <Typography variant="subtitle1" color="initial">
                  <a
                      href={`mailto:${selectedClinic?.email || 'care@3dlifestyle.ca'}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {selectedClinic?.email || 'care@3dlifestyle.ca'}
                  </a>
                </Typography>
              </Box>

            </Box>
          </Box>
        </Box>
        <Container
          maxWidth="lg"
          sx={{ overflow: "hidden", position: "relative" }}
        >
          {/* <Button variant="contained" onClick={()=>setIsOpen(true)}>
          set open
        </Button> */}
          <Box sx={{ display: { sm: "block", md: "none" }, mt: '10px' }}>
            <Box onClick={backhandler}>
                 <ArrowBackIcon />
             </Box>
            {/* <Autocomplete*/}
            {/*  className={classes.selectParent}*/}
            {/*  clearIcon={null}*/}
            {/*  disablePortal*/}
            {/*  id="combo-box-demo"*/}
            {/*  options={clinicOptions}*/}
            {/*  value={selectedClinic}*/}
            {/*  onChange={handleClinicSelect}*/}
            {/*  renderInput={(params) => (*/}
            {/*    <TextField*/}
            {/*      {...params}*/}
            {/*      sx={{ label: { mt: "-10px", zIndex: 0 } }}*/}
            {/*      label="Select Your Default Clinic"*/}
            {/*    />*/}
            {/*  )}*/}
            {/*  getOptionLabel={(option) => option.name}*/}
            {/*  getOptionSelected={(option, value) =>*/}
            {/*    option.id === (value.id || id)*/}
            {/*  }*/}
            {/*/>*/}
            {loading ? <Waveloading fullSize /> : ""}
            <>
              {/*{!user?.id || user?.role_id !== 3 ? (*/}
              {/*  <Box*/}
              {/*    sx={{*/}
              {/*      display: "flex",*/}
              {/*      justifyContent: "space-between",*/}
              {/*      mt: 4,*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <Box>*/}
              {/*      <KeyboardBackspaceIcon onClick={backhandler} />*/}
              {/*    </Box>*/}
              {/*    <PersonIcon onClick={() => setOpenLogin(true)} />*/}
              {/*  </Box>*/}
              {/*) : (*/}
              {/*  ""*/}
              {/*)}*/}

              {user?.id && user?.role_id === 3 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 4,
                    mt:2
                  }}
                >
                  {/* <Box onClick={backhandler}>
                 <ArrowBackIcon />
             </Box> */}
                    <Typography
                      variant="subtitle1"
                      color="#0274be"
                      sx={{ textDecoration:'underline' }}
                      onClick={() => setIsOpen(true)}
                    >
                      <LocationOnIcon sx={{ fontSize: '20px', marginTop: '-5px' }} />  
                      {selectedClinic ? selectedClinic.name : "Select Your Default Clinic"}
                    </Typography>

                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                      <AccountBalanceWalletIcon />
                      <sup
                        style={{
                          fontWeight: "600",
                          left: "-19px",
                          top: "-8px",
                        }}
                      >
                        {user?.wallet_point || 0}
                      </sup>
                    </Box>
                    <Box
                      onClick={handleprofile}
                      sx={{
                        backgroundColor: "rgba(235, 213, 194, 1)",
                        width: "30px",
                        height: "30px",
                        borderRadius: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        {capitalizedUserName.charAt(0)}{" "}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ) : (
                  <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 4,
                        mt:2
                      }}
                  >
                    <Typography
                        variant="subtitle1"
                        color="#0274be"
                        sx={{ textDecoration:'underline' }}
                        onClick={() => setIsOpen(true)}
                    >
                      <LocationOnIcon sx={{ fontSize: '20px', marginTop: '-5px' }} />
                      {selectedClinic ? selectedClinic.name : "Select Your Default Clinic"}
                    </Typography>
                    <PersonIcon onClick={() => setOpenLogin(true)} />
                  </Box>
                    )}

              <TextField
                id="search"
                type="search"
                label="Search"
                value={searchTerm}
                onChange={handleChange}
                className={classes.search}
                sx={{
                  width: "100%",
                  mt: 3,
                  borderRadius: "20px",
                  display: "flex",
                  alignItems: "cernter",
                  label: { mt: "-10px" },
                  zIndex: 0,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ zIndex: 0 }}>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <Typography variant="h5" sx={{ mt: 3 }}>
                Welcome
              </Typography>

              <Box
                className={classes.welcomeText}
                onClick={promohandel}
                sx={{
                  cursor: "pointer",
                  boxShadow: 2,
                  background: "#aa2167 !important",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    fontSize: "22px",
                    color: "#fff",
                  }}
                >
                  Check out latest promos
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    display: "inline-block",
                    background: "#0274be",
                    padding: "10px 15px",
                    borderRadius: "8px",
                    marginTop: "15px",
                    color: "#fff",
                  }}
                >
                  Book Now <TrendingFlatIcon />
                </Typography>
              </Box>

              <Typography variant="h6" sx={{ mt: 4 }}>
                Select Services
              </Typography>

              <Box>
                <Grid
                  // ref={targetRef}
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                    mb: 5,
                  }}
                >
                  {Services && Services.length > 0 ? (
                    Services.filter((service) =>
                      service.title.toLowerCase().includes(searchTerm)
                    ).map((service) => (
                      <Grid item xs={12} sm={6} key={service.slug}>
                        <Box
                          sx={{ position: "relative" }}
                          onClick={() => handleCategoryClick(service.slug)}
                        >
                          <CardMedia
                            sx={{ borderRadius: "6px", mt: 2 }}
                            component="img"
                            alt="Descriptive alt text"
                            height="110"
                            image={service.file_path || serviceDefault}
                          />
                          <Typography
                            variant="body1"
                            sx={{
                              position: "absolute",
                              bottom: "25px",
                              fontWeight: "600",
                              fontSize: "18px",
                              color: "#fff",
                              textAlign: "center",
                              left: "50%",
                              transform: "translateX(-50%)",
                              width: "100%",
                              background: "rgba(0, 0, 0, 0.5)",
                              padding: "10px",
                              width: "90%",
                              borderRadius: "4px",
                              letterSpacing: "1px",
                            }}
                          >
                            {service.title}
                          </Typography>
                        </Box>
                      </Grid>
                    ))
                  ) : (
                    <Typography variant="body2" sx={{ ml: 2 }}>
                      No Services available
                    </Typography>
                  )}
                </Grid>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography variant="h6">Select Membership Plans</Typography>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  {SubscriptionPlan && SubscriptionPlan.length > 0 ? (
                    SubscriptionPlan.map((plan, index) => (
                      <Grid
                        item
                        xs={
                          index === SubscriptionPlan.length - 1 &&
                          SubscriptionPlan.length % 2 !== 0
                            ? 12
                            : 6
                        }
                        sm={
                          index === SubscriptionPlan.length - 1 &&
                          SubscriptionPlan.length % 2 !== 0
                            ? 12
                            : 6
                        }
                        key={plan.id}
                      >
                        <Box sx={{ position: "relative" }}>
                          <CardMedia
                            onClick={() => subscriptionPlanHandel(plan)}
                            sx={{ borderRadius: "6px" }}
                            component="img"
                            alt="Descriptive alt text"
                            height="110"
                            image={
                              plan?.subscription_plan?.plan_image || defaultservice
                            }
                          />
                          <Typography
                            variant="body2"
                            // sx={{ position: 'absolute', bottom: '15px', color: '#fff', left: '10px' }}
                            style={{
                              margin: 0,
                              fontFamily: "helvetica-lt-std-roman",
                              fontWeight: 400,
                              fontSize: "0.875rem",
                              lineHeight: 1.43,
                              color: "#fff",
                              position: "absolute",
                              bottom: 0,
                              padding: "5px 10px",
                              background: "#0000007a",
                              borderRadius: "0 0 0 5px",
                            }}
                          >
                            {`$${plan?.subscription_plan?.plan_price} Plan`}
                          </Typography>
                        </Box>
                      </Grid>
                    ))
                  ) : (
                    <Typography variant="body2" sx={{ ml: 2 }}>
                      No Subscription Plans available
                    </Typography>
                  )}
                </Grid>
              </Box>

              <Typography variant="h6" sx={{ mt: 4 }}>
                Select a Bundle
              </Typography>

              <Box>
                <Grid
                  // ref={servicePlanRef}
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                    mb: 5,
                  }}
                >
                  {ServicePlan?.data && ServicePlan?.data.length > 0 ? (
                    ServicePlan?.data.map((serviceplan) => (
                      <Grid item xs={12} sm={6}>
                        <Box
                          sx={{ position: "relative" }}
                          onClick={() => handlePlanClick(serviceplan?.site_url)}
                        >
                          <CardMedia
                            sx={{ borderRadius: "6px", mt: 2 }}
                            component="img"
                            alt="Descriptive alt text"
                            height="110"
                            image={serviceplan?.image || serviceDefault}
                          />
                          <Typography
                            variant="body1"
                            sx={{
                              position: "absolute",
                              bottom: "25px",
                              fontWeight: "bold",
                              color: "#fff",
                              textAlign: "center",
                              width: "100%",
                              left: "50%",
                              transform: "translateX(-50%)",
                              background: "rgba(0, 0, 0, 0.5)",
                              padding: "10px",
                              width: "90%",
                              borderRadius: "4px",
                              letterSpacing: "1px",
                            }}
                          >
                            {serviceplan?.title}
                          </Typography>
                        </Box>
                      </Grid>
                    ))
                  ) : (
                    <Typography variant="body2" sx={{ ml: 2 }}>
                      No Services plan available
                    </Typography>
                  )}
                </Grid>
              </Box>

              {/* Staff */}
              {loading && <Waveloading fullSize />}
              <Typography variant="h6" sx={{ mt: 4 }}>
                3D Lifestyle Medical Aesthetics Professionals
              </Typography>
              <div style={{ marginTop: "20px" }}
              //  ref={StaffRef}
               >
                <Slider {...settings} className={classes.slickSliderr}>
                  {staff && staff.length > 0
                    ? staff.map((data) => ( 
                        <Box
                          sx={{ position: "relative" }}
                          className={classes.mainDiv}
                        >
                          <img
                            src={data?.profile_image || defaultservice}
                            style={{ width: "100%", height: "272px" }}
                            alt=""
                          />
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: "20px",
                              left: "10%",
                              right: "%",
                            }}
                          >
                            <Typography
                              variant="h5"
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                color: "#fff",
                                mb: "3",
                              }}
                            >
                              {data?.name}
                            </Typography>
                            <Typography
                              variant="overline"
                              sx={{
                                fontWeight: "500",
                                fontSize: "13px",
                                color: "#fff",
                                lineHeight: "0px",
                                letterSpacing: "0.225px",
                              }}
                            >
                              {data?.job_role}
                            </Typography>
                          </Box>
                        </Box>
                      ))
                    : ""}
                </Slider>
              </div>

              {/* Location */}

              <Typography variant="h6" sx={{ mt: 4 }}>
                3D Lifestyle Locations
              </Typography>

              <div style={{ paddingBottom: "200px", marginTop: "20px" }}>
                <Slider {...settings} className={classes.slickSliderr}>
                  {clinic.data && Array.isArray(clinic.data) ? (
                    clinic.data.map((clinicItem) => (
                      <Box
                        key={clinicItem.id}
                        sx={{ position: "relative" }}
                        className={classes.mainDiv}
                      >
                        {/* Check if profile_image is a string before rendering */}
                        {typeof clinicItem.profile_image === "string" ? (
                          <img
                            src={clinicItem.profile_image || defaultservice}
                            style={{ width: "100%", height: "272px" }}
                            alt=""
                          />
                        ) : null}
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: "20px",
                            left: "10%",
                            right: "%",
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{
                              fontWeight: "500",
                              color: "#fff",
                              textAlign: "center",
                              width: "100%",
                              background: "rgba(0, 0, 0, 0.8)",
                              padding: "10px",
                              width: "90%",
                              fontSize: "16px",
                              borderRadius: "4px",
                              marginBottom: "10px",
                              letterSpacing: "1px",
                            }}
                          >
                            {clinicItem?.name}
                          </Typography>
                          {/* Check if location is a string before rendering */}
                          {clinicItem.location?.length > 0 && (
                            <Typography
                              variant="overline"
                              sx={{
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "#fff",
                                lineHeight: "5px",
                              }}
                            >
                              {clinicItem?.location[0]?.address}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Typography variant="body2">No clinic available</Typography>
                  )}

                  {/* {clinic.data && Array.isArray(clinic.data)
  ? clinic.data.map((clinicItem) => (
      <Box key={clinicItem.id} sx={{ position: 'relative' }} className={classes.mainDiv}>
        <img src={clinicItem?.profile_image} style={{ width: "100%" }} alt="" />
        <Box sx={{ position: 'absolute', bottom: '20px', left: '10%', right: '%' }}>
          <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px', color: '#fff', mb: '3' }}>
            {clinicItem?.name}
          </Typography>
          <Typography variant="overline" sx={{ fontWeight: '500', fontSize: '13px', color: '#fff', lineHeight: '0px', letterSpacing: '0.225px' }}>
            3609 W 16th Ave, Vancouver, BC, Canada {clinicItem?.location}
          </Typography>
        </Box>
      </Box>
  ))
  : (
    <Typography variant="body2">No clinic available</Typography>
  )
} */}
                </Slider>
              </div>
            </>
          </Box>
        </Container>

        <Footer  />
      </Box>
    </>
  );
};

export default Home;
