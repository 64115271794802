import React from 'react';
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import Loader from "../components/loader/loader";

const StaffRoutes = () => {
    const authStore = useSelector(state => state.authStore)
    const {user,logged,loading} = authStore
    const location =useLocation();

    if (loading){
        return  <Loader fullSize/>
    }

    if (logged && user?.role_id === 4) {
            return <Navigate to={"/crm/dashboard"}/>
    }
    if (logged && user?.role_id === 1){
        return <Navigate to={"/admin/configuration/roles"} />
    }
    if (logged && user?.role_id === 2){
        return <Navigate to={"/crm/dashboard"} />
    }
    if (logged && user?.role_id === 3){
        console.log("staff route");
        return <Navigate to={"/user/my-account"} />
    }
    return <Navigate to={"/login"} />;
    
};

export default StaffRoutes;