import React, { useState } from 'react';
import {FormControl, FormHelperText, FormLabel, MenuItem, Select} from "@mui/material";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const FromDropdown = ({
    label,
    values,
    errors,
    touched,
    name,
    handleChange,
    options,
    textWrap,
    id,
    clinic,
}) => {
    const [isUserChanged, setIsUserChanged] = useState(false); 
    const selectedValue = isUserChanged ? values[name] || "" : id || "";

    console.log("Default value in dropdown:", selectedValue);
    console.log("Clinic ID:", clinic?.id, "Values[name]:", values[name]);

    return (
        <FormControl fullWidth sx={{ mb: "16px" }}>
            <FormLabel
                focused={false}
                sx={{ fontSize: "16px", color: "#27272e", letterSpacing: ".3px" }}
            >
                {label}
            </FormLabel>
            <Select  
                name={name}
                value={selectedValue} 
                onChange={(e) => {
                    handleChange(e)
                    setIsUserChanged(true)
                    console.log("User-selected value:", e.target.value);
                }}
                size={"small"}
                sx={{
                    background: "#EDF2F7",
                    height: "44px",
                    ".MuiOutlinedInput-notchedOutline": {
                        borderWidth: "0!important",
                    },
                }}
                MenuProps={MenuProps}
            >
                {options &&
                    options.map((item, index) => (
                        <MenuItem
                            key={index}
                            value={item?.id} // Match the item's ID
                            sx={{ textWrap: textWrap || "unset" }}
                        >
                            {item?.name || item?.event_name} {/* Display name */}
                        </MenuItem>
                    ))}
            </Select>
            {errors[name] && touched[name] && (
                <FormHelperText sx={{ color: "#CB3838", width: "max-content", mx: 0 }}>
                    {errors[name]}
                </FormHelperText>
            )}
        </FormControl>
    );
};


export default FromDropdown;