import React, {useEffect, useRef, useState} from 'react'
import {
    Autocomplete,
    Box, Button,
    Card,
    Checkbox,
    Divider, FormControl,
    FormControlLabel,
    FormLabel, Grid, IconButton,
    Input,
    InputAdornment, Modal,
    Radio,
    RadioGroup, TextField, Tooltip,
    Typography
} from '@mui/material'
import { inputStyle, labelStyle } from "../../../../../components/form/formInput";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../../components/custom-button/customButton";
import { ReactComponent as Close } from "../../../../../assets/icon/close-outline.svg";
import { ReactComponent as Braintree } from '../../../../../assets/icon/braintree.svg'
import CardPayment from "./CardPayment";
import { MySwal, successConfirmation } from "../../../../../services/utills";
import {
    crmCreateAppointment,
    crmCreateCard, crmCreateGiftCardAppointment, crmCreatePlanAppointment,
    crmGuestUser,
    crmUpdateAppointment
} from "../../../../../store/crm/appointment/book-appointment";
import { useNavigate, useParams } from "react-router";
import Loader from "../../../../../components/loader/loader";
import arrow from "../../../../../assets/icon/chevron-back-outline.png";
import UserCardsList from "./userCardsList";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { getAllProducts } from "../../../../../store/crm/inventory";
import MenuItem from "@mui/material/MenuItem";

import ClearIcon from '@mui/icons-material/Clear';
import { removeCard } from '../addAppointment';
import Waveloading from '../../../../loading/Waveloading';
import {confirmationButton} from "../../../call-logs/Appointment";

export const walletcontent = {
    fontSize: "14px", color: '#2F80ED',
    fontFamily: 'helvetica-lt-std-bold',
    fontWeight: '700'
}
export const bookingcontent = {
    fontSize: "14px",
    fontFamily: 'helvetica-lt-std-bold',
    fontWeight: '700'
}
export const bookingwrapper = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

}
export const bookingCheckoutwrapper = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

}
export const treatmentcontent = {
    fontSize: "18px",
    fontWeight: '400'
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '5px',
    transform: 'translate(-50%, -50%)',
    width: 450,
    background: '#fff',
    p: '10px 24px 24px 24px',
    '@media  (max-width: 500px) ': {
        width: '95%',
        p: '10px 10px 24px 10px',
    },
}
const AppointmentPayment = (props) => {
    const {
        activeAppointment,
        selectedServices,
        selectedUser,
        setSelectedServices,
        selectedStaff,
        selectedTime,
        activeStep,
        setActiveStep,
        selectedFinalDate,
        newUser,
        appointmentDetailsById,
        selectedUserNotes,
        userCards,
        bookingType,
        selectedPlanTime
    } = props

    const [tip, setTip] = useState(0.0)
    const [loading, setLoading] = useState(false)

    const [discount, setDiscount] = useState(0.0)
    const [walletBalance, setWalletBalance] = useState(0.0)
    const [futurePayment, setFuturePayment] = useState('')
    const [isDiscount, setIsDiscount] = useState('fix')
    const [consultationType, setConsultationType] = useState('Booking');
    const [paymentModal, setPaymentModal] = useState(null)
    const [allInvoices, setAllInvoices] = useState({})
    const [guestSelectedUser, setGuestSelectedUser] = useState(null)
    const [brainTree, setBrainTree] = useState(null)
    const [useCard, setUseCard] = useState(false);
    const userServiceDateUrl = useRef(null);
    const [triggerCondition, setTriggerCondition] = useState(false)

    console.log("radio button ",allInvoices) 
    const dispatch = useDispatch()       
    const navigate = useNavigate()
    const { appointment_id } = useParams()

    const authStore = useSelector(state => state?.authStore)
    const inventoryStore = useSelector(state => state?.inventoryStore)

    const { user } = authStore 
    const { provider_payment = [], booking_setting = {} } = user
    const {
        products = [],
    } = inventoryStore    

    const isTaxPercentage = booking_setting?.default_tax_in_percentage === 1

    const [tax, setTax] = useState(0);

    useEffect(() => {
      // Check if bookingType is 'giftcard'
      if (bookingType === 'giftcard') {
        setTax(0); // Set tax to 0 if bookingType is 'giftcard'
      } else {
        // Otherwise, use booking_setting?.tax_booking_amount or fallback to 0
        setTax(booking_setting?.tax_booking_amount || 0);
      }
    }, [bookingType, booking_setting?.tax_booking_amount]);
    console.log("bookingcheckout", bookingType)
    let is_upfront  = 0;
    const [apiValue, setApiValue] = useState(1);
    const handleCheckboxChange = (event) => {
        const checked = event.target.checked;
        setApiValue(checked ? 1 : 0);    
    };

    useEffect(() => {

        if (appointmentDetailsById?.id) {
            if (appointmentDetailsById?.tip) {
                setTip(parseFloat(appointmentDetailsById?.tip))
            }
            /* if (appointmentDetailsById?.tax) {
                 setTax(parseFloat(appointmentDetailsById?.tax))
             }*/
            if (appointmentDetailsById?.discount) {
                setDiscount(parseFloat(appointmentDetailsById?.discount))
            }
            if (appointmentDetailsById?.discount_type) {
                setIsDiscount(appointmentDetailsById?.discount_type === 'Fixed' ? 'fix' : 'percentage')
            }
        }
    }, [appointmentDetailsById?.id])
     
    useEffect(() => {
        (async () => {
            dispatch(getAllProducts())
        })()
    }, [])

    useEffect(() => {
        // Automatically set consultationType to "Consultation" when the component mounts,
        // if service?.is_consultation_category === 1
        let service = selectedServices[0]
        if (service?.is_consultation_category === 1) {
            if (consultationType == "Booking") {
                if(service?.virtual_paid === 1){
                    setConsultationType('Virtual Consultation');
                }else{
                    setConsultationType('Consultation');
                }
            }
        }
    }, [selectedServices]);

    useEffect(() => {
        handleDateChange()
    }, [selectedServices,selectedFinalDate,setSelectedServices]);
    const handleDateChange = () => {
        let url;
        if (consultationType == "Booking") {
            url = selectedServices[0]?.user_service_url + 'BK/' + selectedFinalDate;
        }  
        if (consultationType == "Virtual Consultation") {
            url = selectedServices[0]?.user_service_url + 'VC/' + selectedFinalDate;
        }
        if (consultationType == "Consultation") { // corrected condition
            url = selectedServices[0]?.user_service_url + 'IPC/' + selectedFinalDate;
        }
        userServiceDateUrl.current = url;
        console.log('Updated URL New:', url);
        // You can add more code here
    };
    const handleCopyLink = async () => {
        navigator.clipboard.writeText(userServiceDateUrl.current);
        let timerInterval;
        await MySwal.fire({
            icon: 'success',
            title: 'URL Copied Successfully',
            showConfirmButton: false,
            html: (
                <Box sx={{mb: 2}}>
                    <Typography>
                        I will close in <b></b> milliseconds.
                    </Typography>
                    <Button
                        sx={{...confirmationButton, mt: "16px"}}
                        onClick={() => MySwal.close()}
                    >
                        Ok. Close It,
                    </Button>
                </Box>
            ),
            timer: 4000,
            timerProgressBar: true,
            didOpen: () => {
                const b = MySwal.getHtmlContainer().querySelector('b');
                timerInterval = setInterval(() => {
                    b.textContent = MySwal.getTimerLeft();
                }, 100);
            },
            willClose: () => {
                clearInterval(timerInterval);
            },
        });
        // You can add more code here
    };        
    const handleCloseModal = (method) => {
        setPaymentModal(null)
        if (method?.payment_method_id || method?.id) {
            let obj = { ...allInvoices }
            delete obj[method?.payment_method_id || method?.id]
            setAllInvoices(obj)
        }
    } 

    const handleConsultationTypeChange = (event, service) => {
        console.log("Service=" + service)
        setConsultationType(event.target.value);
        handleDateChange()
        if (event.target.value == "Virtual Consultation") {
            const value = 0;
            let arr = [...selectedServices];    
            let index = arr.findIndex(item => item.id === service?.id);
            if (index !== -1) {
                console.log("index=" + index)
                arr[index].booking_cost = value; // Directly use the string value to preserve decimal points
                setSelectedServices(arr);
            }
        } else {
            if(service.is_upfront_enable){
                const value = service.upfront_amount;
                let arr = [...selectedServices];
                let index = arr.findIndex(item => item.id === service?.id);          
                if (index !== -1) {    
                    console.log("index=" + index)
                    arr[index].booking_cost = value; // Directly use the string value to preserve decimal points
                    setSelectedServices(arr);
                }
            }else{
                const value = service.regular_price;
                let arr = [...selectedServices];
                let index = arr.findIndex(item => item.id === service?.id);
                if (index !== -1) {
                    console.log("index=" + index)
                    arr[index].booking_cost = value; // Directly use the string value to preserve decimal points
                    setSelectedServices(arr);
                }
            }

        }
    };
    const handleUpdateQuantity = (service, type) => {    
        let arr = [...selectedServices]
        let index = arr.findIndex(item => item.id === service?.id);
        if (index !== -1) {
            arr[index].service_quantity = type === 'add' ? arr[index].service_quantity + 1 : arr[index].service_quantity - 1
            setSelectedServices(arr)
        }
    }
    const handleServicesQuantity = (e, service) => {
        let arr = [...selectedServices]
        let index = arr.findIndex(item => item.id === service?.id);
        if (index !== -1) {
            const { value } = e?.target
            if (!isNaN(value) && value.trim() !== '') {
                const intValue = parseInt(value, 10);
                arr[index].service_quantity = intValue
                setSelectedServices(arr)
            } else if (value === '') {
                arr[index].service_quantity = value
                setSelectedServices(arr)

            }
        }
    }
    const handleServicesQuantityBlur = (e, service) => {
        let arr = [...selectedServices]
        let index = arr.findIndex(item => item.id === service?.id);
        if (index !== -1) {
            const { value } = e?.target
            if (value === '') {
                arr[index].service_quantity = 1
                setSelectedServices(arr)
            }
        }
    }
    const getServicePrice = (service) => {
        if (service?.selected_price !== undefined && service.selected_price !== null) {
            return `${service.selected_price.toString()}`;
        } else if (service?.booking_cost !== null) {
            return `${service.booking_cost.toString()}`;
        } else {
            if (service?.is_free === 1) {
                return "Free";
            } else if (service?.virtual_paid === 1) {
                if (service?.is_upfront_enable) {
                    let finalPrice = parseFloat(service?.upfront_amount) * (parseFloat(service?.service_quantity) || 1);
                    return `${finalPrice}`;
                } else {
                    if (selectedUser?.is_membered === 1) {
                        let finalPrice = parseFloat(service?.member_price) * (parseFloat(service?.service_quantity) || 1);
                        return `${finalPrice}`;
                    } else {
                        let finalPrice = parseFloat(service?.regular_price) * (parseFloat(service?.service_quantity) || 1);
                        return `${finalPrice}`;
                    }
                }
            } else if (selectedUser?.is_membered === 1) {
                let finalPrice = parseFloat(service?.member_price) * (parseFloat(service?.service_quantity) || 1);
                return `${finalPrice}`;
            } else {
                let finalPrice = parseFloat(service?.regular_price) * (parseFloat(service?.service_quantity) || 1);
                return `${finalPrice}`;
            }
        }
    };

    const handleUpdatePrice = (e, service) => {
        const { value } = e.target;
        let arr = [...selectedServices];
        let index = arr.findIndex(item => item.id === service?.id);
        
        if (index !== -1) {
            // Allow updating booking_cost regardless of selected_price
            if (value === '' || (!isNaN(value) && value.trim() !== '')) {
                arr[index].booking_cost = value; 
                setSelectedServices(arr);
            }
            
            // Optionally inform the user that they are updating the price
          
        }
    };
    
    


    const getSubTotal = () => {
        console.log("Test 1 pass");
        let total = 0;
    
        selectedServices.forEach((item) => {
            // Handle 'plan' booking type
            if (bookingType === 'plan') {
                if (item?.booking_cost !== null) {
                    total += (parseFloat(item?.booking_cost) || 0) * (parseFloat(item?.service_quantity) || 1);
                } else {
                    if (selectedUser?.id && selectedUser?.is_membered === 1) {
                        total += parseFloat(item?.member_price) * (parseFloat(item?.service_quantity) || 1);
                    } else {
                        total += parseFloat(item?.regular_price) * (parseFloat(item?.service_quantity) || 1);
                    }
                }
            } 
            // Handle 'giftcard' booking type
            else if (bookingType === 'giftcard') {
                total += (parseFloat(item?.selected_price) || 0) * (parseFloat(item?.service_quantity) || 1);
            } 
            // Handle other booking types
            else {
                console.log("Test 2 pass" + item?.booking_cost);
                if (item?.is_free === 0 || item?.booking_cost !== null) {
                    if (item?.booking_cost !== null) {
                        console.log("Test 3 pass" + item?.booking_cost);
                        total += (parseFloat(item?.booking_cost) || 0) * (parseFloat(item?.service_quantity) || 1);
                    } else {
                        console.log("Test 4 pass" + item?.booking_cost);
                        if (item?.virtual_paid === 1) {
                            is_upfront = 1;
                            if (item?.is_upfront_enable) {
                                total += parseFloat(item?.upfront_amount) * (parseFloat(item?.service_quantity) || 1);
                            } else {
                                if (selectedUser?.is_membered === 1) {
                                    total += parseFloat(item?.member_price) * (parseFloat(item?.service_quantity) || 1);
                                } else {
                                    total += parseFloat(item?.regular_price) * (parseFloat(item?.service_quantity) || 1);
                                }
                            }
                        } else if (selectedUser?.id && selectedUser?.is_membered === 1) {
                            total += (parseFloat(item?.member_price) * (parseFloat(item?.service_quantity) || 1));
                        } else if (item?.is_upfront_enable) {
                            total += parseFloat(item?.upfront_amount) * (parseFloat(item?.service_quantity) || 1);
                        } else {
                            total += (parseFloat(item?.regular_price) * (parseFloat(item?.service_quantity) || 1));
                        }
                    }
                }
            }
        });
    
        console.log("Test 5 pass" + total);
        return parseFloat(total).toFixed(2);
    }
    
    const getWithoutDepositSubTotal = () => {
        let total = 0;
    
        selectedServices.forEach((item) => {
            // Handle 'plan' booking type
            if (bookingType === 'plan') {
                if (item?.booking_cost !== null) {
                    total += (parseFloat(item?.booking_cost) || 0) * (parseFloat(item?.service_quantity) || 1);
                } else {
                    if (selectedUser?.id && selectedUser?.is_membered === 1) {
                        total += parseFloat(item?.member_price) * (parseFloat(item?.service_quantity) || 1);
                    } else {
                        total += parseFloat(item?.regular_price) * (parseFloat(item?.service_quantity) || 1);
                    }
                }
            } 
            // Handle 'giftcard' booking type
            else if (bookingType === 'giftcard') {
                total += (parseFloat(item?.selected_price) || 0) * (parseFloat(item?.service_quantity) || 1);
            } 
            // Handle other booking types
            else {
                if (item?.is_free === 0 || item?.booking_cost !== null) {
                    is_upfront = 1;
                    if (item?.booking_cost !== null) {
                        console.log("Test 3 pass" + item?.booking_cost);
                        total += (parseFloat(item?.booking_cost) || 0) * (parseFloat(item?.service_quantity) || 1);
                    } else if (item?.virtual_paid === 1) {
                        is_upfront = 1;
                        if (item?.is_upfront_enable) {
                            total += parseFloat(item?.upfront_amount) * (parseFloat(item?.service_quantity) || 1);
                        } else {
                            if (selectedUser?.is_membered === 1) {
                                total += parseFloat(item?.member_price) * (parseFloat(item?.service_quantity) || 1);
                            } else {
                                total += parseFloat(item?.regular_price) * (parseFloat(item?.service_quantity) || 1);
                            }
                        }
                    } else if (selectedUser?.id && selectedUser?.is_membered === 1) {
                        total += (parseFloat(item?.member_price) * (parseFloat(item?.service_quantity) || 1));
                    } else {
                        total += (parseFloat(item?.regular_price) * (parseFloat(item?.service_quantity) || 1));
                    }
                }
            }
        });
    
        return parseFloat(total).toFixed(2);
    }
    

    const getTotalTex = () => {
        let total = getSubTotal();
    
        // Adjust for giftcard type
        if (bookingType === 'giftcard') {
            total = selectedServices.reduce((acc, item) => {
                return acc + (parseFloat(item?.selected_price) || 0) * (parseFloat(item?.service_quantity) || 1);
            }, 0);
        }
    
        total = total - discount;
    
        if (consultationType === "Consultation" || consultationType === "Virtual Consultation") {
            if (is_upfront === 1) {
                if (booking_setting?.id) {
                    let taxAmount = parseFloat(tax);
                    if (isTaxPercentage) {
                        return parseFloat((((total / 100) * taxAmount) || 0).toFixed(2));
                    } else {
                        return parseFloat(taxAmount || 0).toFixed(2);
                    }
                } else {
                    return parseFloat("0.00").toFixed(2);
                }
            } else {
                console.log("For Tax " + total);
                return parseFloat("0.00").toFixed(2);
            }
        }
        
        if (booking_setting?.id) {
            let taxAmount = parseFloat(tax);
            if (isTaxPercentage) {
                return parseFloat((((total / 100) * taxAmount) || 0).toFixed(2));
            } else {
                return parseFloat(taxAmount || 0).toFixed(2);
            }
        } else {
            return parseFloat("0.00").toFixed(2);
        }
    };
    
    const getTotalDiscount = () => {
        let subtotal = getSubTotal();
    
        // Adjust for giftcard type
        if (bookingType === 'giftcard') {
            subtotal = selectedServices.reduce((acc, item) => {
                return acc + (parseFloat(item?.selected_price) || 0) * (parseFloat(item?.service_quantity) || 1);
            }, 0);
        }
    
        if (discount) {
            if (isDiscount === 'fix') {
                return parseFloat(discount).toFixed(2);
            } else {
                return parseFloat(((subtotal / 100) * discount)).toFixed(2);
            }
        } else {
            return parseFloat("0.00").toFixed(2);
        }
    }; 
    
    const getTotalTip = () => {
        if (tip > 0) {
            return parseFloat(tip).toFixed(2);
        } else {
            return parseFloat("0.00").toFixed(2);
        }
    };
    
    const getWalletBalance = () => {
        if (walletBalance > 0) {
            return parseFloat(walletBalance).toFixed(2)
        } else {
            return parseFloat("0.00").toFixed(2)
        }
    }

    const handleTaxChange = (e) => {
        const inputValue = e.target.value;
      
        // If the bookingType is 'giftcard', set the tax to 0 directly
        if (bookingType === 'giftcard') {
          setTax(0);
          return;
        }
      
        // Continue with the existing logic for other cases
        if (inputValue === '' || (!isNaN(inputValue) && inputValue.trim() !== '')) {
          const floatValue = parseFloat(inputValue);
      
          // Check if the input value is valid and greater than the minimum allowed value
          if (isNaN(floatValue) || floatValue <= (parseFloat(booking_setting?.tax_booking_amount) + 1)) {
            setTax(inputValue);
          }
        }
      };
      

    const handleDiscountChange = (e) => {

        // const inputValue = e.target.value;
        // if (!isNaN(inputValue) && inputValue.trim() !== '') {
        //     const intValue = parseInt(inputValue, 10);
        //     // if (intValue <= parseFloat(getGrandTotal())){
        //     setDiscount(intValue)
        //     // }
        // }
        const inputValue = e.target.value;
        if (inputValue === '' || (!isNaN(inputValue) && inputValue.trim() !== '')) {
            setDiscount(inputValue); // Set the string value directly to preserve decimal points
        }
        else if (inputValue === '') {
            setDiscount(inputValue)

        }
    }
    const handleTipChange = (e) => {

        const inputValue = e.target.value;
        if (inputValue === '' || (!isNaN(inputValue) && inputValue.trim() !== '')) {
            // const intValue = parseInt(inputValue, 10);
            // if (intValue <= parseFloat(getGrandTotal())){
            setTip(inputValue)
            // }
        } else if (inputValue === '') {
            setTip(inputValue)

        }
    }
    const getGrandTotal = () => {
        return parseFloat(parseFloat(getSubTotal()) + parseFloat(getTotalTex()) + parseFloat(getTotalTip()) - parseFloat(getTotalDiscount())).toFixed(2)
    }

    const getTotalPaidAmount = () => {
        let amount = 0

        if (appointmentDetailsById?.total_paid) {
            amount = appointmentDetailsById?.total_paid
        }
        return (parseFloat(amount)).toFixed(2)
    }

    const totalDue = () => {
        let grandFinal = parseFloat(getGrandTotal())

        if (Object.entries(allInvoices)?.length > 0) {
            Object.entries(allInvoices).forEach(([key, value], index) => {
                if (value?.amount) {
                    let amount = parseFloat(value?.amount)
                    grandFinal = grandFinal - amount
                }
            })
            if (activeAppointment === 'edit') {
                if (appointmentDetailsById?.payment_status === 'Paid' && parseFloat(getGrandTotal()) < parseFloat(getTotalPaidAmount())) {
                    return 0.00
                } else {
                    return (parseFloat(grandFinal) - parseFloat(getTotalPaidAmount())).toFixed(2)

                }
            } else {
                return (parseFloat(grandFinal)).toFixed(2)
            }
        } else {
            if (activeAppointment === 'edit') {
                if (appointmentDetailsById?.payment_status === 'Paid' && parseFloat(getGrandTotal()) < parseFloat(getTotalPaidAmount())) {
                    return 0.00
                } else {
                    return (parseFloat(getGrandTotal()) - parseFloat(getTotalPaidAmount())).toFixed(2)
                }
            } else {
                return (parseFloat(getGrandTotal())).toFixed(2)
            }
        }
    }


    const handleWalletChange = async (e) => {

        const inputValue = e.target.value;
        if (!isNaN(inputValue) && inputValue.trim() !== '') {
            // const intValue = parseInt(inputValue, 10);
            if (inputValue <= parseFloat(selectedUser?.wallet_point)) {
                setWalletBalance(inputValue)
                // await handleInputChange(intValue,method);
            }
        } else if (inputValue === '') {
            setWalletBalance(inputValue)
            // await handleInputChange(inputValue,method);
        }
    }
    const handleClickMethod = async (method, e) => {
        console.log(totalDue())
        const { checked } = e?.target
        if (checked) {
            console.log(totalDue())
            if (parseFloat(totalDue()) >= 0) {
                if (method?.id === 2) {
                    /********** Wallet *************/
                    let value = 0
                    let walletBalance = parseFloat(selectedUser?.wallet_point)
                    let total = parseFloat(totalDue())
                    if (total >= walletBalance) {
                        value = walletBalance
                    } else {
                        value = total
                    }
                    setWalletBalance(value)
                    await handleInputChange(value, method)
                    // selectedUser?.id && parseFloat(selectedUser?.wallet_point) > 0
                } else {
                    if (method?.labels?.length) {
                        const labels = method?.labels
                        let finalObj = {
                            payment_method_id: method?.id,
                            name: method?.name
                        }
                        labels.forEach((label) => {
                            if (label.toLowerCase() === 'amount') {
                                finalObj['amount'] = totalDue()
                            } else {
                                finalObj[label] = ""
                            }
                        })
                        setAllInvoices(prevState => {

                            if (prevState[method?.id]) {
                                return {
                                    ...prevState,
                                    [method?.id]: finalObj
                                };
                            } else {
                                return {
                                    ...prevState,
                                    [method?.id]: finalObj
                                };
                            }
                        });
                    }
                    setPaymentModal(method?.id)
                }
            }
        } else {
            let confirm = await MySwal.fire({
                icon: 'question',
                title: 'Confirmation',
                showConfirmButton: false,
                html: <Box sx={{ mb: 4 }}>
                    <Typography sx={{}}>
                        Are you sure? You don't want to use this Method.
                    </Typography>
                    <Box sx={{ mt: 3, }}>
                        <Button
                            sx={{
                                backgroundColor: "#fff",
                                minWidth: "max-content",
                                border: "1px solid #666666",
                                borderRadius: '10px',
                                fontSize: '16px',
                                px: '20px',
                                mx: '10px',
                                color: "#666666",
                                "&:hover": {
                                    backgroundColor: "#fff",
                                    border: "1px solid #666666",
                                },
                            }}
                            onClick={() => MySwal.clickConfirm()}
                        >
                            Yes
                        </Button>
                        <Button
                            sx={{
                                backgroundColor: "#fff",
                                minWidth: "max-content",
                                border: "1px solid #666666",
                                borderRadius: '10px',
                                px: '10px',
                                mx: '10px',
                                fontSize: '16px',
                                color: "#666666",
                                "&:hover": {
                                    backgroundColor: "#fff",
                                    border: "1px solid #666666",
                                },
                            }}
                            onClick={() => MySwal.close()}
                        >
                            Cancel
                        </Button>
                        {/*{method?.id !== 2 && <Button*/}
                        {/*    sx={{*/}
                        {/*        backgroundColor: "#fff",*/}
                        {/*        minWidth: "max-content",*/}
                        {/*        border: "1px solid #666666",*/}
                        {/*        borderRadius: '10px',*/}
                        {/*        px: '10px',*/}
                        {/*        mx: '10px',*/}
                        {/*        fontSize: '16px',*/}
                        {/*        color: "#666666",*/}
                        {/*        "&:hover": {*/}
                        {/*            backgroundColor: "#fff",*/}
                        {/*            border: "1px solid #666666",*/}
                        {/*        },*/}
                        {/*    }}*/}
                        {/*    onClick={() => MySwal.clickDeny()}*/}
                        {/*>*/}
                        {/*    Change Amount*/}
                        {/*</Button>}*/}
                    </Box>
                </Box>
            })
            if (!!confirm?.isConfirmed) {
                let obj = { ...allInvoices }
                delete obj[method?.id || method?.payment_method_id]
                setAllInvoices(obj)
            } else if (confirm?.isDenied) {
                // setPaymentModal(method?.id)
            }
        }

    }

    const handleInputChange = async (value, method, isClose) => {
        if (parseFloat(value) <= parseFloat(totalDue())) {
            setAllInvoices(prevState => {
                if (prevState[method?.id]) {
                    return {
                        ...prevState,
                        [method?.id]: { amount: value, payment_method_id: method?.id, name: method?.name }
                    };
                } else {
                    return {
                        ...prevState,
                        [method?.id]: { amount: value, payment_method_id: method?.id, name: method?.name }
                    };
                }
            });
            if (isClose) {
                handleCloseModal()
            }
        } else {
            await successConfirmation("Warning", "Amount Increase", "warning")
        }
    }
  
    const handleRemoveService = (serviceToRemove) => {

        const updatedServices = removeCard(selectedServices, serviceToRemove);
        console.log("services length",selectedServices.length)
        if (selectedServices.length === 1) {
            setActiveStep(1); 
          }
        setSelectedServices(updatedServices);
        setFuturePayment('')  
        setAllInvoices('')
      
      

    };

    const handleSaveWallet = async (value, method) => {
        if (parseFloat(value) <= parseFloat(totalDue())) {
            setAllInvoices(prevState => {
                if (prevState[method?.payment_method_id]) {
                    return {
                        ...prevState,
                        [method?.payment_method_id]: {
                            amount: value, 
                            payment_method_id: method?.payment_method_id,
                            name: method?.name
                        }
                    };
                } else {
                    return {
                        ...prevState,
                        [method?.payment_method_id]: {
                            amount: value,
                            payment_method_id: method?.payment_method_id,
                            name: method?.name
                        }
                    };
                }
            });
        } else if (parseFloat(value) > parseFloat(totalDue())) {
            if (parseFloat(value) <= (parseFloat(allInvoices[method?.payment_method_id]?.amount) + parseFloat(totalDue()))) {
                setAllInvoices(prevState => {
                    if (prevState[method?.payment_method_id]) {
                        return {
                            ...prevState,
                            [method?.payment_method_id]: {
                                amount: value,
                                payment_method_id: method?.payment_method_id,
                                name: method?.name
                            }
                        };
                    } else {
                        return {
                            ...prevState,
                            [method?.payment_method_id]: {
                                amount: value,
                                payment_method_id: method?.payment_method_id,
                                name: method?.name
                            }
                        };
                    }
                });
            } else {
                await successConfirmation("Warning", "Amounts Increased", "warning")
                setWalletBalance(allInvoices[method?.payment_method_id]?.amount)

            }

        } else if (value === '' || value === 0) {
            let confirm = await MySwal.fire({
                icon: 'question',
                title: 'Confirmation',
                showConfirmButton: false,
                html: <Box sx={{ mb: 4 }}>
                    <Typography sx={{}}>
                        Are you sure? you don't want to use Wallet Balance?
                    </Typography>
                    <Box sx={{ mt: 3, }}>
                        <Button
                            sx={{
                                backgroundColor: "#fff",
                                minWidth: "max-content",
                                border: "1px solid #666666",
                                borderRadius: '10px',
                                fontSize: '16px',
                                px: '20px',
                                mx: '10px',
                                color: "#666666",
                                "&:hover": {
                                    backgroundColor: "#fff",
                                    border: "1px solid #666666",
                                },
                            }}
                            onClick={() => MySwal.clickConfirm()}
                        >
                            Yes
                        </Button>
                        <Button
                            sx={{
                                backgroundColor: "#fff",
                                minWidth: "max-content",
                                border: "1px solid #666666",
                                borderRadius: '10px',
                                px: '10px',
                                mx: '10px',
                                fontSize: '16px',
                                color: "#666666",
                                "&:hover": {
                                    backgroundColor: "#fff",
                                    border: "1px solid #666666",
                                },
                            }}
                            onClick={() => MySwal.close()}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            })
            if (!!confirm?.isConfirmed) {
                handleCloseModal(method)
            } else {
                setWalletBalance(allInvoices[method?.payment_method_id]?.amount)
            }
        }
    }
    const handleChangeOption = async (e) => {
        const value = e?.target?.value
        if (parseFloat(getGrandTotal()) >= 0) {
            if (value === 'future') {
                if (Object.entries(allInvoices)?.length > 0) {
                    let confirm = await MySwal.fire({
                        icon: 'question',
                        title: 'Confirmation',
                        showConfirmButton: false,
                        html: <Box sx={{ mb: 4 }}>
                            <Typography sx={{}}>
                                Are you sure? You want to do Future Payment. Your selected method will Reset
                            </Typography>
                            <Box sx={{ mt: 3, }}>
                                <Button
                                    sx={{
                                        backgroundColor: "#fff",
                                        minWidth: "max-content",
                                        border: "1px solid #666666",
                                        borderRadius: '10px',
                                        fontSize: '16px',
                                        px: '20px',
                                        mx: '10px',
                                        color: "#666666",
                                        "&:hover": {
                                            backgroundColor: "#fff",
                                            border: "1px solid #666666",
                                        },
                                    }}
                                    onClick={() => MySwal.clickConfirm()}
                                >
                                    Yes
                                </Button>
                                <Button
                                    sx={{
                                        backgroundColor: "#fff",
                                        minWidth: "max-content",
                                        border: "1px solid #666666",
                                        borderRadius: '10px',
                                        px: '10px',
                                        mx: '10px',
                                        fontSize: '16px',
                                        color: "#666666",
                                        "&:hover": {
                                            backgroundColor: "#fff",
                                            border: "1px solid #666666",
                                        },
                                    }}
                                    onClick={() => MySwal.close()}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    })
                    if (!!confirm?.isConfirmed) {
                        if (activeAppointment === 'edit') {
                            if (appointmentDetailsById?.payment_status === 'Paid') {
                                if (parseFloat(getGrandTotal()) > parseFloat(getTotalPaidAmount())) {
                                    setFuturePayment(e.target.value)
                                    setAllInvoices({
                                        "1": {
                                            amount: parseFloat(getGrandTotal()) - parseFloat(getTotalPaidAmount()),
                                            payment_method_id: 1,
                                            name: "Future Payment"
                                        }
                                    })
                                }
                            } else {
                                setFuturePayment(e.target.value)
                                setAllInvoices({
                                    "1": {
                                        amount: parseFloat(getGrandTotal()) - parseFloat(getTotalPaidAmount()),
                                        payment_method_id: 1,
                                        name: "Future Payment"
                                    }
                                })
                            }

                        } else {
                            setFuturePayment(e.target.value)
                            setAllInvoices({
                                "1": {
                                    amount: parseFloat(getGrandTotal()) - parseFloat(getTotalPaidAmount()),
                                    payment_method_id: 1,
                                    name: "Future Payment"
                                }
                            })
                        }
                    }
                } else {
                    if (activeAppointment === 'edit') {
                        if (appointmentDetailsById?.payment_status === 'Paid') {
                            if (parseFloat(getGrandTotal()) > parseFloat(getTotalPaidAmount())) {
                                setFuturePayment(e.target.value)
                                setAllInvoices({
                                    "1": {
                                        amount: parseFloat(getGrandTotal()) - parseFloat(getTotalPaidAmount()),
                                        payment_method_id: 1,
                                        name: "Future Payment"
                                    }
                                })
                            }
                        } else {
                            setFuturePayment(e.target.value)
                            setAllInvoices({
                                "1": {
                                    amount: parseFloat(getGrandTotal()) - parseFloat(getTotalPaidAmount()),
                                    payment_method_id: 1,
                                    name: "Future Payment"
                                }
                            })
                        }
                    } else {
                        setFuturePayment(e.target.value)
                        setAllInvoices({ "1": { amount: getGrandTotal(), payment_method_id: 1, name: "Future Payment" } })
                    }
                }
            } else {
                setFuturePayment(e.target.value)
                setAllInvoices({})
            }
        }
    }

    const handleBrainTree = async (data, method) => {
        setBrainTree(data)
        await handleInputChange(data?.amount, method, true)

    }
    const handleSquare = async (token, amount, method) => {
        // setSquareToken(token)
        if (parseFloat(amount) <= parseFloat(totalDue())) {
            setAllInvoices(prevState => {
                if (prevState[method?.id]) {
                    return {
                        ...prevState,
                        [method?.id]: { amount: amount, payment_method_id: method?.id, name: method?.name, token: token }
                    };
                } else {
                    return {
                        ...prevState,
                        [method?.id]: { amount: amount, payment_method_id: method?.id, name: method?.name, token: token }
                    };
                }
            });
            handleCloseModal()

        } else {
            await successConfirmation("Warning", "Amount Increase", "warning")
        }
        // await handleInputChange(amount,method,true)

    }
    const handleLabelsInputChange = async (value, target, method, bookingType) => {
        if (target === 'Amount' || target === 'amount') {
            if (!isNaN(value) && value.trim() !== '') {
                const floatValue = parseFloat(value);
                // if (parseFloat(totalDue()) === 0 || parseFloat(totalDue()) > floatValue){
                // if (floatValue <= parseFloat(floatValue)) {
                setAllInvoices(prevState => {
                    if (prevState[method?.id]) {
                        let prevMethod = prevState[method?.id] || {};
                        return {
                            ...prevState,
                            [method?.id]: { ...prevMethod, [target]: floatValue }
                        };
                    }
                })
                // }
                // }
            } else if (value === '') {
                setAllInvoices(prevState => {
                    if (prevState[method?.id]) {
                        let prevMethod = prevState[method?.id] || {};
                        return {
                            ...prevState,
                            [method?.id]: { ...prevMethod, [target]: value }
                        };
                    }
                })
            }
        } else {
            setAllInvoices(prevState => {
                if (prevState[method?.id]) {
                    let prevMethod = prevState[method?.id] || {};
                    return {
                        ...prevState,
                        [method?.id]: { ...prevMethod, [target]: value }
                    };
                } else {
                    return {
                        ...prevState,
                        [method?.id]: { amount: value, payment_method_id: method?.id, name: method?.name }
                    };
                }
            });
        }
    }
  const  provider_id=selectedServices?.provider_id;

  console.log("provider_id",provider_id)
    const handleConfirm = async (invoice, method) => {
        let errorMessage = '';
        let isValuesMissing = false;

        // Check if all required fields are filled
        Object.entries(invoice).forEach(([key, value]) => {
            if (key !== 'name' && key !== "payment_method_id" && !value.toString().length) {
                isValuesMissing = true;
                errorMessage = 'All fields required.';
            }
        });

        // Specific check for E-Transfer/CC (External Device) method
        if (method?.name === "E-Transfer/CC (External Device)") {
            // Check if transaction ID is provided for E-Transfer/CC
            if (!invoice.transactionId || invoice.transactionId.trim() === '') {
                isValuesMissing = true;
                errorMessage = 'Transaction ID field is required.';
            }
        }

        // Perform the rest of the validation only if all required fields are filled
        if (!isValuesMissing) {
            if (parseFloat(invoice?.amount) >= 0) {
                // Your amount validation logic here
                if (parseFloat(totalDue()) === 0 || parseFloat(invoice?.amount) <= (parseFloat(totalDue()) + parseFloat(invoice?.amount))) {
                    handleCloseModal();
                } else {
                    errorMessage = 'Invalid Amount.';
                }
            } else {
                errorMessage = 'Amount field is required.';
            }
        }

        // Show error message if any validation failed
        if (errorMessage) {
            await successConfirmation("Error", errorMessage, 'error');
            // Prevent modal from closing if there's an error
            return;
        }
    };


    const handleBookAppointment = async () => {

        if (parseFloat(totalDue()) === 0 || bookingType === 'plan' ||  bookingType === 'giftcard') {

            setLoading(true)
            let userId = null

            if (selectedUser?.id) {
                userId = selectedUser?.id
            } else if (guestSelectedUser?.id) {
                userId = guestSelectedUser?.id
            } else {

                let registerPayload = {
                    name: newUser?.name,
                    email: newUser?.email,
                    phone: newUser?.phone,
                    social_login: 0,
                    is_guest: 1,
                    postal_code: "none",
                    password: "3Dlifestyle!",
                }
                let registerUser = await dispatch(crmGuestUser(registerPayload))
                if (registerUser?.payload?.data?.id) {
                    setGuestSelectedUser(registerUser?.payload?.data)
                    userId = registerUser?.payload?.data?.id
                }
            }

            let notes = {}
            if (selectedUser?.id) {
                notes['note_by'] = selectedUserNotes?.note_by?.id
                if (selectedUserNotes?.staff_notes) {
                    notes['staff_notes'] = selectedUserNotes?.staff_notes
                }
                if (selectedUserNotes?.medical_notes) {
                    notes['medical_notes'] = selectedUserNotes?.medical_notes
                }
            } else {
                notes['note_by'] = newUser?.note_by?.id
                if (newUser?.staff_notes) {
                    notes['staff_notes'] = newUser?.staff_notes
                }
                if (newUser?.medical_notes) {
                    notes['medical_notes'] = newUser?.medical_notes
                }
            }


            let finalInvoices = []
            finalInvoices = Object.values(allInvoices);
            if (futurePayment === 'now') {

                if (allInvoices['3'] && userId) {

                    let finalData = {
                        user_id: userId,
                        number: brainTree?.number,
                        expirationDate: brainTree?.expirationDate,
                        cvv: brainTree?.cvv,
                    }

                    if (brainTree?.token) {
                        let idToUpdate = 3;
                        let newProperty = { "token": brainTree?.token };

                        finalInvoices = finalInvoices.map(item => {
                            if (item.payment_method_id === idToUpdate) {
                                return { ...item, ...newProperty };
                            }
                            return item;
                        });
                    } else {


                        let res = await dispatch(crmCreateCard(finalData))

                        if (res?.payload?.data?.token) {

                            let idToUpdate = 3;
                            let newProperty = { "token": res?.payload?.data?.token };

                            finalInvoices = finalInvoices.map(item => {
                                if (item.payment_method_id === idToUpdate) {
                                    return { ...item, ...newProperty };
                                }
                                return item;
                            });
                        }
                    }
                }
            }

            if (bookingType === 'plan') {
                let planFinalPayload = {
                    user_id: userId,
                    type: consultationType,
                    plan_id: selectedServices[0],
                    isDiscountInPercentage: !!isDiscount === 'percentage',
                    notes: notes,

                    discount: getTotalDiscount(),
                    tax: getTotalTex(),
                    tip: getTotalTip(),
                    invoices: finalInvoices,
                    bookings: selectedPlanTime

                }

                if (selectedStaff?.id) {
                    planFinalPayload['staff_id'] = selectedStaff?.id
                }
                let planResult

                if (activeAppointment === 'add' || activeAppointment === 'newUser') {
                    planResult = await dispatch(crmCreatePlanAppointment(planFinalPayload))
                } else {
                    planFinalPayload['plan_id'] = appointment_id
                    planResult = await dispatch(crmUpdateAppointment(planFinalPayload))
                }

                setLoading(false)
                if (planResult?.payload?.success === 'true') {
                    if (activeAppointment === 'edit') {
                        navigate(`/crm/appointment/update/confirmation/${planResult?.payload?.data[0]?.id}`)
                    } else {
                        navigate(`/crm/appointment/confirmation/${planResult?.payload?.data[0]?.id}`)
                    }
                } else {
                    const errors = planResult?.payload?.errors
                    if (errors?.length > 0) {
                        await MySwal.fire({
                            icon: 'error',
                            title: 'Error',
                            showConfirmButton: false,
                            html: <Box sx={{ mb: 4 }}>
                                {errors.map((item, index) => (
                                    <Typography key={index}>
                                        {item}
                                    </Typography>
                                ))}
                                <Box sx={{ mt: 3, }}>
                                    <Button
                                        sx={{
                                            backgroundColor: "#fff",
                                            minWidth: "max-content",
                                            border: "1px solid #666666",
                                            borderRadius: '10px',
                                            fontSize: '16px',
                                            px: '30px',
                                            mx: '10px',
                                            color: "#666666",
                                            "&:hover": {
                                                backgroundColor: "#fff",
                                                border: "1px solid #666666",
                                            },
                                        }}
                                        onClick={() => MySwal.close()}
                                    >
                                        OK
                                    </Button>
                                </Box>
                            </Box>
                        })
                    }
                }
            }  
            else if (bookingType === 'giftcard')
            {
                let planFinalPayload = {
                    user_id: userId,
                    type:'giftcard',
                    gift_card_id: selectedServices[0]?.gift_card_id,
                    isDiscountInPercentage: !!isDiscount === 'percentage',
                    notes: notes,
                    provider_id:selectedServices[0]?.provider_id,
                    discount: getTotalDiscount(),
                    tax: getTotalTex(),
                    tip: getTotalTip(),
                    invoices: finalInvoices,
                    amount: finalInvoices[0]?.amount,
                  

                }

                if (selectedStaff?.id) {
                    planFinalPayload['staff_id'] = selectedStaff?.id
                }
                let planResult

                if (activeAppointment === 'add' || activeAppointment === 'newUser') {
                    planResult = await dispatch(crmCreateGiftCardAppointment(planFinalPayload))
                } else {
                    planFinalPayload['plan_id'] = appointment_id
                    // planResult = await dispatch(crmUpdateAppointment(planFinalPayload))
                }

                setLoading(false)
                if (planResult?.payload?.success === 'true') {   
                    console.log("plan_result",planResult)   
                   navigate(`/crm/appointment/gift/${planResult?.payload?.data?.id}`,{ state: { planResult }})
                
                } else {
                    const errors = planResult?.payload?.errors
                    if (errors?.length > 0) {
                        await MySwal.fire({
                            icon: 'error',
                            title: 'Error',
                            showConfirmButton: false,
                            html: <Box sx={{ mb: 4 }}>
                                {errors.map((item, index) => (
                                    <Typography key={index}>
                                        {item}
                                    </Typography>
                                ))}
                                <Box sx={{ mt: 3, }}>
                                    <Button
                                        sx={{
                                            backgroundColor: "#fff",
                                            minWidth: "max-content",
                                            border: "1px solid #666666",
                                            borderRadius: '10px',
                                            fontSize: '16px',
                                            px: '30px',
                                            mx: '10px',
                                            color: "#666666",
                                            "&:hover": {
                                                backgroundColor: "#fff",
                                                border: "1px solid #666666",
                                            },
                                        }}
                                        onClick={() => MySwal.close()}
                                    >
                                        OK
                                    </Button>
                                </Box>
                            </Box>
                        })
                    }
                }

            }
            
            else {


                let finalPayload = {
                    service_ids: selectedServices,
                    user_id: userId,
                    type: consultationType,
                    is_email_send:apiValue,
                    isDiscountInPercentage: !!isDiscount === 'percentage',
                    notes: notes,
                    upfront_amount: consultationType === "booking"
                        ? 0
                        : getSubTotal(),
                    date: selectedFinalDate,
                    start_time: selectedTime?.start_time,
                    end_time: selectedTime?.end_time,
                    discount: getTotalDiscount(),
                    tax: getTotalTex(),
                    tip: getTotalTip(),
                    invoices: finalInvoices,
                }

                if (selectedStaff?.id) {
                    finalPayload['staff_id'] = selectedStaff?.id
                }


                let appointmentResult

                if (activeAppointment === 'add' || activeAppointment === 'newUser') {
                    appointmentResult = await dispatch(crmCreateAppointment(finalPayload))
                } else {
                    finalPayload['booking_id'] = appointment_id
                    appointmentResult = await dispatch(crmUpdateAppointment(finalPayload))
                }

                setLoading(false)

                if (appointmentResult?.payload?.success === 'true') {
                    if (activeAppointment === 'edit') {
                        navigate(`/crm/appointment/update/confirmation/${appointmentResult?.payload?.data?.id}`)
                    } else {
                        navigate(`/crm/appointment/confirmation/${appointmentResult?.payload?.data?.id}`)
                    }
                } else {
                    const errors = appointmentResult?.payload?.errors
                    if (errors?.length > 0) {
                        await MySwal.fire({
                            icon: 'error',
                            title: 'Error',
                            showConfirmButton: false,
                            html: <Box sx={{ mb: 4 }}>
                                {errors.map((item, index) => (
                                    <Typography key={index}>
                                        {item}
                                    </Typography>
                                ))}
                                <Box sx={{ mt: 3, }}>
                                    <Button
                                        sx={{
                                            backgroundColor: "#fff",
                                            minWidth: "max-content",
                                            border: "1px solid #666666",
                                            borderRadius: '10px',
                                            fontSize: '16px',
                                            px: '30px',
                                            mx: '10px',
                                            color: "#666666",
                                            "&:hover": {
                                                backgroundColor: "#fff",
                                                border: "1px solid #666666",
                                            },
                                        }}
                                        onClick={() => MySwal.close()}
                                    >
                                        OK
                                    </Button>
                                </Box>
                            </Box>
                        })
                    }
                }
            }
        } else {
            await successConfirmation('Invalid', 'Invalid amount or something went wrong.Please Review all prices again or reset payment ways', 'error')
        }

    }

    let isDataAvailable = (
        (selectedTime?.slots && bookingType === 'service') ||
        (selectedPlanTime?.length > 0 && selectedPlanTime[0]?.date && selectedPlanTime[0]?.start_time && bookingType === 'plan') 
    );

    console.log("data coming", isDataAvailable);

    useEffect(() => {
        // Set futurePayment to an empty string if isDataAvailable or bookingType is "giftcard"
        if (isDataAvailable || bookingType === "giftcard") {
          setFuturePayment('');
        }
      }, [isDataAvailable, bookingType]);
    const isMobileView = window.innerWidth < 768;

    if (isMobileView) {

        isDataAvailable = true;
    }

    console.log("data coming (fixed)", selectedServices);

    const formatTimeDuration = (timeDuration) => {
        // Check if the input is in the expected format "HH:MM"
        if (!timeDuration || !timeDuration.includes(':')) {
            return 'Time not available'; // Return a message if the format is not as expected
        }

        const parts = timeDuration.split(':'); // This splits the string into ["HH", "MM"]

        // Make sure that we have exactly two parts ["HH", "MM"]
        if (parts.length !== 2) {
            return 'Time not available'; // Return a message if the format doesn't have two parts
        }

        // Safely parse the hours and minutes using parseInt and ensure we are not parsing undefined or null
        const hours = parseInt(parts[0], 10); // Extract and convert hours part to number
        const minutes = parseInt(parts[1], 10); // Extract and convert minutes part to number

        // Handle possible NaN values
        if (isNaN(hours) || isNaN(minutes)) {
            return 'Time not available'; // Return a message if either hours or minutes are not numbers
        }

        // Determine how to format the output
        let formattedTime = '';

        if (hours > 0) {
            formattedTime += `${hours} H`;
        }
        if (minutes > 0) {
            if (formattedTime.length > 0) formattedTime += ' ';
            formattedTime += `${minutes} Minutes`;
        }

        return formattedTime || 'Time not available'; // Return formatted time or a message if time is 00:00
    };
    return (
        <Box>
            {loading ? <Waveloading fullSize /> : ""}
            <Card sx={{ mt: 4, p: 2 }}>
                {selectedFinalDate ? (
                    <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <CustomButton
                            size={'large'}
                            mainColor={'#1b7f1d'} 
                            subColor={'#fff'}
                            handleClick={handleCopyLink}
                            val={"Copy Booking Link"}
                        />
                    </Box>
                ) : null}


                <Box sx={bookingwrapper}>
                    <Typography sx={{boxShadow: 'lg', ...bookingcontent}}>Booking Details</Typography>
                    {selectedUser?.id &&
                        <Typography sx={walletcontent}>
                            Wallet Balance: ${selectedUser?.wallet_point || "0.00"}
                        </Typography>
                    }
                </Box>
                <Box sx={bookingCheckoutwrapper}> 
                    <Typography sx={walletcontent}>{selectedUser?.name}</Typography>
                </Box>
                <Box sx={bookingCheckoutwrapper}>
                    <Typography sx={walletcontent}>{selectedUser?.email}</Typography>
                </Box>
                {selectedServices?.length > 0 ?
                    <Box sx={{ background: "#EDF2F7", mt: 2, p: '12px', borderRadius: '4px' }}>
                        {
                            selectedServices.map((service, index) => (
                                <Card sx={{ mb: 2, p: 2 }} key={index}>
                                    <Box sx={{ ...bookingwrapper, alignItems: 'flex-start' }}>
                                        <Box sx={{ display: 'flex', gap: '5px' }}>
                                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                <Typography
                                                    sx={{
                                                        ...treatmentcontent,
                                                        // ml:service?.qty_enable === 1 ? '16px' : ''
                                                    }}
                                                >
                                                    {service?.name || service?.plan_name || service?.title }
                                                </Typography>
                                                {/* <Box sx={{ display: 'flex', }}>
                                                    <Box>
                                                        <Typography > Time:</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography>{service?.time_duration ? formatTimeDuration(service?.time_duration) : 'Time not available'} </Typography>
                                                    </Box>
                                                </Box> */}
                                            </Box>


                                        </Box>

                                        <Box>
                                            <Input
                                                type="text"
                                                startAdornment={
                                                    <InputAdornment
                                                        position="start"
                                                    >
                                                        {service?.is_free === 1 ? "" : "$"}
                                                    </InputAdornment>
                                                }
                                                sx={{
                                                    ...inputStyle,
                                                    width: '80px',
                                                    p: '5px',
                                                    '&::before': {
                                                        content: 'none',
                                                    },
                                                }}
                                                value={getServicePrice(service)}
                                                onChange={(e) => handleUpdatePrice(e, service)}
                                            />
                                        </Box>
                                        <ClearIcon style={{ color: "red" }} onClick={() => handleRemoveService(service)} />

                                    </Box>
                                    <RadioGroup
                                        sx={{ flexWrap: { xs: 'nowrap', sm: 'wrap' } }}
                                        row
                                        value={consultationType}
                                        onChange={(e) => handleConsultationTypeChange(e, service)}
                                    >
                                        {service?.is_consultation_category === 1 && service?.virtual_paid === 0 &&
                                            <FormControlLabel
                                                value="Consultation"
                                                size="small"
                                                control={<Radio />}
                                                label="In-Person (Deposit Required)"
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 16,
                                                    },
                                                }}
                                            />}
                                        {service?.is_consultation_category === 1 &&
                                            <FormControlLabel
                                                value="Virtual Consultation"
                                                size="small"
                                                control={<Radio />}
                                                label={service?.virtual_paid ? "Virtual" : "Virtual (Free)"}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 16,
                                                    },
                                                }}
                                            />}
                                    </RadioGroup>
                                    {bookingType === 'service' && service?.qty_enable === 1 && service?.product &&
                                        <Typography
                                            sx={{
                                                ...treatmentcontent,
                                                // ml:service?.qty_enable === 1 ? '16px' : ''
                                            }}
                                        >
                                            ({service?.product?.name})
                                        </Typography>
                                    }
                                </Card>
                            ))}
                        <Card sx={{ p: 2 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
                                <Box sx={bookingwrapper}>
                                    <Typography sx={{ fontFamily: 'helvetica-lt-std-bold' }}>Sub Total</Typography>
                                    <Typography sx={{ fontFamily: 'helvetica-lt-std-bold' }}>
                                        {consultationType === 'Booking' ? getSubTotal() : getWithoutDepositSubTotal()}
                                    </Typography>
                                </Box>
                                <Box style={bookingwrapper} sx={{ flexWrap: { xs: 'wrap', sm: 'nowrap' } }}>
                                    <div style={{ display: "flex", alignItems: 'center', gap: '20px', }}>
                                        <Typography>Discount</Typography>
                                        <RadioGroup
                                            sx={{ flexWrap: { xs: 'nowrap', sm: 'wrap' } }}
                                            row
                                            value={isDiscount}
                                            onChange={(e) => setIsDiscount(e?.target?.value)}
                                        >
                                            <FormControlLabel
                                                value="fix"
                                                size="small"
                                                control={<Radio />}
                                                label="fix"
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 16,
                                                    },
                                                }}
                                            />
                                            <FormControlLabel
                                                value="percentage"
                                                size="small"
                                                control={<Radio />}
                                                label="%"
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 16,
                                                    },
                                                }}
                                            />
                                        </RadioGroup>
                                    </div>
                                    <Box sx={{
                                        display: 'flex', alignItems: "center", gap: '20px',
                                        '@media (max-width: 444px)': {
                                            justifyContent: 'end',
                                            width: '100%'
                                        }
                                    }}>
                                        <Input
                                            type="text"
                                            startAdornment={<InputAdornment position="start">
                                                {isDiscount === 'fix' ? "$" : ""}
                                            </InputAdornment>}
                                            endAdornment={<InputAdornment position="end">
                                                {isDiscount !== 'fix' ? "%" : ""}
                                            </InputAdornment>}
                                            sx={{
                                                ...inputStyle,
                                                maxWidth: '80px',
                                                p: '5px',
                                                '&::before': {
                                                    content: 'none',
                                                },
                                            }}
                                            value={discount}
                                            onChange={(e) => handleDiscountChange(e)}
                                        />
                                        <Typography
                                            sx={bookingwrapper}
                                        >
                                            {isDiscount === 'fix' ? "$" : "$"}{getTotalDiscount()}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={bookingwrapper}>
                                    <Typography sx={bookingwrapper}>Tax {isTaxPercentage ? "in %" : ""}</Typography>
                                    <Box sx={{ display: 'flex', alignItems: "center", gap: '20px' }}>
                                        <Input
                                            type="text"  
                                            startAdornment={<InputAdornment position="start">
                                                {isTaxPercentage ? "" : "$"}
                                            </InputAdornment>}
                                            endAdornment={<InputAdornment position="end">
                                                {isTaxPercentage ? "%" : ""}
                                            </InputAdornment>}
                                            sx={{
                                                ...inputStyle,
                                                width: '80px',
                                                p: '5px',
                                                '&::before': {
                                                    content: 'none',
                                                },
                                            }}
                                            value={tax}
                                            onChange={handleTaxChange}
                                        />
                                        <Typography sx={bookingwrapper}>${getTotalTex()}</Typography>
                                    </Box>
                                </Box>

                                <Box sx={bookingwrapper}>
                                    <Typography sx={bookingwrapper}>Tip</Typography>
                                    <Box sx={{ display: 'flex', alignItems: "center", gap: '20px' }}>
                                        <Input
                                            type='text'
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            sx={{
                                                ...inputStyle,
                                                width: '80px',
                                                p: '5px',
                                                '&::before': {
                                                    content: 'none',
                                                },
                                            }}
                                            value={tip}
                                            onChange={(e) => handleTipChange(e)}
                                        />
                                        <Typography sx={bookingwrapper}>${getTotalTip()}</Typography>
                                    </Box>
                                </Box>

                                <Divider />
                                <Box sx={bookingwrapper}>
                                    {consultationType == "Booking" ?
                                        <Typography sx={bookingwrapper}>Grand Total</Typography>
                                        : <Typography sx={bookingwrapper}>Deposit Amount</Typography>}
                                    <Typography sx={{
                                        fontSize: "22px",
                                        fontFamily: 'helvetica-lt-std-bold',
                                        fontWeight: '700'
                                    }}>
                                        ${getGrandTotal()}
                                    </Typography>
                                </Box>
                                {activeAppointment === 'edit' && parseFloat(getTotalPaidAmount()) > 0 &&
                                    <Box sx={bookingwrapper}>
                                        <Typography sx={bookingwrapper}>Paid Amount</Typography>
                                        <Typography sx={{
                                            fontSize: "22px",
                                            fontFamily: 'helvetica-lt-std-bold',
                                            fontWeight: '700'
                                        }}>
                                            - ${getTotalPaidAmount()}
                                        </Typography>
                                    </Box>}
                                {Object.entries(allInvoices)?.length > 0 &&
                                    Object.entries(allInvoices).map(([key, value], index) => (
                                        <Box sx={bookingwrapper} key={index}>
                                            <Typography sx={bookingwrapper}>{value?.name}</Typography>
                                            <Box sx={{ display: 'flex', alignItems: "center", gap: '20px' }}>
                                                {value?.payment_method_id === 2 && <Input
                                                    type='text'
                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                    sx={{
                                                        ...inputStyle,
                                                        width: '80px',
                                                        p: '5px',
                                                        '&::before': {
                                                            content: 'none',
                                                        },
                                                    }}
                                                    onBlur={() => handleSaveWallet(walletBalance, value)}
                                                    value={walletBalance}
                                                    onChange={(e) => handleWalletChange(e)}
                                                />}
                                                <Typography sx={bookingwrapper}>-${value?.amount}</Typography>
                                            </Box>
                                        </Box>
                                    ))
                                }
                                <Divider />
                                <Box sx={bookingwrapper}>
                                    <Typography sx={{ ...bookingwrapper, fontFamily: 'helvetica-lt-std-bold' }}>
                                        Due Amount
                                    </Typography>
                                    <Typography sx={{
                                        ...bookingwrapper,
                                        fontFamily: 'helvetica-lt-std-bold'
                                    }}>${totalDue()}</Typography>
                                </Box>
                                {activeAppointment === 'edit' &&
                                    appointmentDetailsById?.payment_status === 'Paid' &&
                                    parseFloat(getGrandTotal()) < parseFloat(getTotalPaidAmount()) &&
                                    <Divider />
                                }
                                {activeAppointment === 'edit' &&
                                    appointmentDetailsById?.payment_status === 'Paid' &&
                                    parseFloat(getGrandTotal()) < parseFloat(getTotalPaidAmount()) &&
                                    <Box sx={bookingwrapper}>
                                        <Typography sx={{
                                            ...bookingwrapper,
                                            fontFamily: 'helvetica-lt-std-bold',
                                            color: '#27AE60',
                                        }}>
                                            Wallet Point will add
                                        </Typography>
                                        <Typography
                                            sx={{
                                                ...bookingwrapper,
                                                fontFamily: 'helvetica-lt-std-bold',
                                                color: '#27AE60',
                                            }}
                                        >
                                            ${(parseFloat(getTotalPaidAmount()) - parseFloat(getGrandTotal())).toFixed(2)}
                                        </Typography>
                                    </Box>
                                }
                            </div>
                        </Card>
                        {isDataAvailable || bookingType == "giftcard"  ? (

                            <Card sx={{ mt: 2, minHeight: '100px' }}>
                                <Box
                                    sx={{
                                        px: 2,
                                        pt: 2,
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <FormControl>
                                        <FormLabel sx={{ ...labelStyle, mb: '5px' }}>Select Your Payment Method</FormLabel>
                                        <RadioGroup
                                            defaultValue="now"
                                            row
                                            value={futurePayment}
                                            onChange={handleChangeOption}

                                        >
                                            <FormControlLabel
                                                value="now"
                                                size="small"
                                                control={<Radio />}
                                                label="Pay Now"
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 20,
                                                    },
                                                }}
                                            />
                                            {bookingType !== 'giftcard' && (
                                                <FormControlLabel
                                                    value="future"
                                                    size="small"
                                                    control={<Radio />}
                                                    label="Future Payment"
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: 20,
                                                        },
                                                    }}
                                                />
                                            )}

                                        </RadioGroup>
                                    </FormControl>

                                    {Object.entries(allInvoices)?.length > 0 && futurePayment !== 'future' && <Box
                                        sx={{
                                            mt: '-5px',
                                            mr: '-5px',
                                        }}
                                    >
                                        <Tooltip title={"Reset Payment Ways"}>
                                            <IconButton
                                                color="error"
                                                onClick={() => {
                                                    setAllInvoices({})
                                                }}
                                            >
                                                <RestartAltIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>}
                                </Box>
                                {
                                    futurePayment === "now" &&
                                    <Box sx={{ pb: 2 }}>
                                        <Box sx={{ p: "5px", background: '#EDF2F7' }}> </Box>
                                        <Box sx={{
                                            px: 2,
                                            pt: 1,
                                            display: 'flex',
                                            columnGap: '40px',
                                            flexWrap: 'wrap',
                                            alignItems: 'center'
                                        }}>
                                            {provider_payment?.length && provider_payment.map((method, index) => {
                                                if (method?.id !== 1) {
                                                    if (selectedUser?.id && parseFloat(selectedUser?.wallet_point) > 0 && method?.id === 2) {
                                                        return <FormControlLabel
                                                            key={index}
                                                            value={method?.name}
                                                            size="small"
                                                            control={
                                                                <Checkbox
                                                                    checked={!!allInvoices[method?.id]?.amount}
                                                                    onChange={(e) => handleClickMethod(method, e)}
                                                                />
                                                            }
                                                            label={"Use Wallet"}
                                                            sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 20,
                                                                },
                                                            }}
                                                        />
                                                    } else if (method?.id !== 2) {
                                                        return <FormControlLabel
                                                            key={index}
                                                            value={method?.name}
                                                            size="small"
                                                            control={
                                                                <Checkbox
                                                                    checked={!!allInvoices[method?.id]?.amount}
                                                                    onChange={(e) => handleClickMethod(method, e)}
                                                                />
                                                            }
                                                            label={method?.name}
                                                            sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 20,
                                                                },
                                                            }}
                                                        />
                                                    }
                                                }
                                            })}
                                            {provider_payment?.length && provider_payment.map((method, index) => {
                                                if (paymentModal === method?.id) {
                                                    return <Modal
                                                        key={index}
                                                        open={paymentModal === method?.id}
                                                    >
                                                        <Box sx={modalStyle}>
                                                            {method?.is_gateway === 1 ?
                                                                <Grid container>
                                                                    <Grid item xs={12} sx={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'space-between'
                                                                    }}>

                                                                        {useCard && <Box sx={{
                                                                            width: 'max-content',
                                                                            cursor: 'pointer'
                                                                        }} onClick={() => setUseCard(false)}>
                                                                            <img src={arrow} alt={'back-arrow'} />
                                                                        </Box>}
                                                                        {method?.payment_gateway === 'BrainTree' && !useCard &&
                                                                            <Braintree style={{ marginTop: '-8px' }} />
                                                                        }
                                                                        {!useCard &&
                                                                            <Typography sx={{ fontSize: '18px' }}>
                                                                                Setup Payment
                                                                            </Typography>}

                                                                        <IconButton
                                                                            size="small"
                                                                            sx={{ p: '0' }}
                                                                            onClick={() => handleCloseModal(method)}
                                                                        >
                                                                            <Close />
                                                                        </IconButton>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        {useCard ?
                                                                            <UserCardsList
                                                                                userCards={userCards}
                                                                                setUseCard={setUseCard}
                                                                                handleBrainTree={handleBrainTree}
                                                                                handleSquare={handleSquare}
                                                                                paymentMethod={method}
                                                                                totalDue={totalDue()}
                                                                            />
                                                                            :
                                                                            <CardPayment
                                                                                userCards={userCards}
                                                                                setUseCard={setUseCard}
                                                                                useCard={useCard}
                                                                                setLoading={setLoading}
                                                                                handleBrainTree={handleBrainTree}
                                                                                handleSquare={handleSquare}
                                                                                paymentMethod={method}
                                                                                totalDue={totalDue()}
                                                                            />
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                                : method?.labels?.length > 0 ?
                                                                    <Grid
                                                                        container
                                                                        sx={{ alignItems: "center", }}
                                                                    >
                                                                        <Grid item xs={12} sx={{
                                                                            display: 'flex',
                                                                            justifyContent: 'flex-end'
                                                                        }}>
                                                                            <IconButton
                                                                                size="small"
                                                                                sx={{}}
                                                                                onClick={() => handleCloseModal(method)}
                                                                            >
                                                                                <Close />
                                                                            </IconButton>
                                                                        </Grid>
                                                                        <Grid item xs={12} sx={{ mt: '-49px', '@media (max-width: 420px) ': { marginTop: '-10px' } }}>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "18px",
                                                                                    textAlign: "center"
                                                                                }}
                                                                            >
                                                                                {"Enter " + method?.name}
                                                                            </Typography>
                                                                        </Grid>
                                                                        {
                                                                            Object.entries(allInvoices[method?.id])?.length > 0 &&
                                                                            Object.entries(allInvoices[method?.id])
                                                                                .map(([key, value], index) => {
                                                                                    if (key !== 'name' && key !== "payment_method_id" && key !== "transactionId" && key !== "institution_name" && key !== "approval_date" && key !== "approval_code") {
                                                                                        return (
                                                                                            <Grid
                                                                                                key={index}
                                                                                                item
                                                                                                xs={12}
                                                                                                sx={{
                                                                                                    marginTop: '24px',
                                                                                                }}
                                                                                            >
                                                                                                <FormControl fullWidth>
                                                                                                    <FormLabel sx={labelStyle}>
                                                                                                        {key}
                                                                                                    </FormLabel>
                                                                                                    <TextField
                                                                                                        value={value || ''}
                                                                                                        fullWidth
                                                                                                        onChange={(e) => handleLabelsInputChange(e?.target?.value, key, method, bookingType)}
                                                                                                        sx={{ ...inputStyle }}
                                                                                                        placeholder={`Enter ${key}`}
                                                                                                        type='number'
                                                                                                        inputProps={{
                                                                                                            style: {
                                                                                                                'moz-appearance': 'textfield',
                                                                                                                'webkit-appearance': 'none',
                                                                                                                'appearance': 'none',
                                                                                                            },
                                                                                                            step: 'any'
                                                                                                        }}
                                                                                                    />
                                                                                                </FormControl>
                                                                                            </Grid>
                                                                                        );
                                                                                    } else {
                                                                                        return null;
                                                                                    }
                                                                                })
                                                                        }
                                                                        {
                                                                            method?.name === "E-Transfer/CC (External Device)" && (
                                                                                <Grid item xs={12} sx={{ marginTop: '24px' }}>
                                                                                    <FormControl fullWidth>
                                                                                        <FormLabel sx={labelStyle}>
                                                                                            Transaction Id
                                                                                        </FormLabel>
                                                                                        <TextField
                                                                                            value={allInvoices[method?.id]?.transactionId || ''}
                                                                                            fullWidth
                                                                                            onChange={(e) => handleLabelsInputChange(e.target.value, 'transactionId', method, bookingType)}
                                                                                            sx={{ ...inputStyle }}
                                                                                            placeholder="Enter Transaction Id"
                                                                                        />
                                                                                    </FormControl>
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                        {

                                                                            method?.name === "3rd Party Financing" && (
                                                                                <>
                                                                                    <Grid item xs={12} sx={{ marginTop: '24px' }}>
                                                                                        <FormControl fullWidth>
                                                                                            <FormLabel sx={labelStyle}>
                                                                                                Institution Name
                                                                                            </FormLabel>
                                                                                            <TextField
                                                                                                value={allInvoices[method?.id]?.institution_name || ''}
                                                                                                fullWidth
                                                                                                onChange={(e) => handleLabelsInputChange(e.target.value, 'institution_name', method, bookingType)}
                                                                                                sx={{ ...inputStyle }}
                                                                                                placeholder="Enter Institution Name"
                                                                                            />
                                                                                        </FormControl>
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sx={{ marginTop: '24px' }}>
                                                                                        <FormControl fullWidth>
                                                                                            <FormLabel sx={labelStyle}>
                                                                                                Approval Date
                                                                                            </FormLabel>
                                                                                            <TextField
                                                                                                value={allInvoices[method?.id]?.approval_date || ''}
                                                                                                fullWidth
                                                                                                onChange={(e) => handleLabelsInputChange(e.target.value, 'approval_date', method, bookingType)}
                                                                                                sx={{ ...inputStyle }}
                                                                                                type='date'
                                                                                                placeholder="Enter Approval Date"
                                                                                            />
                                                                                        </FormControl>
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sx={{ marginTop: '24px' }}>
                                                                                        <FormControl fullWidth>
                                                                                            <FormLabel sx={labelStyle}>
                                                                                                Approval Code
                                                                                            </FormLabel>
                                                                                            <TextField
                                                                                                value={allInvoices[method?.id]?.approval_code || ''}
                                                                                                fullWidth
                                                                                                onChange={(e) => handleLabelsInputChange(e.target.value, 'approval_code', method, bookingType)}
                                                                                                sx={{ ...inputStyle }}

                                                                                                placeholder="Enter Approval Code"
                                                                                            />
                                                                                        </FormControl>
                                                                                    </Grid>
                                                                                </>
                                                                            )
                                                                        }
                                                                        <Grid item xs={12} sx={{
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            mt: '24px'
                                                                        }}>
                                                                            <CustomButton handleClick={() => handleConfirm(allInvoices[method?.id], method)} val={"Confirm"} />
                                                                        </Grid>


                                                                    </Grid>
                                                                    :
                                                                    <Grid
                                                                        container
                                                                        spacing={1}
                                                                        sx={{ alignItems: "center", }}
                                                                    >
                                                                        <Grid item>
                                                                            <Typography 
                                                                                sx={{
                                                                                    fontSize: "18px",
                                                                                    textAlign: "center"
                                                                                }}
                                                                            >
                                                                                Payment Method Not configure. Contact
                                                                                your provider
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                            }
                                                        </Box>
                                                    </Modal>
                                                }

                                            })}

                                        </Box>
                                        <br></br>
                                        <Box sx={{display:"flex",gap:"3",marginLeft:"19px"}}>
                                            <Box>
                                                <FormControlLabel
                                                    // key={index}
                                                    // value={method?.name}
                                                    size="small"
                                                    control={
                                                        <Checkbox
                                                            checked={apiValue === 1}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                    }
                                                    label={"Send Notification"}
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: 20,
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box sx={{ marginTop: "10px", px: 2, }}>
                                            <CustomButton
                                                size={'large'} mainColor={'#27AE60'}
                                                style={{width:"200px", "&:hover": {
                                                        borderColor:"#27AE60",
                                                    },}}
                                                handleClick={handleBookAppointment} val={'Confirm Sale '} />

                                        </Box>

                                    </Box>
                                }
                                {futurePayment === "future" && (


                                    <Box sx={{display:"flex",gap:"3",marginLeft:"19px"}}>
                                        <Box>
                                            <FormControlLabel
                                                // key={index}
                                                // value={method?.name}
                                                size="small"
                                                control={
                                                    <Checkbox
                                                        checked={apiValue === 1}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                }
                                                label={"Send Notification"}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 20,
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </Box> )
                                }
                                {futurePayment === "future" && (
                                    <Box sx={{ p: 2 }}>
                                        <CustomButton
                                            size={'large'} mainColor={'#27AE60'}
                                            style={{width:"200px", "&:hover": {
                                                    borderColor:"#27AE60",
                                                },}}
                                            handleClick={handleBookAppointment} val={'Pending Sale'} />
                                    </Box>
                                )}
                            </Card>
                        ) : (
                            
                                <Box sx={{
                                    p: "12px",
                                    minHeight: '150px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: "4px",
                                    mt: 2,
                                    background: '#fff',
                                }}> 
                                    <Typography sx={{ fontFamily: "helvetica-lt-std-bold" }}>
                                        Select Appointment Date and Time
                                    </Typography>
                                </Box>
                            ) 
                    
                        
                        
                        }
                    </Box>
                    :
                    <Box sx={{ background: "#EDF2F7", p: '12px', borderRadius: "4px", mt: 2, }}>
                        <Box sx={{
                            p: "12px",
                            minHeight: '200px',
                            display: 'flex',
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            borderRadius: "4px",
                            background: '#fff',

                        }}>
                            <Typography sx={{ fontFamily: "helvetica-lt-std-bold" }}>Select Service or plan</Typography>
                        </Box>
                    </Box>
                }
            </Card>
        </Box>
    )
};

export default AppointmentPayment