import React, {useState} from 'react';
import PublicHeader from "../../components/public-header";
import PublicLayout from "../../layout/PublicLayout";
import '../login/styles.scss'
import FormInput from "../../components/form/formInput";
import {Link} from "react-router-dom";
import FormButton from "../../components/form/formButton";
import {Formik} from "formik";
import * as Yup from "yup";
import {emailVerification, loginEmail, loginUser,} from "../../store/auth";
import {useDispatch} from "react-redux";
import toast from "react-hot-toast";
import {useNavigate} from "react-router";
import Loader from "../../components/loader/loader";

const Verification = () => {
    const [loading,setLoading] = useState(false)
    const navigate =useNavigate()
    const dispatch =useDispatch()
    let storedEmail = localStorage.getItem('registeredEmail')
    const extractSubdomain = () => {
        const hostname = window.location.hostname;
        const hostnameParts = hostname.split('.');
        return hostnameParts.length > 2 ? hostnameParts[0] : null;
      };
    const subdomain = extractSubdomain();
    const initialValues = {
        email: "" || storedEmail ? storedEmail :'',
        otp: "" ,
        social_login: 0

    }; 


    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email address").required("Email is Required"),
        otp: Yup.string().matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            "Alphabet and Special Character are  not allowed"
        ).min(6, 'Must be exactly 6 digits')
            .max(6, 'Must be exactly 6 digits').required("OTP is Required"),
    });
 
    const onSubmit=async (data, {setSubmitting}) => {

        setLoading(true)
        let res =await dispatch(emailVerification(data))
        if (res?.payload?.success){
            let res = await dispatch(loginEmail(data));
 
            toast.success(res?.payload?.message, {duration:3000})
            // navigate('/login')
  
            if (res?.payload?.success && res?.payload?.data?.id) {       
                localStorage.setItem("token", res?.payload?.data?.token);   
            console.log("res data",res?.payload?.data)
             
                  console.log("app working", subdomain);
                  const mobileBreakpoint = 768;
                  const screenWidth = window.innerWidth;
               
                  if (subdomain === "app") {   
                    console.log("app works");
                    // Redirect based on screen width
                    if (screenWidth <= mobileBreakpoint) {
                      // Mobile devices
                      navigate("/home");
                    } else {
                      // Desktop devices
                      // Ensure URL is correctly formed and user object is not undefined
                      // if (user && user.explore_services_url) {
                        const fullUrl = `${res?.payload?.data.explore_services_url}/services/#services`;
                        window.location.href = fullUrl;
                      // } else {
                      //   console.error("User or explore_services_url is undefined.");
                      // }
                    } }
                  else {
                    // Handling for non-"app" subdomain
                    navigate("/admin/configuration/roles");
                  }  
            
                  toast.success("Logged Successfully", { duration: 3000 });
                   
              } 

        }
        if (res?.payload?.success === false){
            toast.error(res?.payload?.errors, {duration:3000})
        }
        setSubmitting(false);
        setLoading(false)
    }
    return (
        <PublicLayout> 
            {loading ? <Loader fullSize/> : ''}
            <div className={'login-card'}>
                <PublicHeader
                    heroText={"Verify your Account"}
                    // heroSubText={"Verify"}  
                    to={"/login"}
                />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({values,isSubmitting,handleSubmit, errors, handleChange, touched}) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <FormInput
                                name={"email"}
                                label={"Email"}
                                placeholder={'Type your e-mail'}
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                            />
                            <FormInput
                                name={"otp"}
                                label={"Enter OTP"}
                                placeholder={'Type your OTP'}
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                            />
                            <div className={'forget-box'}>
                                <Link to={'/resend-otp'}>Resend OTP</Link>
                            </div>
                            <FormButton
                                disable={isSubmitting}
                                text={'Verify'}
                            />
                        </form>
                    )
                }}
            </Formik>
            </div>
        </PublicLayout>
    );
};

export default Verification;