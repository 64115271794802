import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CardMedia,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import homeoutline from '../../assets/images/homeoutline.png'
import booking from "../../assets/images/Vector (Stroke).png"; 
import menu from '../../assets/images/homeIcon.png'
import Link from '@mui/material/Link';
import Slide from "react-reveal/Slide";
import plan from "../../assets/images/wallet2.png";
import profile from "../../assets/images/user.png";
import BookingTimer from "../../components/timer/timer";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';
import Login from "../login";
import { useDispatch, useSelector } from "react-redux";
import ContactForm from "./ContactForm";
import { registerUser, fetchUserToken } from "../../store/auth";
import {
  ApplyGiftCard,
  ApplyPromoCode,
  bookCustomerAppointment,
  findGuestUser,
} from "../../store/booking-widget";
import toast from "react-hot-toast";
import Confirmation from "./confirmation";
import PayByCard from "./payByCard";
import { ReactComponent as BackArrow } from "../../assets/icon/arrow-back.svg";
import Loader from "../../components/loader/loader";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import Waveloading from "../loading/Waveloading";
import Footer from "../home/Footer";

export const bookingInput = {
  ".MuiInputBase-input": {  
    borderColor: " #525252", 
    height: "15px",
    minHeight: "unset",
    WebkitTextFillColor: "#000",
    "&:focus": {
      borderColor: "#525252!important",
      outline: "none",   
    },
  },
};
export const inputStyle = {
  background: "#EDF2F7",
  borderRadius: "4px",
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiInputBase-input": {
    borderColor: " #EDF2F7",
    height: "18px",
    minHeight: "unset",
    "&:focus": {
      borderColor: "#EDF2F7",
      outline: "none",
      input: {
        height: "18px",
      },
    },
    "&:hover": {
      borderColor: "#EDF2F7",
      outline: "none",
    },
  },
};
const invoicePayment = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "auto",
  bgcolor: "background.paper",
  p: "27px 12px",
  borderRadius: "6px",
  overflowX: 'auto',
  "@media (max-width: 550px)": {
    width: "95%",

  },
  gap: "5px",
}
export const bookingTextArea = {
  ".MuiInputBase-input": {
    borderColor: " #525252",
    height: "15px",   
    minHeight: "unset", 
    //  color :" black",
    //  fontWeight:"bold",    
    "&:focus": {     
      borderColor: "#FF0000!important",    
      outline: "none",
    }, 
  }, 
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'red', // This sets the border color to red on focus
    },
  },
};
const shouldBeChecked = (user, item) => {
  return (
      (user?.id &&
          item?.payment_method?.id === 2 &&
          user?.wallet_point &&
          user?.is_membered === 1 &&
          parseFloat(user?.wallet_point) > 0) ||
      (
          item?.payment_method?.id !== 2 &&
          item?.payment_method?.id < 5
      )
  );
};
const BookingContact = ({
  item,
 selectedServices,  
  handleReset,
  selectedTime, 
  provider,
  selectedDate,
  selectedStaff,   
  providerBookingSettings,
  SelectedLocation,
}) => {
  const [loading, setLoading] = useState(false); 
  const [description, setDescription] = useState("");
  const [registerData, setRegisterData] = useState(null);  
  const [active, setActive] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const [isPaid, setIsPaid] = useState(0);
  const [selectedMethods, setSelectedMethods] = useState([]);
  const [isFamilyMember, setIsFamilyMember] = useState(false);
  const [fullName, setFullName] = useState("");  
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [loginOpen, setOpenLogin] = useState(false);
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [load, setLoad] = useState(false);
  const [loadcard, setLoadcard] = useState(false);
  const [card, setCard] = useState("");
  const authStore = useSelector((state) => state?.authStore);
  const handleCloseInvoice = () => setOpenInvoice(false);
  const handleCloseCard = () => setOpenCard(false);
  const bookingWidgetStore = useSelector((state) => state?.bookingWidgetStore)
  // const {promos = [],gifts=[]} = bookingWidgetStore
  const [promos, setPromos] = useState([]);
  const [gifts, setGifts] = useState([]);
  const [openinvoice, setOpenInvoice] = useState(false);
  const [discountvalue, setDiscountvalue] = useState(null);
  console.log("gifts value",promos)
  const [opencard, setOpenCard] = useState(false);
  const [error, setError] = useState('');
  const [errors, setErrors] = useState('');
  const { user } = authStore;
  const navigate = useNavigate();
  let priceShow = 1;
  let promoDiscount = 0;
  let  giftDiscount = 0;
    let service_type = 'booking'
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("type")) {
         service_type = queryParams.get("type"); 
    }  
  console.log("Booking Contact:"+service_type);
  useEffect(() => {
    if (bookingWidgetStore) {
      setPromos(bookingWidgetStore.promos || []);  // Set promos from Redux state
      setGifts(bookingWidgetStore.gifts || []);    // Set gifts from Redux state
    }
  }, [bookingWidgetStore]); 
  useEffect(() => {
    setPromos([])
    setGifts([])
    
      // Reset promos when selectedServices change
  }, [selectedServices,selectedTime,selectedDate,]); 
 
  useEffect(() => {
    if (service_type === "booking" || service_type === "Consultation") {
      if (selectedServices?.length > 0) {
        selectedServices.forEach((item) => {
          if (service_type === "booking" && item?.regular_price == 0 && item?. is_upfront_enable == 0) {
            setIsPaid(0);
          } else if (item?.is_free === 0) {
            setIsPaid(isPaid + 1);
          }
        });
      }
    } else if (service_type === "Virtual Consultation") {
      if (selectedServices?.length > 0) {
        selectedServices.forEach((item) => {
          if (item?.virtual_paid === 1) {
            setIsPaid(isPaid + 1);
          }else{
            setIsPaid(0);
          }
        });
      }
    }
  }, [selectedServices, user?.id]);
 

  const handleOpenInvoice = async (invoiceId) => {
    setOpenInvoice(true)  
  };
  const handleOpenCard = async (invoiceId) => {
    setOpenCard(true)  
  };


  // setOpenCard
  console.log("isPaid"+isPaid)
  useEffect(() => { 
    console.log('PayByCard Rendered');
}, [selectedMethods]); 
 
console.log("issue fixed ", user)
  const getSubTotal = () => {
    let total = 0;
    let price = 0;
    let is_upfront  = 0;

      if (service_type === "booking") {
      selectedServices.forEach((item) => {
        if (item?.is_free === 0) {
            priceShow = item?.is_price_show;
          if (user?.id && user?.is_membered === 1 && !isFamilyMember) {
              if(item?.is_price_show === 0 && item?.is_upfront_enable === 1){
                  total = 0;
              }else{
                  total = total + parseFloat(item?.member_price);
              }
          } else { 
              if(item?.is_price_show === 0 && item?.is_upfront_enable === 1){
                  if(item?.is_upfront_percentage === 1){   
                       price = (item?.upfront_amount/100) * item?.regular_price;
                  }else{
                       price = item?.upfront_amount;
                  }    
                  is_upfront = 1; // Set is_upfront to 1 when applicable
                  total = total + parseFloat(price);
              }else{
                  if(item?.is_promo === 1){
                      total = total + parseFloat(item?.member_price);
                  }else{
                      total = total + parseFloat(item?.regular_price);
                  }
              }
          }
        }
      });
    } else if (service_type === "Consultation") {
      total = parseFloat(providerBookingSettings?.consultation_fee);
      is_upfront = 1; // Set is_upfront to 1 when applicable
      }else if (service_type === "Virtual Consultation") {
        selectedServices.forEach((item) => {
          if(item?.virtual_paid === 1) {
            if(item?.is_upfront_enable === 1){
              if(item?.is_upfront_percentage === 1){
                price = (item?.upfront_amount/100) * item?.regular_price;
              }else{
                price = item?.upfront_amount;
              }
              total = total + parseFloat(price);
            }else{
              if(user?.id && user?.is_membered === 1){
                price = price + parseFloat(item?.member_price);
              }else{
                price = price + parseFloat(item?.regular_price);
              }
              total = total + parseFloat(price);
            }
          }else{
            is_upfront = 1; // Set is_upfront to 1 when applicable
          }
          });
      }
      // Calculate the promo discount
  if (promos?.discount_value) {
    if (promos?.discount_type === "Percentage") {
      promoDiscount = parseFloat(((promos?.discount_value / 100) * total).toFixed(2));
    } else {
      promoDiscount = promos?.discount_value;
    }
  }

  // setDiscountvalue(promoDiscount); 
  // Subtract promo discount from total
  total = total - promoDiscount;

  return { total, promoDiscount,is_upfront} }
    // let discountValue = promoDiscount; 
    // setDiscountvalue(discountValue)
    // // let giftValue = giftDiscount;
    // total=total-discountValue;
    // // total=total-giftValue;
    // return {total, ;
  
  const isPercentage = providerBookingSettings?.default_tax_in_percentage === 1;

  const getFinalTax = () => { 
    let total = getSubTotal().total;
    let is_upfront = getSubTotal().is_upfront;

    if(is_upfront === 1){ 
        return parseFloat(0);    
    }else{
        if (isPercentage) {  
            return parseFloat(
                (
                    (total / 100) *
                    parseFloat(providerBookingSettings?.tax_booking_amount)
                ).toFixed(2) 
            );
        } else {
            return parseFloat(providerBookingSettings?.tax_booking_amount);
        }
    }
 
  };
  const [finalTotal, setFinalTotal] = useState("0.00");
  const [giftDiscountApplied, setGiftDiscountApplied] = useState("0.00");
  const [remainingGiftBalance, setRemainingGiftBalance] = useState("0.00");
  
  useEffect(() => {
    const calculateFinalTotal = () => {
      let total = parseFloat(getSubTotal().total);
      let totalTax = parseFloat(getFinalTax());
      let calculatedFinalTotal = total + totalTax;
      let appliedGiftDiscount = 0; // The amount actually applied from the gift card
      let remainingBalance = 0; // The remaining balance on the gift card
  
      // Check if gifts?.amount exists and apply it
      if (gifts?.remaining_amount) {
        const giftDiscount = gifts.remaining_amount;
        if (giftDiscount >= calculatedFinalTotal) {
          // If the gift card covers the entire final total
          appliedGiftDiscount = calculatedFinalTotal;
          remainingBalance = giftDiscount - calculatedFinalTotal;
          calculatedFinalTotal = 0;
        } else {
          // If the gift card partially covers the final total
          appliedGiftDiscount = giftDiscount;
          calculatedFinalTotal -= giftDiscount;
          remainingBalance = 0;
        }
      }
  
      // Update state variables
      setGiftDiscountApplied(appliedGiftDiscount);
      setRemainingGiftBalance(remainingBalance);
      setFinalTotal(calculatedFinalTotal > 0 ? calculatedFinalTotal.toFixed(2) : "0.00");
    };
  
    calculateFinalTotal();
  }, [gifts]); // Dependencies to re-run the calculatio
  
  const handleBookAppointment = async (token, cardToken) => {
    console.log("appointment booking", cardToken) 
    setLoading(true);
    let selectedServicesIds = [];
    selectedServices.forEach((item) => { 
      selectedServicesIds.push(item?.id);  
    });

    let bookingPayload = {
      service_ids: selectedServicesIds, 
      provider_id: provider?.id,
      date: selectedDate,
      token: token,
      used_amount:giftDiscountApplied,
      remaning_amount:remainingGiftBalance,
      gift_card_id:gifts?.id,
      gift_code:gifts?.code,
      discount_value:promoDiscount,
      promo_code:promos?.discount_code,
      start_time: selectedTime?.start,
      end_time: selectedTime?.end,  
      upfront_amount: service_type === "booking"
          ? 0
          : getSubTotal().total,
      type:
        service_type === "booking"
          ? "Booking"
          : service_type === "Consultation"
          ? "Consultation"
          : "Virtual Consultation",
    };
    if (selectedStaff?.id) {
      bookingPayload["staff_id"] = selectedStaff?.id;
    }

    let payAtClinic;
    let payUsingWallet;

    provider?.provider_payment?.forEach((item) => {
      if (
        item?.payment_method?.name === "Pay At Clinic" &&
        item?.payment_method?.id === 1
      ) {
        payAtClinic = item?.payment_method?.id;
      }
      if (
        item?.payment_method?.name === "Use My wallet Point" &&
        item?.payment_method?.id === 2  
      ) {
        payUsingWallet = item?.payment_method?.id; 
      }
    });

    let invoices = []; 

    // if (selectedMethods?.length > 0) { 
    // || selectedMethods?.some((item) => item?.id === 1)
    if (isPaid > 0) {
      const  isCheck =
        checkWalletMethod() &&
        user?.id &&
        getSubTotal().total + getFinalTax() > parseFloat(user?.wallet_point);
      selectedMethods.forEach((item) => {
        if (
          item?.payment_method?.name === "Use My wallet Point" &&
          item?.payment_method?.id === 2  
        ) {
          if (isCheck) {
            invoices.push({
              payment_method_id: item?.payment_method_id,     
              amount: parseFloat(user?.wallet_point),
              // "tax":  calculateTax(parseFloat(user?.wallet_point))
            });
          } else {
            invoices.push({
              payment_method_id: item?.payment_method_id,  
              amount: finalTotal, 
              // "tax": getFinalTax()
            });
          } 
        } else if (  
          item?.payment_method?.name === "Pay At Clinic" &&  
          item?.payment_method?.id === 1
        ) {
          invoices.push({
            payment_method_id: item?.payment_method_id,
            amount: isCheck    
              ? getSubTotal().total + getFinalTax() - parseFloat(user?.wallet_point)
              : finalTotal,
            // "tax": isCheck ? calculateTax((getSubTotal().total + getFinalTax()) - parseFloat(user?.wallet_point)) :getFinalTax(),
          });
        } else if (item?.payment_method?.id === 4) {
          invoices.push({
            payment_method_id: item?.payment_method_id,
            amount: isCheck
              ? getSubTotal().total + getFinalTax() - parseFloat(user?.wallet_point)
              : finalTotal,
            // "tax": isCheck ? calculateTax((getSubTotal().total + getFinalTax()) - parseFloat(user?.wallet_point)) :getFinalTax(),
            token: cardToken,
          });
        } else if (item?.payment_method?.id === 3) {
          invoices.push({
            payment_method_id: item?.payment_method_id,
            amount: isCheck
              ? getSubTotal().total + getFinalTax() - parseFloat(user?.wallet_point)
              : finalTotal,
            // "tax": isCheck ? calculateTax((getSubTotal().total + getFinalTax()) - parseFloat(user?.wallet_point)) :getFinalTax(),
            token: cardToken,
          });
        }  
      });
    } else {
      invoices.push({ payment_method_id: payAtClinic, amount: 0 }); 
    }

    bookingPayload["invoices"] = invoices;

    if (isFamilyMember && user?.id) {
      bookingPayload["is_family_friend"] = true;
      bookingPayload["full_name"] = fullName; 
      bookingPayload["phone_number"] = phone;
      bookingPayload["email"] = email;
      
    }
    bookingPayload["description"] = description
    console.log("payload in description",bookingPayload)  

    console.log("bookingdata",bookingPayload)
    let res = await dispatch(bookCustomerAppointment(bookingPayload));
    if (res?.payload?.success === "true") {
      setActive("confirmation");
      setConfirmation(res?.payload?.data);
      handleReset();

      toast.success(res?.payload?.message, { duration: 4000 });
    } else {   
      const errors = res?.payload?.errors; 
      console.log(errors);
      if (errors) {
        toast.error(errors, { duration: 5000 });
      }
    }
    setLoading(false);
    localStorage.setItem("token", token);
  }

  const handleNextClick = (val) => { 
    setActive(val);
  };
  const handleEmailBlur = async (val) => {   
    let res = await dispatch(findGuestUser({ email: val }));

    if (res?.payload?.success === "true") {
      return res?.payload?.data;
    } else {
      return null;
    }
  };
  const shouldRedirectToLogin = !user?.id || user?.role_id !== 3;
  const handleBoxClick = () => {
    if (shouldRedirectToLogin) {
      setOpenLogin(true);   
    } else {
      navigate("/user/bookings");       
    }
  };
  const handleplan = () => {
    if (shouldRedirectToLogin) {
      setOpenLogin(true);
    } else {  
      navigate("/user/membership")
    }
  };
  const handleHome = () => {
    navigate("/home");
  };  
  const handleprofile = () => {
    if (shouldRedirectToLogin) {  
      setOpenLogin(true);
    } else { 
      
      navigate("/user/user-account");
    }  
  };
  const handleSubmitForm = async (data) => {
    // console("booking confirmation ")
    setLoading(true);
 
     let item =provider?.provider_payment
    let token = "";

    if (user?.id) {
      token = localStorage.getItem("token");
    } else {
      let finalData = registerData || data;
      let res = await handleEmailBlur(finalData?.email);

      if (res?.token) {
        token = res?.token;
        setConfirmation(true); 
      } else {
        let res = await dispatch(
          registerUser({ ...finalData, default_provider_id: provider?.id })
        );
        let result = await handleEmailBlur(finalData?.email);
        if (result?.token) {
          token = result?.token;
          setConfirmation(true);
        }
      }
    }

    await handleBookAppointment(token);
    setLoading(false);
  };  
  const checkWalletMethod = () => {
    return (
      selectedMethods?.length > 0 &&
      selectedMethods.some((method) => method?.payment_method_id === 2)
    );
  };
  const [checkedStates, setCheckedStates] = useState({});

  const handleUpdatePlan = async () => {
    if (!code.trim()) {
      setErrors('Promo code is required');
      return;
    }

    // Clear previous error message
    setErrors('');
    let selectedServicesIds = [];
  selectedServices.forEach((item) => {
    selectedServicesIds.push(item?.id); 
  });  

  // Check if there's only one selected service and use its ID directly
  const serviceId = selectedServicesIds.length === 1 ? selectedServicesIds[0] : selectedServicesIds;

  const payload = { 
    service_id: serviceId, 
    provider_id: provider?.id,
    user_id: user?.id,
    promo_code: code,
  };

    try {
      setLoadcard(true)
      const response = dispatch(ApplyPromoCode(payload))
      console.log(response.data); 
      handleCloseInvoice();
      setLoadcard(false)
      setCode("")
      
    
    } catch (error) {
      setLoadcard(false)
   
    }
  };
  const handlegiftcard = async () => {
    if (!card.trim()) {
      setError('Gift Card is required');
      return;
    }

    // Clear previous error message
    setError('');
    let selectedServicesIds = [];
  selectedServices.forEach((item) => {
    selectedServicesIds.push(item?.id); 
  });  

  // Check if there's only one selected service and use its ID directly
  const serviceId = selectedServicesIds.length === 1 ? selectedServicesIds[0] : selectedServicesIds;

  const payload = {
    // service_id: serviceId, 
    provider_id: provider?.id,
    user_id: user?.id,
    gift_code: card,
  };

    try {
      setLoad(true)
      const response = dispatch(ApplyGiftCard(payload))
      console.log( "gift card data ",response.data); 
      handleCloseCard();

      setLoad(false)
      setPromos([])
      setCard("")
    } catch (error) {
      setLoad(false)
   
    }
  }

const handleCheckbox = (item, index, e) => {
  const { checked } = e.target;
  const newCheckedStates = { ...checkedStates };
  newCheckedStates[index] = checked;
  setCheckedStates(newCheckedStates);

  if (checked) {
      if (
          checkWalletMethod() &&
          user?.id &&
          getSubTotal().total + getFinalTax() > parseFloat(user?.wallet_point)
      ) {
          let res = selectedMethods.filter(
              (method) => method?.payment_method_id === 2
          );

          setSelectedMethods([...res, item]);
      } else {
          setSelectedMethods([item]);
      }
  } else {
      setSelectedMethods(selectedMethods.filter((row) => row?.id !== item?.id));
  }
};

const isChecked = (item, selected) => { 
  const found = selected.find((select) => select.id === item?.id);
  return !!found;
};

useEffect(() => { 
  const initialCheckedStates = {};
  const initialSelectedMethods = [];

  provider?.provider_payment.forEach((item, index) => {
      initialCheckedStates[index] = false;
  });

  setCheckedStates(initialCheckedStates);
  setSelectedMethods(initialSelectedMethods);
}, [user, setSelectedMethods]);

  const bookingNow = () => (
    <Grid container>
      <Grid item xs={12} sx={{ mt: "16px", mb: "24px" }}>
        <Typography sx={{ color: "#666666" }}>
          Refunds and cancellations are subject to the
          <Link href="https://3dlifestyle.ca/cancellation-policy/">
          <Typography
            as={"span"}
            sx={{
              color: "#006AFF",
              fontWeight: "bold",
              mx: "3px",
              cursor: "pointer",
              "&:hover": { textDecoration: "underline" },
            }}
          >
            cancellation policy
          </Typography>
          </Link>
          of {provider?.name}. Please contact {provider?.name} to learn more
          about their privacy practices. 
        </Typography>
      </Grid>
      <Grid item xs={12}> 
        <Divider />
      </Grid>
      <Grid item xs={12} sx={{ my: "24px" }}>
        <Typography sx={{ color: "#666666" }}>
            Upon booking, 3D lifestyle will automatically create an account for you with {` `}
            <Link href="https://www.3Dlifestyle.ca" target="_blank"     style={{ fontWeight: 'bold' }} // Replace 'bold' with the desired weight, e.g., '500', '700', etc.
 rel="noopener noreferrer">
                3Dlifestyle.ca
            </Link>.
            You can sign back into 3D Lifestyle using your email address at any time.
        </Typography>
      </Grid>
      <Grid xs={12} item>
        {user?.id ? (
          <Button
            fullWidth
            onClick={() => {
              if (isFamilyMember) {
                if (email && fullName && phone) {
                  handleSubmitForm();
                }
              } else {
                handleSubmitForm();
              }
            }}
            variant={"contained"}
            sx={{ backgroundColor: '#aa2167',color:'#fff', height: '48px',  "&:hover": {
              backgroundColor: "#aa2167",
              color:'#fff'
            }, }}  
          > 
            Book appointment
          </Button>
        ) : (
          <Button
            fullWidth
            variant={"contained"}
            type={"submit"}
            sx={{ backgroundColor: '#E6DAD1',color:'#000', height: '48px',
            "&:hover": {
              backgroundColor: "#E6DAD1",
              color:'#000'
            }, }}
          >
            Book appointment
          </Button>
        )}
      </Grid>
      <Grid item xs={12} sx={{ mt: "24px" }}>
        <Typography sx={{ color: "#666666" }}> 
          By creating this appointment, you acknowledge you will receive
          automated transactional messages from this merchant.
        </Typography>
      </Grid>
    </Grid>  
  ); 
  const refundPolicy = () => ( 
    <Grid container>
      <Grid item xs={12} sx={{ mt: "16px", mb: "24px" }}>
        <Typography sx={{ color: "#666666" }}>
          Refunds and cancellations are subject to the 
          <Link href="https://3dlifestyle.ca/cancellation-policy/">
          <Typography 
            as={"span"}
            sx={{
              color: "#006AFF",
              fontWeight: "bold",
              mx: "3px",
              cursor: "pointer",  
              "&:hover":{ textDecoration: "underline" },
            }}
          >
            cancellation policy
          </Typography>
          </Link>
          of {provider?.name}. Please contact {provider?.name} to learn more
          about their privacy practices.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sx={{ my: "24px" }}> 
        <Typography sx={{ color: "#666666" }}>
            Upon booking, 3D lifestyle will automatically create an account for you with {` `}
            <Link href="https://www.3Dlifestyle.ca" target="_blank" rel="noopener noreferrer">
                3Dlifestyle.ca
            </Link>.
            You can sign back into 3D Lifestyle using your email address at any time.
        </Typography>
      </Grid>
    </Grid>
  );
  const displayPaid = () =>
    isPaid > 0 && (
      <Grid container>
        {(service_type === "booking" || service_type === "Consultation" || service_type === "Virtual Consultation") && (
          <Grid item xs={12} sx={{ mt: "16px", mb: "24px",display: { xs: "block", lg: "flex", sm: "flex" },justifyContent:"space-between"}}>

            <Typography
              variant={"h3"}
              sx={{ 
                fontWeight: "bold",
                mb: "24px",
                fontSize: "22px",
                color: "black",
              }}
            >     
              Pay your appointment  
            </Typography> 
            {/* <Box style={{display:"flex",gap:"10px"}}>
            { user?.id ?
            <Button variant="contained"  style={{fontSize:"12px"}}onClick={handleOpenInvoice}> Apply Promo</Button>
:""}
  { user?.id ?   
            <Button variant="contained" style={{fontSize:"12px"}} onClick={handleOpenCard}> Apply GiftCard</Button>
:""}
            </Box>   */}
           
             
          </Grid>  
        )}
         {(service_type === "booking" || service_type === "Consultation" || service_type === "Virtual Consultation") && (
        <Grid item xs={12}>
        {service_type === "Consultation" ? (
      <Typography sx={{ color: "#000"  }}>
        A payment of $50 as a deposit is required to hold your time slot and space for you. <br/>
        This deposit is deducted against any treatments which shall be taken.
      </Typography>
    ) : (   
      <Typography sx={{ color: "#000" }}>
        A payment of  ${finalTotal} is required to book your appointment.
      </Typography>
    )}       
        </Grid>
           )}
          <Grid item xs={12}>
            <Divider />
          </Grid> 
          <Grid item xs={12} sx={{ my: "16px" }}>  
            {selectedServices.length > 1 ? (
              <Typography sx={{ color: "#000000", fontSize: "16px" }}>
                Services 
              </Typography>
            ) : (
              <Typography sx={{ color: "#000000", fontSize: "16px" }}>  
                Service
              </Typography>
            )}
   
            {selectedServices?.length > 0 &&
              selectedServices.map((item, index) => ( 
                  <React.Fragment key={index}>
                      <Box
                          sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              mt: "8px",
                          }}
                      >
                          <Box>
                              <Typography sx={{ color: "#000", fontSize: "16px", fontWeight:'bold' }}>
                                  {item?.name}
                                  {service_type === "booking"
                                      ? ''
                                      : service_type === "Consultation"
                                          ? "(In Person Consultation)"
                                          : "(Virtual)"}
                              </Typography>
                          </Box>
                          <Typography sx={{ color: "#000000" }}>
                            {
                              service_type === "Consultation"
                                  ? "$" +item?.upfront_amount // If the service type is "Consultation", display the upfront amount
                                  : item?.virtual_paid
                                      ? item?.is_upfront_enable
                                          ?"$" + item?.upfront_amount
                                          :user?.is_membered === 1 && !isFamilyMember
                                              ? "$" + item?.member_price // If the user is a member (but not a family member), display the member price
                                              : item?.is_promo === 1
                                                  ? "$" + item?.member_price // If there's a promo, display the member price (this seems like it might be an error, as you might want to show a promo price instead)
                                                  : "$" + item?.regular_price// If the service type is "Consultation", display the upfront amount
                                      : user?.is_membered === 1 && !isFamilyMember
                                          ? "$" + item?.member_price // If the user is a member (but not a family member), display the member price
                                          : item?.is_promo === 1
                                              ? "$" + item?.member_price // If there's a promo, display the member price (this seems like it might be an error, as you might want to show a promo price instead)
                                              : "$" + item?.regular_price // Otherwise, display the regular price
                            }
                          </Typography>
                      </Box>
  
                      {item?.is_price_show === 0 && item?.is_upfront_enable === 1 &&
                          <Box
                          sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              mt: "8px",      
                          }}
                      >
                          <Box>
                              <Typography sx={{ color: "#666666", fontSize: "14px" }}>
                                  Deposit Amount    
                              </Typography>
                          </Box>
                          <Typography sx={{ color: "#000000" }}>
                              {item?.is_price_show === 0 && item?.is_upfront_enable === 1 && user?.is_membered === 1
                                  ? "$0.00"
                                  : "$" + item?.upfront_amount}
                          </Typography>   
                      </Box>}
                  </React.Fragment>
              ))}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container sx={{display:"flex" ,gap: {
          lg: "50px", 
          // sm: "5px", 
        },}}>


          {/* promo start */}
          <Grid item lg={5.5} sm={12} xs={12}>
            <Box sx={{
               display: "flex",
               justifyContent: "space-between",
               mt: "8px",
               alignItems:"baseline",
               gap:"20px"
            }}>

           
              <FormControl fullWidth sx={{ marginTop: "5px" }}>
                {/* <InputLabel id="demo-simple-select-label"> promo code</InputLabel> */}
                <TextField
                sx={inputStyle}
                value={code}
                  label='Enter Promo Code here!'
                onChange={(e) => setCode(e.target.value)}     
                error={!!errors}
                // helperText={error}
         
                // required
              />  
                {errors && (
        <Typography variant="body2" sx={{ color: 'red', mt: 1 }}>
          {errors}
        </Typography>
      )}
              </FormControl>
              <Box >
              
              <Button
            variant="contained"
            disabled={loadcard} 
            onClick={handleUpdatePlan}
          >
            { loadcard ? 'Applying...' : 'Apply'}
          </Button>
             
            </Box>
            </Box>

            </Grid>
          
{/* giftcard  start*/}
<Grid item lg={5.5} sm={12} xs={12}>
            <Box sx={{
               display: "flex",
               justifyContent: "space-between",
               mt: "8px",
               alignItems:"baseline",
               gap:"20px"
            }}>

           
              <FormControl fullWidth sx={{ marginTop: "5px" }}>
                {/* <InputLabel id="demo-simple-select-label"> promo code</InputLabel> */}
                <TextField
                sx={inputStyle}
                value={card}
                  label='Enter Gift Card here!'
                onChange={(e) => setCard(e.target.value)}     
                error={!!error}
                // error={errors.title}  
                // helperText={errors.title ? "Title is required" : ""}    
                // required
              />  
               {error && (
        <Typography variant="body2" sx={{ color: 'red', mt: 1 }}>
          {error}
        </Typography>
      )}
              </FormControl>
             
              <Box >
              <Button
            variant="contained"
            disabled={load} 
            onClick={handlegiftcard}
          >
            {load ? 'Applying...' : 'Apply'}
          </Button>
            </Box>
            </Box>

            </Grid>
            </Grid>

          <Grid item xs={12} sx={{ my: "16px" }}>

      
          {promos?.discount_value && (
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: "8px" }}>
        <Box>
          <Typography sx={{ color: "#000000", fontSize: "14px" }}>
            Promo Discount
          </Typography>
        </Box>
        { promos?.discount_type === "Percentage"? 
        <>
        <Typography sx={{ color: "#000000" }}>{promos?.discount_value} %</Typography>
        </> :<>
        <Typography sx={{ color: "#000000" }}>${promos?.discount_value}</Typography>
        </> }


        
      </Box>
    )}
           
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: "16px",
              }}
            >
              <Box>
                <Typography sx={{ color: "#000000", fontSize: "14px" }}>
                  {service_type === "Consultation"
                    ? "Deposit Fee"
                    : "Subtotal"}
                </Typography>
              </Box>
              <Typography  sx={{color: service_type === "Consultation" ? "#FF0000" : "#000000",}}>
                ${getSubTotal().total}
              </Typography>
            </Box>  
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",   
                mt: "16px",
              }}   
            >
              <Box>
                <Typography sx={{ color: "#000000", fontSize: "14px" }}>
                  Tax Amount 
                </Typography>  
              </Box>
              <Typography sx={{ color: "#000000" }}>
                ${getFinalTax()}
              </Typography>
            </Box>
  







{gifts?.remaining_amount && (
  <Box sx={{ display: "flex", justifyContent: "space-between", mt: "8px" }}>
    <Box>
      <Typography sx={{ color: "#000000", fontSize: "14px" }}>
        Gift Card 
      </Typography>
    </Box>
    <Typography sx={{ color: "#000000" }}>
      ${giftDiscountApplied}
    </Typography>
  </Box>
)}

{/* {gifts?.remaining_amount && remainingGiftBalance > 0 && (
  <Box sx={{ display: "flex", justifyContent: "space-between", mt: "8px" }}>
    <Box>
      <Typography sx={{ color: "#000000", fontSize: "14px" }}>
        Remaining Gift Card Balance
      </Typography>
    </Box>
    <Typography sx={{ color: "#FF0000" }}>
      ${remainingGiftBalance}
    </Typography>
  </Box>
)} */}

<Box sx={{ display: "flex", justifyContent: "space-between", mt: "16px" }}>
  <Box>
    <Typography sx={{ color: "#000000", fontSize: "16px", fontWeight: "600" }}>
      Total
    </Typography>
  </Box>
  <Typography sx={{ color: "#000000", fontSize: "16px", fontWeight: "600" }}>
    ${finalTotal}
  </Typography>
</Box>


          </Grid>
          <Grid item xs={12} mt={"16px"}>
            <Divider />
          </Grid>
          <Grid xs={12} item sx={{ mt: "16px" }}>
  {user?.id ? (
    parseFloat(finalTotal) === 0 ? (
      <Button
        fullWidth
        onClick={() => {
          if (isFamilyMember) {
            if (email && fullName && phone) {
              handleSubmitForm();
            }
          } else {
            handleSubmitForm();
          }
        }}
        variant={"contained"}
        sx={{
          backgroundColor: "#aa2167",
          color: "#fff",
          height: "48px",
          "&:hover": {
            backgroundColor: "#aa2167",
            color: "#fff",
          },
        }}
      >
        Book appointment
      </Button>
    ) : (
      <Button
        fullWidth
        onClick={() => {
          if (isFamilyMember) {
            if (email && fullName && phone) {
              handleNextClick("pay");
            }
          } else {
            handleNextClick("pay");
          }
        }}
        variant={"contained"}
        sx={{
          backgroundColor: "#aa2167",
          color: "#fff",
          borderColor: "#aa2167",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#aa2167",
            color: "#fff",
          },
        }}
      >
        Continue
      </Button>
    )
  ) : (
    <Button
      fullWidth
      variant={"contained"}
      type={"submit"}
      sx={{
        backgroundColor: "#aa2167",
        borderColor: "#aa2167",
        color: "#fff",
        height: "48px",
        "&:hover": {
          backgroundColor: "#aa2167",
          color: "#fff",
        },
      }}
    >
      Continue
    </Button>
  )}
</Grid>

      </Grid>
    );

  return (
    <>     
        {loginOpen ? (
    <Box   
    sx={{  
      position: "fixed",  
      left: "0",
      right: "0",
      bottom: "0",
      top: 0,
      zIndex: "1111111111111!important",
    }}
    >
                      <div
                        
                      >
                        <Slide top>
                          <Login
                            loginOpen={loginOpen}
                            setOpenLogin={setOpenLogin}
                          />
                        </Slide>
                      </div>
    </Box>
                    ) : null}
      <Box>
        {loading ? <Waveloading fullSize /> : ""}    
        {active === "confirmation" ? (
          <Box    
            sx={{
              position: "fixed",
              left: "0",
              right: "0",  
              bottom: "0",  
              top: "0",
              zIndex: "1111!important",
              background: "#fff", 
              overflowY:'scroll',
            }}          
          >     
            <Confirmation        
              setConfirmation={setConfirmation}
              handleReset={handleReset}  
              confirmation={confirmation}
            />
          </Box>  
        ) : (       
          <Box>
            {active === "pay" && (
              <Box
                sx={{ width: "max-content", mb: "16px", cursor: "pointer" }}
                onClick={() => setActive("")} 
              >
                <BackArrow />
              </Box>        
            )}   
             <Box sx={{ mt: "24px" }}>
                    <Typography sx={{ my: "16px",}}> 
                    <span style={{ fontWeight: "bold",
                            mb: "24px", 
                            fontSize: "20px",
                            color: "black", }}>
                      Select Date
                      </span>  
                      {active > 1 && (
                        <Typography
                          sx={{
                            ...style,
                            fontSize: "14px",
                            textDecoration: "unset",
                            ml: "5px",
                          }}
                          as={"span"}
                          // onClick={() => handleEdit(1)}
                        >  
                          Edit
                        </Typography>
                      )}
                    </Typography>
                    {selectedTime?.start ? (
                      <Box
                        sx={{
                          background: "#f4ede3",
                          padding: " 20px", 
                          borderRadius: "4px",
                        }}
                      >
                        <Typography> <CalendarMonthIcon  sx={{fontSize:'18px'}}/> {selectedDate}</Typography>
                        {selectedTime?.start && (
                          <Typography sx={{ color: "#636363" }}>
                              {selectedTime?.start} – {selectedTime?.end}
                          </Typography>
                        )}  
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
            <Typography 
              variant={"h3"}
              sx={{
                fontWeight: "bold",            
                mb: "24px", 
                mt:3,  
                fontSize: "22px",
                color: "black", 
              }}    
            >  
              You’re nearly done.{" "}
              {active === "pay"
                ? `Proceed your $${finalTotal} Payment.`  
                : "Enter your details below."}
            </Typography>  
            <BookingTimer handleReset={handleReset} />
            {active === "" ? (  
              <>      
                {user?.id ? (      
                  <Box>
                    <Grid container sx={{ justifyContent: "space-between" }}>  
                      <Grid item xs={12} sx={{ mt: "16px" }}>
                        <FormGroup>     
                          <FormControlLabel           
                            control={  
                              <Checkbox
                                checked={isFamilyMember}
                                onChange={(e) =>   
                                  setIsFamilyMember(e.target?.checked)  
                                }
                              />   
                            }
                            label="For Family Member"   
                            />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} md={5.7} sx={{ mt: "16px" }}>    
                        <FormControl fullWidth>  
                          <TextField   
                            fullWidth
                            placeholder={"Full Name"}    
                            variant="outlined"          
                            sx={{
                              ...bookingInput,
                              "& .MuiInputBase-input": {
                                color: "#000", // Change the text color of the value
                              },
                            }}  
                            onChange={(e) =>          
                              isFamilyMember && setFullName(e.target?.value)      
                            }                       
                            disabled={!isFamilyMember}
                            value={isFamilyMember ? fullName : user?.name}    
                          />  
                        </FormControl> 
                      </Grid>  
                      <Grid item xs={12} md={5.7} sx={{ mt: "16px" }}>
                        <FormControl fullWidth>     
                          <Box
                            style={{ display: "flex", alignItems: "flex-end" }}
                          >
                            <TextField  
                              fullWidth      
                              disabled={!isFamilyMember}     
                              onChange={(e) => setPhone(e.target?.value)}
                              placeholder={"Phone No"}  
                              variant="outlined"         
                              sx={{
                                ...bookingInput,
                                "& .MuiInputBase-input": {
                                  color: "#000", // Change the text color of the value
                                },
                              }}  
                              value={isFamilyMember ? phone : user?.phone}
                            />
                          </Box>      
                        </FormControl> 
                        </Grid>    
                    </Grid>   
                    <Grid
                      container      
                      sx={{ justifyContent: "space-between", mt: "16px" }}
                    >        
                      <Grid item xs={12}>
                        <FormControl fullWidth> 
                          <TextField       
                            fullWidth  
                            disabled={!isFamilyMember}
                            onChange={(e) => setEmail(e.target?.value)}
                            variant="outlined"    
                            placeholder={"Email"}
                            sx={{
                              ...bookingInput,
                              "& .MuiInputBase-input": {
                                color: "#000", // Change the text color of the value
                              },
                            }}  
                            value={isFamilyMember ? email : user?.email}     
                          />  
                        </FormControl>       
                      </Grid>          
                    </Grid>   
                    {service_type === "booking"
                        ? <>
                         <Grid container sx={{ mt: "16px" }}>
                      <Grid item xs={12}>
                        <FormControl fullWidth>  
                          <TextField
                          //  disabled={!isFamilyMember}
                            fullWidth 
                            variant="outlined"
                            name={"description"}      
                            sx={bookingTextArea}
                            placeholder={"Appointment notes (optional)"}
                            value={description}    
                            onChange={(e) => setDescription(e.target.value)} 
                            minRows={4}           
                            multiline                      
                          />          
                        </FormControl> 
                      </Grid>
                    </Grid>     
                        </> 
                        :     
                        <>        
             <Grid container sx={{ mt: "16px" }}>   
                      <Grid item xs={12}>
                        <FormControl fullWidth>   
                          <TextField
                          //  disabled={!isFamilyMember}
                            fullWidth
                            variant="outlined"
                            name={"description"}    
                            sx={bookingTextArea}
                            placeholder={"Please Answer the following Questions: What is your area/s of concern, your goal and what treatment/s are you considering to achieve this goal? "}
                            value={description}     
                            onChange={(e) => setDescription(e.target.value)}
                            minRows={4}         
                            multiline                      
                          />  
                        </FormControl>
                      </Grid>
                    </Grid>
                        </> 
                      }
                   
                  </Box>
                ) : (  
                  <ContactForm
                    handleNext={() => {
                      handleNextClick("pay");
                    }}
                    setLoading={setLoading}
                    handleSubmitForm={handleSubmitForm}
                    isPaid={isPaid}
                    setRegisterData={setRegisterData}   
                    bookingNow={isPaid > 0 ? displayPaid : bookingNow}
                  />     
                )}                                    
                {isPaid > 0 && user?.id  
                  ? displayPaid()        
                  : user?.id
                  ? bookingNow()
                  : ""}   
              </>
            ) : active === "pay" ? (
              <Box>
                <Grid item xs={12} sx={{ mt: "16px", mb: "24px" }}>   
                  {checkWalletMethod() &&
                    user?.id &&   
                    getSubTotal().total + getFinalTax() >
                      parseFloat(user?.wallet_point) && ( 
                      <Typography sx={{ mb: "16px", color: "red" }}>  
                        Your wallet balance less then total amount. So also
                        choose another way to pay $  
                        {getSubTotal().total +   
                          getFinalTax() -             
                          parseFloat(user?.wallet_point)}   
                      </Typography>
                   )}
                  {provider?.provider_payment.map((item, index) => {
                    if (item?.status === 1) {

                    }    
                  })}  
                   {provider?.provider_payment.map((item, index) => {
    // Check if the item status is 1 (active)
    if (item?.status === 1) {
        if (user?.is_membered === 1 && priceShow === 0) {
            if (user?.id && item?.payment_method?.id === 1) {
                return (
                    <FormControlLabel
                        key={index}
                        sx={{ mr: "24px" }}
                        label={item?.payment_method?.name}  
                        control={
                            <Checkbox
                                checked={checkedStates[index] !== undefined ? checkedStates[index] : isChecked(item, selectedMethods)}
                                onChange={(e) => handleCheckbox(item, index, e)}
                            /> 
                        }
                    />
                );
            }
        } else {
            if (
                (user?.id && item?.payment_method?.id === 2 && user?.wallet_point && user?.is_membered === 1 && parseFloat(user?.wallet_point) > 0) ||
                (item?.payment_method?.id !== 1 && item?.payment_method?.id !== 2 && item?.payment_method?.id < 5)
            ) {
                return (
                    <FormControlLabel
                        key={index}
                        sx={{ mr: "24px" }}  
                        label={item?.payment_method?.name}
                        control={ 
                            <Checkbox
                                checked={checkedStates[index] !== undefined ? checkedStates[index] : isChecked(item, selectedMethods)}
                                onChange={(e) => handleCheckbox(item, index, e)}
                            />
                        }
                    />
                ); 
            }
        }
    }
})}


                </Grid>
                {selectedMethods?.length > 0 ? (
                  
                  <Grid item xs={12}>
                            {console.log('selectedMethods:', selectedMethods)}
        {console.log('Gateway methods count:', selectedMethods.filter(item => item?.payment_method?.is_gateway === 1).length)}
                    {selectedMethods.some(
                      (item) => item?.payment_method?.is_gateway === 1
                    ) ? (
                      <>
                      <PayByCard
                        refundPolicy={refundPolicy}
                        selectedMethods={selectedMethods}
                        provider={provider}
                        user={user}
                        setLoading={setLoading}       
                        registerData={registerData}
                        handleBookAppointment={handleBookAppointment}  
                      />
                      {console.log('Rendering PayByCard in booking')}
                      </>
                    ) : (  
                      <Box>  
                        {refundPolicy()} 
                        <Button
                          fullWidth
                          onClick={() => handleSubmitForm()}
                          variant={"contained"}
                          type={"submit"}    
                          sx={{ backgroundColor: '#E6DAD1',color:'#000', height: '48px', "&:hover": {
                            backgroundColor: "#E6DAD1",
                            color:'#000'
                          },}}
                        >
                          Book Appointment      
                        </Button>
                      </Box>
                    )} 
                  </Grid>
                ) : (
                  ""
                )}
              </Box>
            ) : (
              ""
            )}
          </Box>
        )}
      </Box> 
 

      <Box sx={{ display: { sm: "none", xs: "block" } }}>
            <Footer 
            SelectedLocation={SelectedLocation}
            zIndexContact="999999999999"
         />    
                </Box>
               
                <Modal  
                  open={openinvoice}
                  onClose={handleCloseInvoice}
                 style={{}}  
                 aria-labelledby="modal-modal-title"
                 aria-describedby="modal-modal-description"
        >

          {/* <Invoices appointmentInvoices={invoicedetails} /> */}
         
          {loading ? ( 
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
              <Waveloading />
            </Box>
          )  : 
            <>
             <Box sx={invoicePayment}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
 
              </Box>
              <Typography variant="h5" sx={{ fontWeight: '600' }}  >
                Promo Code 
              </Typography>

              <Box sx={{
                backgroundColor: '#F2F2F2',
                // border: '1px solid #F2F2F2',
                fontSize: '25px',
                display: 'inline-block',
                borderRadius: '5px',
                padding: '5px 15px'
              }}> 

                <CloseIcon
                    onClick={handleCloseInvoice}
                    style={{ cursor: "pointer", color: '#000', marginTop: '-5px' }}
                />
              </Box>

            </Box>
            <Grid item xl={12} md={12} sm={12} xs={12}>
              <FormControl fullWidth sx={{ marginTop: "20px" }}>
                {/* <InputLabel id="demo-simple-select-label"> promo code</InputLabel> */}
                <TextField
                sx={inputStyle}
                value={code}
                  label='Enter Promo Code here!'
                onChange={(e) => setCode(e.target.value)}     
                // error={errors.title}
                // helperText={errors.title ? "Title is required" : ""}
              />
              </FormControl>

            </Grid>
            <Box style={{display:"flex",justifyContent:"flex-end",marginTop:"10px"}}>
              <Button variant="contained" onClick={handleUpdatePlan}>Apply</Button>
            </Box>
          </Box>

            </> 
          } 

        </Modal>
        <Modal
          open={opencard}
          onClose={handleCloseCard}
          style={{}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          {/* <Invoices appointmentInvoices={invoicedetails} /> */}
         
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
              <Waveloading />
            </Box>
          ):
            <>
             <Box sx={invoicePayment}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
 
              </Box>
              <Typography variant="h5" sx={{ fontWeight: '600' }}  >
              Gift Card
              </Typography>

              <Box sx={{
                backgroundColor: '#F2F2F2',
                // border: '1px solid #F2F2F2',
                fontSize: '25px', 
                display: 'inline-block',
                borderRadius: '5px', 
                padding: '5px 15px'
              }}>
                <CloseIcon
                    onClick={handleCloseCard}
                    style={{ cursor: "pointer", color: '#000', marginTop: '-5px' }}
                />
              </Box>

            </Box>
            <Grid item xl={12} md={12} sm={12} xs={12}>
              <FormControl fullWidth sx={{ marginTop: "20px" }}>
                {/* <InputLabel id="demo-simple-select-label"> promo code</InputLabel> */}
                <TextField
                sx={inputStyle}
                value={code}
                  label='Enter Gift Card here!'
                onChange={(e) => setCode(e.target.value)}     
                // error={errors.title}  
                // helperText={errors.title ? "Title is required" : ""}     
              />
              </FormControl>
 
            </Grid>
            <Box style={{display:"flex",justifyContent:"flex-end",marginTop:"10  px"}}>
              <Button variant="contained" onClick={handlegiftcard}>Apply</Button>
            </Box>

          </Box>

            </>
          }

        </Modal>
    </>
  );
};

export default BookingContact;