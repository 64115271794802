import React, {useEffect, useState} from 'react';
import UserLayout from "../index";
import {Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography} from "@mui/material";
import { readUserNotification} from "../../../store/user/notification";
import moment from "moment/moment";
import Loader from "../../../components/loader/loader";
import Footer from '../../home/Footer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import { Drawer, useMediaQuery, useTheme} from "@mui/material";
import { Link } from "react-router-dom";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { useLocation, useNavigate } from "react-router";
import { removeUser } from "../../../store/auth";
import {useDispatch, useSelector} from "react-redux";
import { MdPowerSettingsNew,} from "react-icons/md";
import { confirmation } from "../../../services/utills";
// import {scrollCSS} from "../../App";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText"; 
import {CustomDrawer, drawerWidth} from "../.././../components/siderbar/adminSidebar";
import Pusher from "pusher-js";
import {getAllUserNotification} from "../../../store/user/notification";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { getAllMessages, ReadMessageuser } from "../../../store/user/Chat-modue";
import PersonIcon from '@mui/icons-material/Person';
import MailIcon from '@mui/icons-material/Mail';
import LockIcon from '@mui/icons-material/Lock';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import WalletIcon from '@mui/icons-material/Wallet';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import TodayIcon from '@mui/icons-material/Today';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';




  
const UserAccount = () => {
    const [loading,setLoading] = useState(false)

    const unreadCounter = useSelector(state => state?.userNotificationStore?.unreadCounter);
    const unreadCounterr = useSelector(state => state?.UserChatStore?.unreadCounter);
    const authStore = useSelector(state => state?.authStore);
    const { user } = authStore;
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

    // const user = useSelector((state) => state?.authStore?.user);
    const { pathname } = location;

    const sideData = [
        { name: "My Bookings", logo: <EventAvailableIcon style={{ color: "black", fontSize:'33px', position:'relative', top:'-10px' }} />, url: "/user/bookings" },
        {
            name: "My Treatment Plans",
            logo: <TodayIcon style={{ color: "black", fontSize:'33px', position:'relative', top:'-10px' }} />,
            url: "/user/plans/listing",
        },
        { name: "Milestones", logo: <AssignmentIndIcon style={{ color: "black", fontSize:'33px', position:'relative', top:'-10px' }} />, url: "/user/milestone" },
        { name: "My Wallet", logo: <WalletIcon style={{ color: "black", fontSize:'33px', position:'relative', top:'-10px' }} />, url: "/user/wallet" },
        {
            name: "Inbox",
            count: "inbox",
            logo: <MailIcon style={{ color: "black", fontSize:'33px', position:'relative', top:'-10px' }} />,
            url: "/user/inbox",
        },
        {
            name: "My Membership",
            logo: <AccountBalanceWalletIcon style={{ color: "black", fontSize:'33px', position:'relative', top:'-10px' }} />,
            url: "/user/membership",
        },
        {
            name: "My Profile",
            logo: <PersonIcon style={{ color: "black", fontSize:'33px', position:'relative', top:'-10px' }} />,
            url: "/user/my-account",
        },
        

        { name: "Change Password", logo: <LockIcon style={{ color: "black", fontSize:'33px', position:'relative', top:'-10px' }} />, url: "/user/password" },
        {
            name: "Default Spa Location",
            logo: <LocationOnIcon style={{ color: "black", fontSize:'33px', position:'relative', top:'-10px' }} />,
            url: "/user/default-spa-location",
        },
        // {
        //     name: "Notification / Alerts",
        //     count: "notification",
        //     logo: <NotificationsIcon style={{ color: "black" }} />,
        //     url: "/user/notification",
        // },

        { name: "Refer/Share", logo: <PeopleAltIcon style={{ color: "black" }} />, url: "/user/refer/share" },
        { name: "Support", logo: <HeadphonesIcon style={{ color: "black" }} />, url: "/user/support" },
    ];

    const handleNavigate = (url) => {
        navigate(url);
    };

    return (
        <>
   <UserLayout>
    <Typography sx={{ fontSize: "20px" }} color="initial">
        My Account 
    </Typography>
   <Box
        sx={{ 
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
        }}
    >
        <Typography sx={{ fontSize: "20px" }} color="initial">
           Welcome Back {user?.name}
        </Typography>
        {unreadCounter  && (
            <Box
                sx={{
                    position: "relative",
                    display: "inline-flex",
                    cursor: "pointer",
                }}
                onClick={() => handleNavigate("/user/notification")}
            >
                <NotificationsIcon sx={{ fontSize: "28px", color: "#000" }} />
                <Typography
                    sx={{
                        position: "absolute",
                        top: '-5px',
                        right: '-3px',
                        color: "#fff",
                        background: "#FF2D32",
                        borderRadius: "50%",
                        fontSize: "10px",
                        width: "16px",
                        height: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {unreadCounter}
                </Typography>
            </Box>
         )} 
    </Box>


    <Grid container spacing={2} sx={{ padding: "20px 20px 200px 20px" }}>
    {sideData.map((item, index) => (
        <Grid item xs={6} sm={4} md={2} key={index}>
            <Box
                onClick={() => handleNavigate(item.url)}
                sx={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: "8px",
                    padding: "16px",
                    textAlign: "center",
                    cursor: "pointer",
                    height: "130px", // Fixed height for uniformity
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center", // Ensures icon and text are close
                    "&:hover": {
                        backgroundColor: "#f5f5f5",
                        color: "#9d7658",
                    },
                }}
            >
                {/* Icon */}
                <Box
                    sx={{
                        fontSize: "2rem",
                        position: item.name === "My Bookings" ? "relative" : "initial",
                        top: item.name === "My Bookings" ? "-10px" : "0px",
                    }}
                >
                    {item.logo}
                </Box>

                {/* Text */}
                <Typography
                    variant="body2"
                    sx={{
                        mt: 1,
                        textAlign: "center",
                        whiteSpace: "normal",
                        lineHeight: "1.2",
                        maxWidth: "100%",
                        position: item.name === "My Bookings" ? "relative" : "initial",
                        top: item.name === "My Bookings" ? "-10px" : "0px",
                    }}
                >
                    {item.name}
                </Typography>
            </Box>
        </Grid>
    ))}
</Grid>



        </UserLayout>
        <Box style={{marginLeft:'calc(-50vw + 50%)', marginRight:'calc(-50vw + 50%)' }}>
            
        <Footer />
        </Box>
        </>
    )
}
export default UserAccount;