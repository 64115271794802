// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pb-header {
  width: 100%;
  display: flex;
}
.pb-header .pb-logo-box {
  margin: auto;
}
.pb-header .pb-logo-box img {
  vertical-align: middle;
  max-width: 200px;
}

.pb-hero-box {
  text-align: center;
  margin: 16px;
}
.pb-hero-box h3 {
  font-size: 30px;
  color: #27272E;
  margin: 0 0 8px 0;
}
.pb-hero-box p {
  margin: 0;
  font-size: 16px;
  color: #27272E;
}

@media (max-width: 400px) {
  .pb-header .pb-logo-box img {
    vertical-align: middle;
    max-width: 250px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/public-header/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;AACF;AACE;EACE,YAAA;AACJ;AAAI;EACE,sBAAA;EACA,gBAAA;AAEN;;AAEA;EACE,kBAAA;EACA,YAAA;AACF;AAAE;EACE,eAAA;EACA,cAAA;EACA,iBAAA;AAEJ;AACE;EACE,SAAA;EACA,eAAA;EACA,cAAA;AACJ;;AAGA;EAEE;IACE,sBAAA;IACA,gBAAA;EADF;AACF","sourcesContent":[".pb-header{\n  width: 100%;\n  display: flex;\n\n  .pb-logo-box{\n    margin: auto;\n    img{\n      vertical-align: middle;\n      max-width: 200px;\n    }\n  }\n}\n.pb-hero-box {\n  text-align: center;\n  margin: 16px;\n  h3 {\n    font-size: 30px;\n    color: #27272E;\n    margin: 0 0 8px 0;\n  }\n\n  p {\n    margin: 0;\n    font-size: 16px;\n    color: #27272E;\n  }\n}\n\n@media (max-width:400px) {\n\n  .pb-header .pb-logo-box img {\n    vertical-align: middle;\n    max-width: 250px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
