import { Autocomplete, Box, Button, CardMedia, Container, Grid, TextField, Typography, InputAdornment, Tab, Tabs, Slide, } from '@mui/material';
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import './style.css';
import { ServicesPlan, getAllClinic, getsubscriptionPlan, SubCategory, AllServices, Servicepromo} from '../../store/home';
import useStyles from './style';
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonIcon from "@mui/icons-material/Person";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import homeoutline from '../../assets/images/homeoutline.png'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import booking from '../../assets/images/Vector (Stroke).png'
import menu from '../../assets/images/homeIcon.png'
import plan from '../../assets/images/wallet2.png'
import profile from '../../assets/images/user.png'
import { TabContext, TabPanel } from '@mui/lab';
import Login from '../login';
import Waveloading from '../loading/Waveloading';
import Footer from './Footer';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import threeDlogo from "../../assets/logo/3dlogo-transparant.webp";
import fb from "../../assets/icon/fb-tranparant.png";
import insta from "../../assets/icon/instagram-transparant.webp";
import tiktok from "../../assets/icon/png-transparent-tiktok-logo.png";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import CustomModal from "../../components/modal/customModal";

const Promopage = () => {
    const classes = useStyles();
    const { Slug } = useParams();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);


    const location = useLocation();
    const selectedLocation = location?.state?.selectedClinic;
    const selectedlocation = location?.state?.selectedlocation;
    const Clinicname = selectedLocation?.name
    const Clinicid = selectedLocation?.id

    const [loginOpen, setOpenLogin] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedClinic, setSelectedClinic] = useState(selectedLocation || selectedlocation || null);
    const dispatch = useDispatch();
    const [activeButton, setActiveButton] = useState(localStorage.getItem('activeButton') || 'home');
    const id = localStorage.getItem('selectedLocationId')
    const backId = localStorage.getItem('selectedLocationId')

    const homeStore = useSelector(state => state?.homeStore)
    const { clinic, Promo,
        subcategory, ServiceData } = homeStore
    const [value, setValue] = useState(0);

    const handletabs = (event, newValue) => {
        setValue(newValue);
    };

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };
    // all clinic api=
    useEffect(() => {
        (async () => {
            await dispatch(getAllClinic())
        })()

    }, [])

    // level 3 api 
    useEffect(() => {
        setLoading(true);
     dispatch(Servicepromo(id)) 
     setLoading(false);
    }, []);


    //  options
    const clinicOptions = clinic.data && Array.isArray(clinic.data)
        ? clinic.data.map((clinic) => ({
            name: clinic?.name,
            id: clinic?.id,
        }))
        : [];

    // clinic handel 
    const handleClinicSelect = (event, newValue) => {
        localStorage.setItem('selectedLocationId', newValue?.id);
        setSelectedClinic(newValue);
        backhandler(newValue)
    };
    const handleHome = () => {
        navigate('/home');
    };
    const backhandler = (selectedClinic) => {
        navigate(`/home`, { state: { selectedLocation: selectedClinic } });
    };
    const backarrow = () => {
        navigate(`/home`, { state: { selectedLocation: selectedLocation } });
    };
    console.log("sub category", subcategory?.parent)

    const authStore = useSelector((state) => state?.authStore);

    const { user } = authStore;
    const subcategorySlug = subcategory?.slug;
    const parentSlugs = subcategory?.parent?.slug;
    const childSlugs = subcategory?.child?.map((child) => child.slug);
    const selectedChildSlug = childSlugs?.[value]
    console.log("treatment,group", subcategorySlug, parentSlugs)


    const fetchData = async () => {
        setLoading(true);

        let payload = {
            provider_id:  id,
        };

        if (selectedChildSlug && selectedChildSlug.length > 0) {
            payload.treatment = selectedChildSlug;
            payload.group = subcategory?.slug;
        } else {
            payload.treatment = subcategory?.slug;


            if (subcategory?.parent) {
                payload.group = subcategory?.parent?.slug;
            } else {
                payload.group = subcategory?.slug;
            }
        }

        const response = await dispatch(AllServices(payload));
        setLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [subcategory, value]);


    const handleCategoryClick = (url) => {
        // Navigate to the URL
        navigate(url, { state: { selectedLocation: selectedClinic, subcategorySlug } },);
    };
    console.log("subcategorySlug: in parent", subcategorySlug);

    const shouldRedirectToLogin = !user?.id || user?.role_id !== 3;
    const handleBoxClick = () => {
        setActiveButton('booking');
        if (shouldRedirectToLogin) {
            setOpenLogin(true)
        } else {

            navigate('/user/bookings');
        }
        setActiveButton('booking');
        localStorage.setItem('activeButton', 'booking');
    };
    const handleplan = () => {
        setActiveButton('member');
        if (shouldRedirectToLogin) {
            setOpenLogin(true)
        } else {

            navigate('/user/membership');
        }
        setActiveButton('member');
        localStorage.setItem('activeButton', 'member');
    };
    const handleprofile = () => {

        if (shouldRedirectToLogin) {
            setOpenLogin(true)

        } else {
            // Navigate to the booking page
            navigate('/user/user-account');

        }
        setActiveButton('profile');
        localStorage.setItem('activeButton', 'profile');
    };
  
    let capitalizedUserName = "";
    if (user?.name) {
        // Ensure user.name is not null or undefined
        capitalizedUserName = user.name.charAt(0).toUpperCase() + user.name.slice(1);
    }

    return (
        <>
            {loginOpen ? (
                <Box
                    sx={{
                        position: "fixed",
                        left: "0",
                        right: "0",
                        bottom: "0",
                        top: 0,
                        zIndex: "1111111111111!important",
                    }}
                >
                    <Slide top>
                        <Login loginOpen={loginOpen} setOpenLogin={setOpenLogin}  showFooter={true} />
                    </Slide>
                </Box>
            ) : (
                ""
            )}
            <CustomModal open={isOpen} setOpen={setIsOpen} background={"#f4ede3"}>
                <Autocomplete
                    className={classes.selectParent}
                    clearIcon={null}
                    disablePortal
                    id="combo-box-demo"
                    options={clinicOptions}
                    value={selectedClinic}
                    onChange={(event, newValue) => {
                        handleClinicSelect(event, newValue);
                        if (newValue) {
                            setSelectedClinic(newValue); // Update the selected clinic state
                            setIsOpen(false); // Close the modal
                        }
                    }}
                    sx={{ mt: 4 }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            sx={{ label: { mt: "-10px", zIndex: 0 } }}
                            label="Select Your Default Clinic"
                        />
                    )}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                        option.id === (value.id || id)
                    }
                />
            </CustomModal>

            <Box sx={{ backgroundColor: "#f4ede3" }}>
                <Box sx={{ p: 2 }}>
                <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #AA2167",
                pb: 1,
              }}
            >
              <Box
                component="img"
                src={threeDlogo} 
                alt="3D Logo"
                sx={{ width: "120px" }}
              />

              <Box>
                  {selectedClinic?.facebook && (
                      <a
                          href={selectedClinic?.facebook}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                              display: 'inline-block',
                              cursor: 'pointer',
                              width: '40px',
                              marginLeft: '10px',
                              position: 'relative',
                              top: '-2px',
                          }}
                      >
                          <img
                              src={fb}
                              width="100%"
                              style={{ borderRadius: '8px' }}
                              alt="Facebook"
                          />
                      </a>
                  )}

                  {selectedClinic?.instagram && (
                      <a
                          href={selectedClinic?.instagram}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                              display: 'inline-block',
                              cursor: 'pointer',
                              width: '40px',
                              marginLeft: '10px',
                              position: 'relative',
                              top: '-1px',
                          }}
                      >
                          <img
                              src={insta}
                              width="100%"
                              style={{ borderRadius: '8px' }}
                              alt="Instagram"
                          />
                      </a>
                  )}

                  {selectedClinic?.tiktok && (
                      <a
                          href={selectedClinic?.tiktok}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                              display: 'inline-block',
                              cursor: 'pointer',
                              width: '65px',
                          }}
                      >
                          <img
                              src={tiktok}
                              width="100%"
                              style={{ borderRadius: '8px' }}
                              alt="TikTok"
                          />
                      </a>
                  )}

              </Box>
        



            </Box>
                    <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 1,   flexWrap:'wrap',
                flexDirection:'column', }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <PhoneIcon sx={{ fontSize: "20px" }} />
                <Typography variant="subtitle1" color="initial">
                  <a
                      href={`tel:${selectedClinic?.phone || '+416 850 0231'}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {selectedClinic?.phone || '+416 850 0231'}
                  </a>
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <EmailIcon sx={{ fontSize: "20px" }} />
                <Typography variant="subtitle1" color="initial">
                  <a
                      href={`mailto:${selectedClinic?.email || 'care@3dlifestyle.ca'}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {selectedClinic?.email || 'care@3dlifestyle.ca'}
                  </a>
                </Typography>
              </Box>

            </Box>
                </Box>
            </Box>

            <Box sx={{ overflow: 'hidden', position: 'relative', display: { sm: "block", md: "none" } }}>
                <Container maxWidth="lg" sx={{ overflow: 'hidden', position: 'relative' }}>
                    <Box sx={{ display: { sm: "block", md: "none" },mt: '10px' }}>
                        {/*<Autocomplete*/}
                        {/*    className={classes.selectParent}*/}
                        {/*    disablePortal*/}
                        {/*    clearIcon={null} */}
                        {/*    id="combo-box-demo"*/}
                        {/*    options={clinicOptions}*/}
                        {/*    value={selectedClinic}*/}
                        {/*    onChange={handleClinicSelect}*/}
                        {/*    renderInput={(params) => (*/}
                        {/*        <TextField {...params} sx={{ label: { mt: '-10px', zIndex:0 } }} label="Select Your Clinic Location" />*/}
                        {/*    )}*/}
                        {/*    getOptionLabel={(option) => option.name}*/}
                        {/*    getOptionSelected={(option, value) => option.id === (value.id || id)}*/}
                        {/*/>*/}

                            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                                <Box >  
                                    <KeyboardBackspaceIcon onClick={backarrow} />
                                </Box>
                                {/*<PersonIcon onClick={() => setOpenLogin(true)} />*/}

                            </Box>

                        {user?.id && user?.role_id === 3 ? (

                            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4,
                                mt:2 }}>
                                {/* <Box onClick={backarrow} >
                                        <ArrowBackIcon />
                                    </Box> */}
                                <Typography
                                    variant="subtitle1"
                                    color="#0274be"
                                    sx={{ textDecoration:'underline' }}
                                    onClick={() => setIsOpen(true)}
                                >
                                    <LocationOnIcon sx={{ fontSize: '20px', marginTop: '-5px' }} />
                                    {selectedClinic ? selectedClinic.name : "Select Your Default Clinic"}
                                </Typography>
                                <Box sx={{ display: "flex", justifyContent: "space-between", }}>
                                    <Box sx={{ display: "flex", justifyContent: "end", }}>
                                        <AccountBalanceWalletIcon />
                                        <sup style={{ fontWeight: '600', left: '-19px', top: '-8px' }}>{user?.wallet_point || 0}</sup>
                                    </Box>
                                    <Box onClick={handleprofile} sx={{ backgroundColor: 'rgba(235, 213, 194, 1)', width: '30px', height: '30px', borderRadius: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography sx={{ color: '#000', fontSize: '16px', fontWeight: '500' }}>{capitalizedUserName.charAt(0)}</Typography>
                                    </Box>


                                </Box>
                            </Box>
                        ) : (
                            <Box sx={{ display: "flex", justifyContent: "space-between", my: 4, }}>
                                {/* <Box onClick={backarrow} >
                                        <ArrowBackIcon />
                                    </Box> */}
                                <Typography
                                    variant="subtitle1"
                                    color="#0274be"
                                    sx={{ textDecoration:'underline' }}
                                    onClick={() => setIsOpen(true)}
                                >
                                    <LocationOnIcon sx={{ fontSize: '20px', marginTop: '-5px' }} />
                                    {selectedClinic ? selectedClinic.name : "Select Your Default Clinic"}
                                </Typography>
                                <PersonIcon onClick={() => setOpenLogin(true)} />
                            </Box>
                        )}
                         {loading ? <Waveloading fullSize /> : ""}
<>     
                        <Box sx={{ mt: 3 }}>
                            <Typography variant="h6">Our Promo</Typography>


                        </Box>

                        <TextField
                            id="search"
                            type="search"
                            label="Search"
                            value={searchTerm}
                            onChange={handleChange}
                            className={classes.search}
                            sx={{ width: '100%', mt: 3, borderRadius: '20px', display: 'flex', alignItems: 'cernter', label: { mt: '-10px' } }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        {/* <Grid container spacing={2} sx={{ mt: 2 }}>

                            <Grid item xs={12} sm={6} key={plan.id}>
                                <Box sx={{ position: 'relative' }}>
                                    <CardMedia
                                        sx={{ borderRadius: '6px' }}
                                        component="img"
                                        alt="Descriptive alt text"
                                        height="110"
                                        image={subcategory?.file_path} 
                                    />
                                    <Typography
                                        variant="body2"
                                        sx={{ position: 'absolute', bottom: '15px', color: '#fff', left: '10px' }}
                                    >
                                        {subcategory?.title}
                                    </Typography>
                                </Box>
                            </Grid>

                        </Grid> */}
                       
 {Promo?.data && Promo?.data.length > 0 ? (
                                    <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                                       
                                        {Promo?.data.map((service) => (
                                            <>

                                                <Grid item xs={12} sm={6} key={service.id} >


                                                    <Box sx={{ borderRadius: '9px', p: '10px 15px', border: '1px solid #E0E0E0', background: '#fff', background: '#efe6e0', display: 'flex', flexDirection: 'column' }}>
                                                        <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700', my: 1 }}>{service?.name}</Typography>
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                            <Box sx={{ display: 'flex' }}>
                                                                <Box>
                                                                    <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4, textDecoration: 'line-through', textDecorationColor: 'black' }}>${service?.regular_price}</Typography>
                                                                    <Typography variant="subtitle2" sx={{ fontSize: '10px', }}>Regular Price</Typography>
                                                                </Box>
                                                                <Box>
                                                                    <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700' }}>${service?.member_price}</Typography>
                                                                    <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>Member Price</Typography>
                                                                </Box>
                                                            </Box >
                                                            <Box>
                                                                <Typography sx={{ fontFamily: 'helvetica-lt-std-roman', color: '#000', fontSize: '15px', fontWeight: '600' }}>{window.formatTimeDuration(service?.time_duration)}</Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1, alignItems: 'center' }}>
                                                            <Box >
                                                                <Button sx={{ 
                                                                        color: '#FFFFFF',borderRadius: '8px',padding: '10px 20px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167"}}
                                                                    onClick={() => handleCategoryClick(service?.site_url)}  >Book Now <TrendingFlatIcon /></Button>
                                                            </Box>
                                                            <Box>
                                                                <Typography sx={{
                                                                    fontSize: '15px', fontWeight: '300', color: 'rgba(28, 75, 137, 1)',
                                                                    textDecoration: 'underline', cursor: "pointer"
                                                                }} onClick={() => window.location.href = service?.link}  >More Info</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>


                                                </Grid>
                                            </>
                                        ))}
                                    </Grid>
                                ) : null}


                             
</>


                    </Box>
                </Container>
                <Footer />
            </Box>
        </>






    );
};

export default Promopage;