import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import {apiHeaders, apiHeadersImage} from "../../../lib/apiHeader";
import apis from "../../../lib/apis/apis";
import toast from "react-hot-toast";



export const getAllUserSupport = createAsyncThunk("getAllUserSupport", async (status, { dispatch }) => {
    try {
        const response = await axios.get(apis.get_all_support, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return response?.data?.data;
        } else {
            return [];
        }
    } catch (err) {
        return []
    }
})
export const storeUserSupport = createAsyncThunk("storeUserSupport", async (data, { dispatch }) => {
        try {
            const response = await axios.post(apis.store_support, data?.data,{
                headers: apiHeadersImage(),
            });

            if (response?.data?.success === "true") {
                await dispatch(getAllUserSupport())

                return response?.data;
            }else {
                return response?.data
            }
        } catch (err) {
            return err?.response?.data
        }
    }
);
export const replyUserSupport = createAsyncThunk("replyUserSupport", async (data, { dispatch }) => {
        try {
            const response = await axios.post(apis.reply_support, {...data},{
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                await dispatch(getAllUserSupport())
                toast.success("Your Reply have been added. We will reach you shortly",{duration:4000})
                return response?.data;
            }else {
                return response?.data
            }
        } catch (err) {
            return err?.response?.data
        }
    }
);
export const closeUserSupport = createAsyncThunk("closeUserSupport", async (data, { dispatch }) => {
        try {
            const response = await axios.post(apis.close_support, {...data},{
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                await dispatch(getAllUserSupport())
                return response?.data;
            }else {
                return response?.data
            }
        } catch (err) {
            return err?.response?.data
        }
    }
);

export const userSupportSlice = createSlice({
    name: 'userSupportSlice',
    initialState: {
        userSupport: [],
    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getAllUserSupport.fulfilled, (state, action) => {
            state.userSupport = action.payload || []
        })
    }
})


export default userSupportSlice.reducer