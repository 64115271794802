import React, { useState, useEffect } from 'react';
import {Box, Grid, useMediaQuery} from "@mui/material";
// import {ReactComponent as Loading} from "../../assets/icon/loading.svg";
// import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';

const extractSubdomain = () => {
    const hostname = window.location.hostname;
    const hostnameParts = hostname.split('.');
    return hostnameParts.length > 2 ? hostnameParts[0] : null;
};

const loaderCss= {
    width:'100%',
    height:'100%',
    top: "0",
    bottom: "0",
    left: "0",
    backdropFilter:'blur(2px)',
}
const defaultCss ={
    position:"absolute",
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    zIndex:'99999999999999999',
    background:'transparent',
}
const Loader = ({fullSize}) => {
    const [logo, setLogo] = useState(null);
    const isMobile = useMediaQuery("(max-width:768px)");
    const subdomain = extractSubdomain();
    useEffect(() => {
        if (subdomain === "zoey") {
            import('../../assets/images/logo.jpg').then(logo => {
                setLogo(logo.default);
            });
        } else {
            import('../../assets/logo/3d-logo.png').then(logo => {
                setLogo(logo.default);
            });
        }
    }, [subdomain]);
    return (
        <Box
            sx={fullSize ? {...defaultCss,...loaderCss }: {}}
        >
           <Grid container sx={{justifyContent:'center'}}>
               <Grid item  xs={isMobile ? 6 : fullSize ? 3 : 4}>
                   {/*<Fade   forever={true}>*/}
                   {/*    <img  src={logo} alt={"loading"}/>*/}
                   {/*    /!*<Loading/>*!/*/}
                   {/*</Fade>*/}
                   <Pulse  forever={true}>
                       <img src={logo} alt={"loading"} width={'100%'} height={"fit-content"}/>
                       {/*<Loading/>*/}
                   </Pulse>
               </Grid>
           </Grid>
        </Box>
    );
};

export default Loader;