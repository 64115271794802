import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { handleDelete } from "../../../services/utills";
import close from "../../../assets/icon/close-circle.png";
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import visa from "../../../assets/icon/visa.png";
import arrow from "../../../assets/icon/arrowForward.svg";
import { useDispatch } from "react-redux";
import CustomModal from "../../../components/modal/customModal";
import { defaultCard } from "../../../store/user/user-cards";
import Loader from "../../../components/loader/loader";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {scrollCSS} from "../../../App";
import Waveloading from "../../loading/Waveloading";

const CardsList = ({ allCardsList }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleCardClick = async (type, item, provider) => {
    const MySwal = withReactContent(Swal);
    const result = await MySwal.fire({
      title: "",
      text: "",
      showConfirmButton: false,
      html: (
        <Box sx={{ borderRadius: "15px" }}>
          <Typography
            variant={"h4"}
            sx={{ textAlign: "center", fontWeight: "600" }}
          >
            {type === "delete" ? "Delete Card" : "Default Card"}
          </Typography>
          <Typography variant={"h5"} sx={{ textAlign: "center", mt: 2 }}>
            {type === "delete"
              ? " Are you sure  you want to delete this card"
              : " Are you sure  you want to default this card"}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              mt: 3,
              mb: 4,
            }}
          >
            <Button
              sx={{
                backgroundColor: "#fff",
                width: "200px",
                height: "62px",
                border: "1px solid #666666",
                borderRadius: "10px",
                fontSize: "20px",
                color: "#666666",
                "&:hover": {
                  backgroundColor: "#fff",
                  border: "1px solid #666666",
                },
              }}
              onClick={() => MySwal.close()}
            >
              No
            </Button>
            <Button
              sx={{
                backgroundColor: "#fff",
                width: "200px",
                border: "1px solid #666666",
                color: "#666666",
                borderRadius: "10px",
                fontSize: "20px",
                "&:hover": {
                  backgroundColor: "#fff",
                  border: "1px solid #666666",
                },
              }}
              onClick={() => MySwal.clickConfirm()}
            >
              Yes
            </Button>
          </Box>
        </Box>
      ),
    });

    if (!!result?.isConfirmed) {
      setLoading(true);

      if (type === "delete") {
        let finalData = {
          token: item?.token || item?.id,
          provider_id: provider?.id,
        };
        await handleDelete(dispatch, "cardDelete", finalData);
      } else {
        let finalData = {
          card_id: item?.id,
          provider_id: provider?.id,
          user_id: provider?.id,
        };
        await dispatch(defaultCard(finalData));
      }
      setLoading(false);
    }
  };

  return (
    <Box
        sx={{maxHeight:'300px',pr:"10px",overflowY:'scroll',...scrollCSS}}
    >
     {loading ? <Waveloading fullSize /> :""}
      {allCardsList?.length > 0 &&
        allCardsList.map((item, itemIndex) => {
          const cardList = item?.cards?.id ? item?.cards?.cards : item?.cards;
          return (
            <Box key={itemIndex}>
                {cardList?.length > 0 && <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        p: "3px 0px",
                        mt: "16px",
                        backgroundColor: "#f4ede3",
                    }}
                >
                    <LocationOnOutlinedIcon/>
                    <Typography
                        sx={{
                            ml: "16px",
                            color: "#000",
                            textDecoration: "underline",
                        }}
                    >
                        {item?.provider?.name}
                    </Typography>
                </Box>}
              {cardList?.length > 0 &&
                cardList.map((card, index) => (
                  <Card
                    key={index}
                    sx={{
                      mt: 2,
                      p: "9px 16px",
                      border: "0.8px solid #C4C4C4",
                      borderRadius: "4px",
                      boxShadow: "none",
                      position: "relative",
                      overflow: "unset",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "-12px",
                        right: "-9px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleCardClick("delete", card, item?.provider)
                      }
                    >
                      <img src={close} alt="" />
                    </Box>

                    <Grid container>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexGrow: "1",
                        }}
                      >
                        {item?.payment_gateway !== "Square" && (
                          <FormControlLabel
                            sx={{
                              " @media (max-width:500px)": { marginRight: 0 },
                            }}
                            value="end"
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  if (e.target?.checked) {
                                    handleCardClick(
                                      "default",
                                      card,
                                      item?.provider
                                    );
                                  }
                                }}
                                checked={!!card?.default}
                              />
                            }
                            label=""
                            labelPlacement="end"
                          />
                        )}
                        <Box
                          sx={{
                            ml: 2,
                            mt: "2px",
                            width: "max-content",
                            " @media (max-width:500px)": { marginLeft: 0 },
                          }}
                        >
                          <img src={visa} alt="" />
                        </Box>
                        <Typography
                          variant="h5"
                          color="initial"
                          sx={{
                            ml: 3,
                            " @media (max-width:500px)": {
                              marginLeft: "5px",
                              textAlign: "center",
                              fontSize: "15px",
                            },
                          }}
                        >
                          {card?.cardType || card?.card_brand}-
                          {card?.last4 || card?.last_4}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={0.2}
                        xs={0.2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <img src={arrow} width="10px" alt="" />
                      </Grid>
                    </Grid>
                  </Card>
                ))}
            </Box>
          );
        })}
    </Box>
  );
};

export default CardsList;
