import React, {useEffect, useState} from 'react';
import {Box, Button, Link, Typography} from "@mui/material";
import {useLocation, useParams} from "react-router-dom";
import ConfigurationDrawer from '../../components/siderbar/ConfigurationDrawer';
import { MdCardMembership, MdFileCopy, MdLanguage, MdLocalHospital, MdOutlinePayment, MdSupervisedUserCircle, MdVpnKey } from 'react-icons/md';
import { AiOutlineMenu } from 'react-icons/ai';
import {getClinicByID} from "../../store/clinics";
import { useDispatch, useSelector } from 'react-redux';
import AdminDrawer from '../../components/siderbar/adminDrawer';



const ConfigurationLayout = ({children,clinic}) => {
    const [lastBreadcrumbs,setLastBreadcrumbs] = useState('')
    const {pathname} = useLocation()
    const params = useParams()
    const [clinicLoading, setClinicLoading] = useState(false)
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.authStore?.user);




    useEffect(()=>{ 
        const values = pathname.split("/");
        const lastValue = values[values.length - 1].trim();
        setLastBreadcrumbs(lastValue)
    },[pathname,params])

    const handleClick =()=>{

    }
    const navigateAndUpdate= (userId) => {
        setClinicLoading(true)
        dispatch(getClinicByID(userId))
        setClinicLoading(false)
    }

    return (
        <Box>
             {/*<Box sx={{pb: "30px"}}>*/}
                 {/*<Breadcrumbs*/}
                 {/*    separator={<NavigateNextIcon fontSize="small" />}*/}
                 {/*    aria-label="breadcrumb"*/}
                 {/*>*/}
                 {/*    {breadcrumbs}*/}
                 {/*</Breadcrumbs>*/}
             {/*</Box>*/}
            <Box
                sx={{ width: '100%', borderRadius: "7px", height: "100%"}}
            >
                <Box sx={{ display:'flex', justifyContent:'end' }}>

                {!user?.id || user?.role_id === 2 ?  (
                        <Box>
                            <Button
                                variant="text"
                     
                                
                            >
                                <ConfigurationDrawer clinicId={user?.id} />
                            </Button>
                        </Box>
                    ) : (
                        ""
                    )}


                               

                    
                {/* {!user?.id || user?.role_id === 1 ?  (
                        <Box>
                            <Button
                                variant="text"
                      
                                
                            >
                                <AdminDrawer/>
                            </Button>
                        </Box>
                    ) : (
                        ""
                    )} */}
                    



               
                </Box>
                {children}
            </Box>
 </Box>
    );
};

export default ConfigurationLayout;