import React, {Fragment, useEffect, useState} from 'react';
import {MenuItem, Box, Typography, IconButton, Avatar, Menu} from "@mui/material";
import {styled} from "@mui/material/styles";
import MuiAvatar from "@mui/material/Avatar";
import SearchIcon from '@mui/icons-material/Search';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import Badge from "@mui/material/Badge";
import ChatLog from "./chatLog";
import SendMsgForm from "./sendMsgForm";
import { getAllMessages, getUserDetails, ReadMessageuser } from '../../../store/user/Chat-modue';
import { useSelector } from 'react-redux';
// import { GetMessagess } from '../../../store/user/Chat-modue';

// import { GetMessages } from '../../store/crm/chat-module';
const ChatWrapperStartChat = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    borderRadius: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor:"#F1F5F9"
}))

const ChatContent = (props) => {
    const {
        store,
        hidden,
        SendMsgs,
        mdAbove,
        dispatch,
        statusObj,
        getInitials,
        sidebarWidth,
        userProfileRightOpen,
        handleLeftSidebarToggle,
        handleUserProfileRightSidebarToggle
    } = props
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

console.log("store 2,",store)  
    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }
     const authStore = useSelector(state => state?.authStore);
        const { user } = authStore;

    useEffect(() => {
        // dispatch(getUserDetails());
        const provider_id=store?.userProfile?.provider_id
        dispatch(getAllMessages(user?.default_provider_id));  
        //  dispatch(ReadMessageuser(user?.id));
       
    }, [dispatch]);



    const handleClose = () => { 
        setAnchorEl(null) 
    } 
    const handleStartConversation = () => { 
        if (!mdAbove) {   
            handleLeftSidebarToggle()
        }
    }
    console.log("selected chat",store)

    const renderContent = () => {
        if (store) {  
            const selectedChat = store.userProfile
            console.log("selected chat in messages",store?.UserMessages)    
            const getChat = store.Messages

            if (!selectedChat) {
                return (  
                    <ChatWrapperStartChat  
                        sx={{
                            ...(mdAbove ? { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } : {})
                        }}
                    > 
                        <MuiAvatar   
                            sx={{
                                mb: 5,    
                                pt: 8,
                                pb: 7,
                                px: 7.5,
                                width: 110,    
                                height: 110,       
                                backgroundColor: 'background.paper',
                                boxShadow: theme => theme.shadows[3]
                            }}   
                        >
                            <ChatBubbleOutlineOutlinedIcon sx={{ width: 50, height: 50, color: 'action.active' }} />
                        </MuiAvatar>   
                        <Box
                            onClick={handleStartConversation}
                            sx={{  
                                px: 6,
                                py: 2.25,
                                borderRadius: 5,
                                backgroundColor: 'background.paper',
                                boxShadow: theme => theme.shadows[3],
                                cursor: mdAbove ? 'default' : 'pointer'  
                            }}
                        >
                            <Typography sx={{ fontWeight: 600 }}>Start Conversation</Typography>
                        </Box>
                    </ChatWrapperStartChat>
                )     
            } else {  
                return (
                    <Box   
                        sx={{
                            flexGrow: 1,
                            width: '100%',
                            height: '100%',
                            backgroundColor: "#fff",  
                            paddingBottom:'80px'
                        }}  
                    >
                     <Box
                            sx={{
                                py: 1,
                                px: 5,
                                display: 'flex',
                                alignItems: 'center',   
                                justifyContent: 'space-between',
                                borderBottom: theme => `1px solid ${theme.palette.divider}`   
                            }}
                        >    
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {mdAbove ? null : (
                                    <IconButton onClick={handleLeftSidebarToggle} sx={{ mr: 2, display:'none'  }}>
                                        <MenuIcon />
                                    </IconButton> 
                                )} 
                                <Box
                                    onClick={handleUserProfileRightSidebarToggle}
                                    sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                >
                                    <Badge
                                        overlap='circular'
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }}   
                                        sx={{ mr: 4.5 }}
                                        badgeContent={
                                            <Box
                                                component='span'
                                                sx={{
                                                    width: 8,
                                                    height: 8,
                                                    borderRadius: '50%',
                                                    color: `${statusObj[selectedChat.status]}.main`,
                                                    boxShadow: theme => `0 0 0 2px ${theme.palette.background.paper}`,
                                                    backgroundColor: `${statusObj[selectedChat.status]}.main`
                                                }}
                                            />  
                                        }
                                    >
                                        {selectedChat.profile_image ? (
                                            <MuiAvatar
                                                src={selectedChat.profile_image}   
                                                alt={selectedChat.name}  
                                                sx={{ width: 40, height: 40 }}
                                            />
                                        ) : (
                                            <Avatar
                                                skin='light'
                                                color={'blue'}
                                                sx={{ width: 40, height: 40, fontSize: '1rem' }}
                                            >
                                                {getInitials(selectedChat.name)}
                                            </Avatar>
                                        )}
                                    </Badge> 
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography sx={{ color: 'text.secondary' }}>{selectedChat.name}</Typography>
                                        <Typography variant='body2' sx={{ color: 'text.disabled' }}>
                                            {selectedChat.email}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {/*{mdAbove ? (*/} 
                                {/*    <Fragment>*/}
                                {/*        <IconButton size='small' sx={{ color: 'text.secondary' }}>*/}
                                {/*            <PhoneOutlinedIcon />*/}
                                {/*        </IconButton>*/}
                                {/*        <IconButton size='small' sx={{ color: 'text.secondary' }}>*/}
                                {/*            <VideocamOutlinedIcon sx={{ fontSize: '1.5rem' }} />*/}
                                {/*        </IconButton>*/} 
                                {/*        <IconButton size='small' sx={{ color: 'text.secondary' }}>*/}
                                {/*            <SearchIcon />*/}
                                {/*        </IconButton>*/}
                                {/*    </Fragment>*/}
                                {/*) : null}*/}
                                <IconButton size='small' onClick={handleClick} sx={{ color: 'text.secondary', display:'none' }}>
                                    <MoreVertOutlinedIcon />
                                </IconButton>
                                {/* <Menu
                                    open={open}   
                                    sx={{ mt: 2 }}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{    
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                > */}
                                    {/* <MenuItem onClick={handleClose}>View Contact</MenuItem>
                                    <MenuItem onClick={handleClose}>Mute Notifications</MenuItem>
                                    <MenuItem onClick={handleClose}>Block Contact</MenuItem>
                                    <MenuItem onClick={handleClose}>Clear Chat</MenuItem>
                                    <MenuItem onClick={handleClose}>Report</MenuItem> */}
                                {/* </Menu> */}
                            </Box>
                        </Box>

                        {/* {selectedChat && store.userProfile ? ( */}
                        {/* userContact: store.userProfile */}
              
                        {/* {getChat ?( */}  
                            <ChatLog hidden={hidden} data={{chat:store.Messages, userContact: store.userProfile }} />
                         {/* ) : null} */}

                        <SendMsgForm store={store} dispatch={dispatch} SendMsgs={SendMsgs} />

                        {/*<UserProfileRight*/}
                        {/*    store={store}*/}
                        {/*    hidden={hidden}*/}
                        {/*    statusObj={statusObj}*/}
                        {/*    getInitials={getInitials}*/}
                        {/*    sidebarWidth={sidebarWidth}*/}
                        {/*    userProfileRightOpen={userProfileRightOpen}*/}
                        {/*    handleUserProfileRightSidebarToggle={handleUserProfileRightSidebarToggle}*/}
                        {/*/>*/}
                    </Box>
                )   
            }
        } else {
            return null
        }
    }

    return renderContent()
};

export default ChatContent;