import React, {useState} from 'react';
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {Box} from "@mui/material";
import Header from "../components/header/header";
import CRMSidebar from "../components/siderbar/crmSidebar";
import {useSelector} from "react-redux";
import {scrollCSS} from "../App";
import Loader from "../components/loader/loader";

const ProvidersRoutes = () => {
    const [sidebarOpen,setSidebarOpen] = useState(true)
    const [mobileOpen, setMobileOpen] = useState(false);

    const authStore = useSelector(state => state.authStore)
    const {user,logged,loading} = authStore
    const location =useLocation();

    if (loading){
        return  <Loader fullSize/>
    }

    if (logged && (user?.role_id === 2 || user?.role_id === 4)) {
        if (location?.pathname === '/') {
            return <Navigate to={"/crm/dashboard"}/>
        }
        return <Box sx={{display: 'flex'}}>
            <CRMSidebar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} sidebarOpen={sidebarOpen}/>
            <Box component="main" sx={{
                flexGrow: 1, overflowX: 'scroll', ...scrollCSS, "&::-webkit-scrollbar ": {
                    width: "5px",
                    display: 'none'
                },
            }}>
                <Header isCRM mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} admin type={'user'}
                        setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen}/>
                <Box sx={{
                    backgroundColor: '#EDF2F7',
                    height: "calc(100vh - 69px)",
                    padding: {sm:'20px', xs:'10px'},
                    overflowY: 'auto',
                    ...scrollCSS,
                   
                }}>
                    <Outlet/>
                </Box>
            </Box>
        </Box>
    }

    if (logged && user?.role_id === 1){
        return <Navigate to={"/admin/configuration/roles"} />
    }
    if (logged && user?.role_id === 3){
        console.log("provider route");
        return <Navigate to={"/user/my-account"} />
    }
    if (logged && user?.role_id === 4){
        return <Navigate to={"/crm/dashboard"} />
    }

    return <Navigate to={"/login"} />
};

export default ProvidersRoutes;