import React, {useEffect, useState} from 'react';
import UserLayout from "../index";
import {Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography} from "@mui/material";
import Pusher from 'pusher-js';
import {getAllUserNotification, readUserNotification} from "../../../store/user/notification";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";
import Loader from "../../../components/loader/loader";
import Footer from '../../home/Footer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Waveloading from '../../loading/Waveloading';


const NotificationAlerts = () => {
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch()
    const notifications = useSelector(state => state?.userNotificationStore?.notifications)


    
    const handleRead = async (id) => {
        setLoading(true)
        let res =  await dispatch(readUserNotification(id))
        setLoading(false)
    }
    useEffect(()=>{
        (async ()=>{
            await dispatch(getAllUserNotification())
        })()
    },[])
      

    return (
        <>
        <UserLayout>
            <Grid container>
            {loading ? <Waveloading fullSize /> :""}
                {notifications?.length > 0  && <Grid item xs={12} sx={{textAlign: "end", mb: '7px'}}>
                    <Typography onClick={()=>handleRead('all')} sx={{color: '#2F80ED', textDecoration: 'underline', cursor: 'pointer'}}>
                        Mark all as read
                    </Typography>
                </Grid>}
                {notifications?.length > 0 ? notifications.map((item ,index)=>{
                        let formattedDate;
                        if (moment().isSame(moment(item?.created_at), 'day')) {
                            formattedDate = moment(item?.created_at).fromNow();
                        } else {
                            formattedDate = moment(item?.created_at).format('DD/MM/YYYY');
                        }
                    return(
                       

                        <Accordion sx={{ width: '100%', marginBottom: '16px', boxShadow: "0px", borderRadius: "6px" }}>
                          <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              display: 'flex',
                              alignItems: "center",
                              background: item?.is_read === 1 ? '#F3FBFF' : '#D8F3FF',
                              padding: "20px",
                              marginTop: '8px',
                              borderRadius: "4px",
                              cursor: item?.is_read === 0 ? 'pointer' : 'default',
                            }}
                            onClick={() => item?.is_read === 0 && handleRead(item?.id)}
                          >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                              <Typography 
                                sx={{
                                  fontSize: { xs: '16px', md: '22px' },
                                  fontWeight: item?.is_read === 1 ? "lighter" : 'bold',
                                  fontFamily: item?.is_read === 1 ? 'helvetica-lt-std-light' : 'helvetica-lt-std-bold',
                                }}> 
                                {item?.title}
                              </Typography>
                              
                              <Typography
                                sx={{
                                  fontWeight: item?.is_read === 1 ? "lighter" : 'bold',
                                  fontFamily: item?.is_read === 1 ? 'helvetica-lt-std-light' : 'helvetica-lt-std-bold',
                                  textAlign: 'right', // Align text to the right
                                }}
                              >
                                {formattedDate}
                              </Typography>
                            </Box>
                          </AccordionSummary>  
                        
                          <AccordionDetails>
                            <Typography>
                              {item?.body}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        
                      
                )}) :
                    <Grid
                        item
                        xs={12}
                        sx={{
                            marginTop:'100px',
                        }}
                    >
                        <Typography sx={{textAlign:'center'}}>
                            Empty Notification
                        </Typography>
                        
                    </Grid>
                }
            </Grid>
            
        </UserLayout>
        <Box style={{marginLeft:'calc(-50vw + 50%)', marginRight:'calc(-50vw + 50%)' }}>
            
        <Footer />
        </Box>
        </>
    );
};

export default NotificationAlerts;  
// <Grid
//     key={index}
//     item   
//     xs={12}
//     sx={{
//         display:'flex',
//         background:item?.is_read  === 1 ? '#F3FBFF' :'#D8F3FF',
//         padding:"20px ",
//         marginTop:'8px',
//         justifyContent:'space-between',
//         borderRadius:"4px",
//         cursor: item?.is_read  === 0 ? 'pointer':'default',
//     }}
//     onClick={()=>item?.is_read  === 0 && handleRead(item?.id)}
// >    
//     <Typography
//         sx={{
//             fontSize: {xs: '16px', md: '22px'},
//             fontWeight:item?.is_read === 1  ? "lighter" :'bold',
//             fontFamily:item?.is_read === 1 ? 'helvetica-lt-std-light' : 'helvetica-lt-std-bold',
//         }}>   
//         {item?.title}
//     </Typography>

// </Grid>