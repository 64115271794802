import React, {useEffect, useState} from 'react';

import Box from "@mui/material/Box";
import CrmLayout from "../../../layout/CRMLayout";
import {
  Avatar,
  Button,
  Checkbox,
  FormControl,
  Grid,

  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import Hidden from '@mui/material/Hidden';
import {inputStyle} from "../../../components/form/formInput";
import Autocomplete from "@mui/material/Autocomplete";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {useNavigate} from "react-router";
import {generateYears, monthsArray} from "../../../services/utills";
import CrmMonthView from "../../../components/crm-calender/CRMMonthView";
import CalenderDrawer from "./calenderDrawer";
import CrmDayView from "../../../components/crm-calender/CRMDayView";
import {useDispatch, useSelector} from "react-redux";
import {getProviderStaff} from "../../../store/crm/staff";
import Loader from "../../../components/loader/loader";
import {useLocation} from "react-router-dom";
import CustomModal from "../../../components/modal/customModal";
import CalenderAppointmentDetail from "./calenderAppointmentDetail";
import {getAllCalenderBookings, updateCrmCalenderFilter} from "../../../store/crm/calender";
import Waveloading from '../../loading/Waveloading';
import CrmPage from '../../../components/mobileview/CrmPage';
import MonthView from '../../../components/mobileview/MonthView';
import moment from 'moment-timezone';
export const arrowStyle = {borderRadius:'unset',background:'#fff',height:'44px',width:"46px"}

export const filterArray =[
  'Day',
  'Month',

 
]
export const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
let timeZone = localStorage.getItem("timeZone");

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const legends =[
  {color: '#56CCF2',title:'Unconfirmed Pending'},
  {color: '#323681',title:'Confirmed Pending'},
  {color: '#F2C94C',title:'Checked-In'},
  {color: '#219653',title:'Completed'},
  // {color: '#EB5757',title:'Cancel'},
  {color: '#9A9797',title:'No Show'},
]
const CRMCalender = () => {
  const [startDate, setStartDate] = useState(moment.tz(new Date(), timeZone).toDate());
  const [endDate, setEndDate] =  useState(moment.tz(new Date(), timeZone).toDate());
  const [openDrawer, setOpenDrawer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openAppointmentDetail, setOpenAppointmentDetail] = useState(null);


  const navigate = useNavigate()
  const {pathname} = useLocation()
  const dispatch = useDispatch()

  const crmStaffStore = useSelector(state => state?.crmStaffStore)
  const crmCalenderStore = useSelector(state => state?.crmCalenderStore)
  const {crmStaffs = []} = crmStaffStore
  const {calenderType,calenderBookings=[],selectedStaff,isCancel} = crmCalenderStore
  const {month_counts={},bookings=[]} = calenderBookings
  const user = useSelector(state => state?.authStore?.user)


  useEffect(() => {
    (async () => {
      setLoading(true)
      await dispatch(getProviderStaff())
      await fetchCalenderBookings(startDate)
      setLoading(false)
    })()
  }, [])

/*    // Additional useEffect for handling automatic staff selection based on role_id
    useEffect(() => {
        const autoSelectStaff = async () => {
            if (user?.role_id === 4) {
                // Directly use the logic to update the selectedStaff and fetch bookings
                const selectedStaffId = user.id; // Assuming this is how you get the corresponding staff ID
                await dispatch(updateCrmCalenderFilter({ selectedStaff: selectedStaffId }));
                await fetchCalenderBookings(startDate);
            }
        };

        autoSelectStaff();
    }, [user, dispatch, startDate]);*/
  const fetchCalenderBookings = async (date)=>{
    // setLoading(true)
    await dispatch(getAllCalenderBookings({date:date || startDate}))
    // setLoading(false)
  }
  const handleStaffChange = async (event, newValue) => {
    await dispatch(updateCrmCalenderFilter({selectedStaff:newValue})) 
    fetchCalenderBookings(startDate) 
  };
  const reloadCalendar = async () => {
    // Logic to reload calendar data
    await fetchCalenderBookings(startDate);
  };
 
  const handleDateChange = async (direction) => {
    let newStartDate = new Date(startDate);
    const increment = direction === "left" ? -1 : 1;
  
    if (calenderType === "Month" && newStartDate.getMonth() === 0 && direction === "right") {
      newStartDate = new Date(newStartDate.getFullYear(), 1, 1);
    } else {
    
      switch (calenderType) {
        case "Day":
          newStartDate.setDate(newStartDate.getDate() + increment);
          break;
        case "Week":
          newStartDate.setDate(newStartDate.getDate() + increment * 7);
          break;
        case "Month":
          newStartDate.setMonth(newStartDate.getMonth() + increment);
        
          const lastDayOfNewMonth = new Date(newStartDate.getFullYear(), newStartDate.getMonth() + 1, 0).getDate();
          if (newStartDate.getDate() > lastDayOfNewMonth) {
            newStartDate.setDate(lastDayOfNewMonth);
          }
          break;
        default:
      
          break;
      }
    }
  
   
    let newEndDate = new Date(newStartDate.getFullYear(), newStartDate.getMonth() + 1, 0);
  
    setStartDate(newStartDate);  
    setEndDate(newEndDate);
  
    await fetchCalenderBookings(newStartDate);
  };
  
  
  const handleAutocomplete = async (event, value) => {   
    await dispatch(updateCrmCalenderFilter({calenderType:value}))
    if (value === 'Week') {
      const end = new Date(startDate);
      end.setDate(end.getDate() + 6);
      setEndDate(end);
    } else if (value === 'Month') {
      const end = new Date(startDate);
      end.setMonth(end.getMonth() + 1);
      setEndDate(end);
    } else {
      setEndDate(startDate)
    }
    await fetchCalenderBookings(startDate)
  }


  const formatDate = () => {
    if(calenderType === 'Month') {
      return startDate.toLocaleDateString("en-US", {month: "short", year: "numeric"});
    }
    else if (calenderType === 'Week') {
      return startDate.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric"
      }) + " - " + endDate.toLocaleDateString("en-US", {month: "short", day: "numeric"});
    } else if (calenderType === 'Day') {
      return weekday[startDate?.getDay()] + " - " + startDate.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric"
      });
    }
  }
  
  const handleRedirect = (url) => {
    navigate(url)
  }

  const handleDateClick = async (date) => {
    let newStartDate = new Date(date);
    setStartDate(newStartDate)
    await dispatch(updateCrmCalenderFilter({calenderType: "Day"}))
    await fetchCalenderBookings(date)
  }     


  const handleCheckCancel = async () => {
    await dispatch(updateCrmCalenderFilter({isCancel:!isCancel}))
    await fetchCalenderBookings(startDate)
  }
  return (
      <CrmLayout>
        {loading ? <Waveloading fullSize/> :""}
        <Box sx={{
          background: '#F1F5F9',
          borderRadius: '7px',
          p: '16px',
          display: 'flex',   
          flexDirection:{xs:'column',sm:'row'},  
          justifyContent:'space-between',
          flexWrap: 'wrap',
          alignItems: {xs:'start',sm:'space-between'},

        }}>
          <Typography sx={{mr: '24px',mt:'6.1px'}}>
            Legends:
          </Typography>
          {legends.map((item, index) => (
              <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mr: '16px',
                    mt:'6.1px'
                  }}
                  key={index}
              >
                <Box
                    sx={{
                      background: item?.color,
                      p: '6px',    
                      borderRadius: '2px',
                      mr: '7px',
                    }}> </Box>
                <Typography>
                  {item?.title}
                </Typography>
              </Box>   
          ))}
          <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap:'wrap',
                mr: '16px',
                cursor:'pointer',
              }}
              onClick={handleCheckCancel}
          >
            <Box sx={{background: "#EB5757", p: '6px', borderRadius: '2px', mr: '7px'}}> </Box>
            <Typography>Cancel/Void</Typography>
            <Checkbox onClick={handleCheckCancel} checked={!!isCancel}  size="small" />
          </Box>  
        </Box>  
        <Grid   
            container
            sx={{
              my: '24px',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap:'wrap',  
              flexDirection:{sm:'unset', xs:'column-reverse'}
            }}
        >
          <Grid
              item
              sx={{
                display: 'flex',
                flexWrap:'wrap'
              }}
          >
            <Autocomplete
                sx={{width: '110px'}}
                options={filterArray}
                getOptionLabel={(option) => option}
                value={calenderType}
                disableClearable
                onChange={handleAutocomplete}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        sx={inputStyle}
                    />  
                )}
   /> 
            <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ml: '16px',
                }}
            >
              <IconButton
                  sx={{
                    ...arrowStyle,
                    borderBottomLeftRadius: '7px',
                    borderTopLeftRadius: '7px',
                  }}
                  onClick={() => handleDateChange("left")}
              >
                <KeyboardArrowLeftIcon sx={{color: '#4F4F4F'}}/>
              </IconButton>
              <IconButton
                  sx={{
                    ...arrowStyle,
                    borderBottomRightRadius: '7px',
                    borderTopRightRadius: '7px',
                  }}
                  onClick={() => handleDateChange("right")}
              >
                <KeyboardArrowRightIcon sx={{color: '#4F4F4F'}}/>
              </IconButton>  
            </Box>
            <Box>
              <Typography sx={{  
                p: '10px 20px',
                width: "max-content",
              }}>
                {formatDate()}  
              </Typography> 
            </Box>
          </Grid>
          <Grid sx={{ width:{sm:'unset', xs:'100%', textAlign:'right'} }}>
            <Button
                sx={{
                  background: '#19A2FF',
                  px: '24px',
                  border: '1px solid #19A2FF',
                  color: '#fff',
                  marginRight:2,
                  marginBottom:{sm:0, xs:2},
                  "&:hover": {
                    background: '#fff',
                    color: '#19A2FF',
                  }
                }}
                onClick={() => {
                  localStorage.setItem('url',pathname)
                  handleRedirect("/crm/add-appointment")
                }}
            >
              Add Appointment
            </Button>
          </Grid>
        </Grid>
        <Grid container>   
          <Grid    
              item
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
          >
            <Typography
                sx={{mr: '3rem'}}   
            >   
              Aesthetician:
            </Typography>
            <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  width: 'max-content',  
                  maxWidth: '100%',
                  overflowX: 'auto',
                }}
            >
              <CrmPage crmStaffs={crmStaffs} paddingselect={"8.4px 14px"} handleStaffChange={handleStaffChange}/>

              <Hidden smDown implementation="css">
                <Tabs 
                    value={selectedStaff}
                    onChange={handleStaffChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    sx={{
                      '.MuiTabs-indicator': {
                        background: '#000000',
                        gap: '12px',
                      },
                      '.Mui-selected': {
                        color: '#000!important',
                        fontWeight: '600',
                      },
                    }}
                >
                  <Tab
                      label="All"
                      value="all"
                      sx={{
                        fontSize: '18px',
                        color: '#000',
                        textTransform: 'capitalize',
                        fontFamily: selectedStaff === 'all' ? 'helvetica-lt-std-bold' : 'helvetica-lt-std-roman',
                      }}
                  />
                  {crmStaffs?.map((item, index) => (
                      <Tab
                          icon={<Avatar src={item?.profile_image} variant="rounded" sx={{ height: '54px', width: '54px' }} />}
                          iconPosition="start"
                          label={item?.name}
                          value={item?.id}
                          key={index}
                          sx={{
                            fontSize: '18px',
                            color: '#000',
                            textTransform: 'capitalize',
                            fontFamily: selectedStaff === item?.id ? 'helvetica-lt-std-bold' : 'helvetica-lt-std-roman',
                          }}
                      />
                  ))}
                </Tabs>
              </Hidden>
            </Box>
          </Grid>
        </Grid>
        <Grid
            container
            sx={{
              my: "24px",
            }}
        >
          <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap:"wrap",
                gap:{xs:'12px',sm:'8px', xl:'0px'}
              }}
          >
            <Box
                sx={{
                  display:'flex',
                  alignItems:'center',

                }}
            >
              <Typography sx={{mr:'16px'}}>
                YEAR
              </Typography>
              <FormControl sx={{minWidth: 90}}>
                <Select
                    size={"small"}
                    value={startDate?.getFullYear() || 2020}
                    MenuProps={MenuProps}
                    onChange={(e)=> {
                      const end = new Date(startDate);
                      end.setFullYear(e?.target?.value);
                      setStartDate(end);
                    }}
                    sx={{
                      background:'#19A2FF',
                      color:'#fff',
                      ".MuiOutlinedInput-notchedOutline": {
                        borderWidth: "0!important"
                      },
                      "svg": {
                        color:'#fff',
                        fontSize:'34px',
                      }
                    }}
                >
                  <MenuItem value={""}>Select</MenuItem>
                  {generateYears(20)?.length > 0 &&generateYears(20)?.map((item,index)=>(
                      <MenuItem value={item} key={index}>{item}</MenuItem>
                  ))
                  }
                </Select>
              </FormControl>
              <Box sx={{ ml:{sm:'20px', xs:'10px'} }}>
                <MonthView
                paddingselect={"8.4px 14px"}
                    setStartDate={setStartDate}
                    fetchCalenderBookings={fetchCalenderBookings}
                />
              </Box>

            </Box>

            <Hidden smDown implementation="css">
              <div style={{ display: 'flex', gap: '10px' ,flexWrap:"wrap"}}>
                {monthsArray.map((item,index) => (  
                    <Typography
                        key={index}
                        sx={{
                          // startDate.getMonth()
                          width: 'max-content',
                          minWidth:'65px',
                          height: '38px',
                          px:'10px',
                          background: startDate?.getMonth() === item?.key ? "#1DA3FF" :"#F1F5F9",
                          borderRadius: '6.2px',
                          color: startDate?.getMonth() === item?.key ? "#fff" :'#000000',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor:'pointer'
                        }}
                        onClick={async ()=>{
                          const end = new Date(startDate);
                          end.setMonth(item?.key);
                          setStartDate(end);
                          await dispatch(updateCrmCalenderFilter({calenderType:"Month"}))
                          await fetchCalenderBookings(end)

                        }}
                    >
                      {item?.shortName} {`(${month_counts[item?.index] || 0} )`}
                    </Typography>  
                ))}
              </div>
            </Hidden>
          </Grid>
        </Grid>  
        {calenderType === 'Month' &&
            <Grid item xs={12}>     
              <CrmMonthView
                  monthViewData={bookings}
                  startDate={startDate}  
                  setOpenDrawer={setOpenDrawer}
                  handleDateClick={handleDateClick}
              />  
            </Grid>
        }
        {calenderType === 'Day' &&
            <Grid item xs={12}>
              <CrmDayView  
                  dayViewData={bookings}
                  setOpenAppointmentDetail={setOpenAppointmentDetail}
                  startDate={startDate}
                  minStartTime={calenderBookings?.min_start_time}
                  maxEndTime={calenderBookings?.max_end_time}
              />
            </Grid>
        }

        <CalenderDrawer
            setOpenAppointmentDetail={setOpenAppointmentDetail}
            appointmentLists={openDrawer}
            setOpen={()=>{
              setOpenDrawer(null)
            }}
        />
        <CustomModal
            customWidth={"1000px"}
            open={openAppointmentDetail !== null}
            setOpen={() => setOpenAppointmentDetail(null)}
        >
          <CalenderAppointmentDetail
              fetchCalenderBookings={fetchCalenderBookings}
              appointment_id={openAppointmentDetail}
              onRescheduleSuccess={reloadCalendar}
          />
        </CustomModal>
      </CrmLayout>
  );
}

export default CRMCalender;