import React from 'react';
import {Box, Divider, Grid, Input, InputAdornment, Typography} from "@mui/material";
import logo from "../../../../assets/logo/3d-logo.png";

import {invoicesClasses} from "./styles";
import moment from "moment";
import {inputStyle} from "../../../../components/form/formInput";
import {bookingwrapper} from "../../../crm/pos/invoices/InvoicePayment";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from 'react-router-dom';

const UserInvoices = ({invoiceDetails}) => {
    const {wrapper, logoWrap, heading, subHeading, contentWrapper, content, subFooter, tableWrapper} = invoicesClasses
    const {customer, provider,invoices} = invoiceDetails
    let allInvoices = [];

    if (invoices.length > 0) {
        allInvoices = invoices[0].invoices_methods || [];   
    }
    return (
        <Box sx={wrapper}> 
            <Grid container>
                <Grid item lg={12} sx={logoWrap}>   
                <div style={{display:"flex",alignItems:"center"}}>
                <Link to="/user/bookings">
        <ArrowBackIosIcon  style={{color:"black"}}/>
      </Link>
            <Typography sx={heading} style={{marginLeft:"10px"}}>INVOICE</Typography>
                </div>
                   
                    <img src={logo} alt={"loading"} width={'20%'}/>
                </Grid>
                </Grid>
                <Grid container >
                {/* <Grid item lg={12} md={12} sm={12} xs={12} sx={{ ...contentWrapper, flexWrap:'wrap' }}> */}
                    <Grid item xl={4} lg={4} md={6} xs={12}>
                        <Typography sx={{...subHeading, color: '#04B4FF'}}>To</Typography>
                        <Typography sx={subHeading}>{customer?.name}</Typography>
                        <Typography sx={content}>{customer?.phone}</Typography>
                        <Typography sx={content}>{customer?.email}</Typography>
                        <Typography sx={content}>{customer?.address?.address || ""}</Typography>
                        <Typography sx={content}>{customer?.address?.postal_code || ""}</Typography>
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} xs={12}>
                        <Typography sx={{...subHeading, color: '#04B4FF'}}>From:</Typography>
                        <Typography sx={subHeading}>{provider?.name}</Typography>
                        <Typography sx={content}>{provider?.phone || " Not Set"}</Typography>
                        <Typography sx={content}>{provider?.address?.address || " Not Set"}</Typography>    
                        <Typography sx={content}>{provider?.address?.postal_code || "Not Set"}</Typography>
                    </Grid>
                    <Grid item xl={3} lg={4} md={6} xs={12}>
                        <Typography sx={{...subHeading, color: '#04B4FF'}}>Info:</Typography>
                        <Typography sx={subHeading}>Amount: ${invoiceDetails?.grand_total}</Typography>
                        <Typography
                            sx={content}>Date: {moment(invoiceDetails?.date).format("MM/DD/YYYY")}</Typography>
                        <Typography sx={{...content,}}>  
                            {invoices?.length > 1 ?"Invoices ID":"Invoice ID"}:
                            {invoices?.length> 0 && invoices?.map((item,index)=>(
                                <Typography sx={content} key={index} as={'span'}>
                                    {item?.id}{" "}
                                </Typography>
                            ))}
                        </Typography>
                        <Box style={{display:"flex"}}>
                        <Typography sx={content}>Status:</Typography>
    <Typography  
    sx={{
        background: invoiceDetails?.payment_status === 'Paid' || invoiceDetails?.payment_status === 'Pre Paid' ? "#1C7E46" : '#C64949',
        // width:"30%",
        ml: '1px',
        color: "#fff",  
        padding: '2px 22px',
        borderRadius: "22px"  
      }}> {invoiceDetails?.payment_status}</Typography>
                        </Box>
                   
                    </Grid>
                </Grid>
                {/* </Grid> */}

            {/* </Grid> */}


            <Grid container>
                <Grid item lg={12} sm={12} xs={12} sx={{...tableWrapper,marginTop: '40px',mb:"13px"}}>
                    <Grid item lg={5} sm={7} xs={7}>
                        <Typography  sx={{...subHeading, color: '#04B4FF'}}>Service</Typography>
                    </Grid>
                    <Grid item lg={3}></Grid>
                    <Grid item lg={2} sm={2} xs={2}>
                        <Typography  sx={{...subHeading, color: '#04B4FF'}}>Qty</Typography>
                    </Grid>
                    <Grid item lg={2} sm={3} xs={3} sx={{ textAlign:{sm:'unset', xs:'end'} }}>
                        <Typography  sx={{...subHeading, color: '#04B4FF',  textAlign:{md:'left', sm:'center', xs:'right'}}}>Amount</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Box>
                <Divider sx={{borderColor: "#000000", width: "100%", borderBottomWidth:'2px' }}/>
            </Box>

            <Grid container sx={{my:'13px'}}>
            {invoiceDetails?.services?.map((item,index)=>(
                <Box key={index} sx={{ width:{sm:'100%', xs:'100%'} }}>
                        <Grid item lg={12} sm={12} xs={12} sx={tableWrapper}>
                            <Grid item lg={5} sm={7} xs={7}>  
                                <Typography sx={content}>{item?.name}</Typography>
                            </Grid>
                            <Grid item lg={3}> </Grid>  
                            <Grid item lg={2} sm={2} xs={2}>
                                <Typography sx={content} style={{ marginLeft:'5px' }}>1</Typography>
                            </Grid>
                            <Grid item lg={2} sm={3} xs={3}>
                                <Typography sx={{...content, textAlign:{md:'left', sm:'center', xs:'right'} }}>${item?.booking_cost}</Typography>
                            </Grid>
                        </Grid>
                </Box>
            ))}
            </Grid>
            <Box>
                <Divider sx={{borderColor: "#000000", width: "100%",borderBottomWidth:'2px'}}/>
            </Box>
            <Grid container  sx={{my:'13px'}}>
                <Grid lg={12} xs={12} sx={tableWrapper} item>
                    <Grid lg={5} item> </Grid>
                    <Grid lg={3} sm={4} xs={12} item>
                        <Typography sx={content}>Sub Total (CAD)</Typography>
                    </Grid>
                    <Grid lg={2} sm={5}  item>
                        <Typography sx={content}></Typography>
                    </Grid>
                    <Grid lg={2} sm={3} xs={12} item>
                        <Typography sx={{ ...content, textAlign:{md:'unset',sm:'center', xs:'right'} }}>${invoiceDetails?.cost}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Box>
                <Divider sx={{borderColor: "rgba(0, 0, 0, 0.52)", width: "100%"}}/>
            </Box>
          

            <Grid container  sx={{my:'5px'}}>
                <Grid lg={12} xs={12} item sx={tableWrapper} style={{marginBottom: '10px'}}>
                    <Grid lg={5} item></Grid>
                    <Grid lg={3} sm={4} xs={12} item>
                        <Typography sx={content}>Tax</Typography>
                    </Grid>
                    <Grid lg={2} sm={5} item>
                        <Typography sx={subHeading}></Typography>
                    </Grid>
                    <Grid lg={2} sm={3} xs={12} item>
                        <Typography sx={{...content,   textAlign:{md:'unset',sm:'center', xs:'right'}, mt:{md:'-5px',sm:'-5px'}}}>${invoiceDetails?.tax}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Box>
                <Divider sx={{borderColor: "rgba(0, 0, 0, 0.52)", width: "100%"}}/>
            </Box>
           
            <Grid container  sx={{my:'5px'}}>
                <Grid lg={12} xs={12} item sx={tableWrapper} style={{marginBottom: '10px'}}>
                    <Grid lg={5} item></Grid>
                    <Grid lg={3} sm={4} xs={12} item>
                        <Typography sx={content}>Tip</Typography>
                    </Grid>
                    <Grid lg={2} sm={5} item>
                        <Typography sx={subHeading}></Typography>
                    </Grid>
                    <Grid lg={2} sm={3} xs={12} item>
                        <Typography sx={{...content,   textAlign:{md:'unset',sm:'center', xs:'right'}, mt:{md:'-5px',sm:'-5px'}}}>${invoiceDetails?.tip ? invoiceDetails?.tip :"0.00"}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Box>
     {/* sx={{borderColor: "rgba(0, 0, 0, 0.52)", width: "100%"}} */}
            </Box>



            <Grid container  sx={{my:'5px'}}>
                <Grid lg={12} xs={12} item sx={tableWrapper} style={{marginBottom: '10px'}}>
                    <Grid lg={5} item></Grid>
                    <Grid lg={3} sm={4} xs={12} item>
                        <Typography sx={content}>Discount</Typography>
                    </Grid>
                    <Grid lg={2} sm={5} item>
                        <Typography sx={subHeading}></Typography>
                    </Grid>
                    <Grid lg={2} sm={3} xs={12} item>
                        <Typography sx={{...content,   textAlign:{md:'unset',sm:'center', xs:'right'}, mt:{md:'-5px',sm:'-5px'}}}>{invoiceDetails?.discount ? invoiceDetails?.discount :"0.00"}</Typography>
                    </Grid>
                </Grid>
            </Grid>
           
            <Box>
                <Divider style={{borderColor: "#000000", width: "100%",borderBottomWidth:'2px'}}/>
            </Box>
            <Grid container  sx={{my:'5px'}}>
                <Grid lg={12} xs={12} item sx={tableWrapper} style={{marginBottom: '10px'}}>
                    <Grid lg={5} item></Grid>
                    <Grid lg={3} sm={4} xs={12} item>
                        <Typography sx={subHeading}>Grand Total</Typography>
                    </Grid>
                    <Grid lg={2} sm={5} item>
                        <Typography sx={subHeading}></Typography>
                    </Grid>
                    <Grid lg={2} sm={3} xs={12} item>
                        <Typography sx={{...subHeading, color: '#04B4FF',fontSize:'24px',  textAlign:{md:'unset',sm:'center', xs:'right'}, mt:{md:'-5px',sm:'-5px'}}}>${invoiceDetails?.grand_total}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            {Object.entries(allInvoices)?.length > 0 &&
                Object.entries(allInvoices).map(([key, value], index) => (
                    <Grid container  sx={{my:'5px'}}>
                        <Grid lg={12} item sx={tableWrapper}>
                            <Grid lg={5} item>
                            </Grid>
                            <Grid lg={3} item>
                                <Typography sx={content}>{value?.payment_methods}</Typography>
                            </Grid>
                            <Grid lg={2} item>
                                <Typography sx={content}></Typography>
                            </Grid>
                            <Grid lg={2} item>
                                <Typography sx={{...content, ml:{sm:1, md:1, xs:1, lg:0}}}>-${value?.amount}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ))
            }
            <Box>
                <Divider style={{borderColor: "#000000", width: "100%",borderBottomWidth:'2px'}}/>
            </Box>
            <Grid container  sx={{my:'13px'}}>
                <Grid lg={12} item  xs={12} sx={tableWrapper} style={{marginBottom: '60px'}}>
                    <Grid lg={5} item></Grid>
                    <Grid lg={3} sm={4} xs={12} item>
                        <Typography sx={subHeading}>Due Amount</Typography>
                    </Grid>
                    <Grid lg={2} sm={5} item>
                        <Typography sx={subHeading}></Typography>
                    </Grid>
                    <Grid lg={2} sm={3} xs={12} item>
                        <Typography sx={{...subHeading, color: '#04B4FF',fontSize:'24px', textAlign:{md:'unset',sm:'center', xs:'right'}, mt:{md:'-5px',sm:'-5px'}}}>${invoiceDetails?.due_amount}</Typography>
                   </Grid>
                </Grid>
            </Grid>
            <div style={{
                width: "100%",
            }}>
                <Box sx={subFooter}>
                    <Typography>3dlifestyle.ca</Typography>
                    <Divider orientation='vertical'
                             style={{borderColor: "#9A9797", width: "20px", height: "20px"}}/>
                    <Typography>care@3dlifestyle.ca </Typography>
                    <Divider orientation='vertical'
                             style={{borderColor: "#9A9797", width: "20px", height: "20px"}}/>
                    <Typography>(416) 850-0231</Typography>

                </Box>
            </div>
        </Box>
    );
}

export default UserInvoices;