import React, { useEffect, useState } from "react";
import {
  AppBar,
  Autocomplete,
  Avatar,
  Box,
  Button,
  TextField,
  Toolbar,
  Typography, Container,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, removeUser } from "../../store/auth";
import Login from "../login";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Slide from "react-reveal/Slide";
import { useLocation, useNavigate, useParams } from "react-router";
import { confirmation } from "../../services/utills";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import useStyles from "./styles";
import { getAllClinic } from "../../store/home";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CustomModal from "../../components/modal/customModal";

const BookingHeader = ({ provider, setLocationOpen ,active}) => {
  
    const currentURL = window.location.href;
    const pathSegments = currentURL.split('/');
    const servicesIndex = pathSegments.indexOf('services');
    const serviceType = pathSegments[servicesIndex + 2];
  const [loginOpen, setOpenLogin] = useState(false);
  const location = useLocation();  
  const classes = useStyles();
  const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  const { slug } = useParams();
  const subcategorySlug = slug || serviceType;

  const selectedLocation = location?.state?.selectedLocation 
  console.log("location selected",selectedLocation,provider)
  const [selectedClinic, setSelectedClinic] = useState(selectedLocation ||provider || null);
  const homeStore = useSelector((state) => state?.homeStore);
  const { clinic } = homeStore;
  const authStore = useSelector((state) => state?.authStore);
  useEffect(() => {
    setSelectedClinic(selectedLocation || provider || null);
  }, [selectedLocation, provider]);

  const { user } = authStore;
  const shouldRedirectToLogin = !user?.id || user?.role_id !== 3;
  const localStorageKey = 'selectedlocation';
  const handleLogout = async () => {
    if (await confirmation("Are you want to logout", "Yes, Logout!")) {
      localStorage.removeItem("token");
      dispatch(removeUser());
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i];
          const eqPos = cookie.indexOf("=");
          const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      }


      // Optionally, force a page reload
      window.location.reload();
    }
  };
  useEffect(() => {
    (async () => {
         await dispatch(getAllClinic())
       
    })()
},[])  
  let capitalizedUserName = "";
if (user?.name) {
 
  capitalizedUserName = user.name.charAt(0).toUpperCase() + user.name.slice(1);
}
const clinicOptions =clinic.data && Array.isArray(clinic.data)
  ? clinic.data.map((clinic) => ({
      name: clinic?.name,
      id: clinic?.id,
    }))
  : [];
  const clinicId=selectedLocation?.id || localStorage.getItem('selectedLocationId')
console.log("provideridClinic",clinicId)
useEffect(() => {
  const savedLocation = localStorage.getItem(localStorageKey);
  if (savedLocation) {
      setSelectedClinic(JSON.parse(savedLocation));
  } 
}, []);
const backhandler= (selectedClinic) => {
  // handel
  console.log("location sending",selectedClinic)
  console.log("location sending from",selectedLocation)
  navigate(`/home`, { state: { selectedLocation: selectedClinic  } });

};

 
const handleClinicSelect = (event, newValue) => {
    localStorage.setItem('selectedLocationId', newValue?.id);
    setSelectedClinic(newValue);
  backhandler(newValue)
     localStorage.setItem(localStorageKey, JSON.stringify(newValue));

}; 
const handleprofile = () => {
        if (shouldRedirectToLogin) {
            setOpenLogin(true)
        } else {
            // Navigate to the booking page
            navigate('/user/user-account')
        }
    };
// subcategorySlug     
  useEffect(() => {

    (async () => {
      if (!user?.id && localStorage.getItem("token")) {
        await dispatch(getUserDetails());   
      }
    })();
  }, [user]);


  return (
    <>
      {loginOpen ? (
        <Box
          sx={{
            position: "fixed",
            left: "0",
            right: "0",
            bottom: "0",
            top: 0,
            zIndex: "1111111111111!important",
            
          }}
        >
          <Slide top>
            <Login loginOpen={loginOpen} setOpenLogin={setOpenLogin}  showFooter={true} />
          </Slide>
        </Box>
      ) : (
        ""
      )}

      <AppBar
        position="fixed"
        sx={{
          top: "0",
          bottom: "auto",
          minHeight: "69px",
          backgroundColor: "#fff",
          boxShadow: "none",
          borderBottom: "1px solid #E5E5E5",
          display:{sm:'block', xs:'none'}
        }}
      >
        <Toolbar sx={{ px: "48px!important", minHeight: "69px!important" }}>
          <Box
            component="div"
            sx={{
              flexGrow: 1,
              color: "black",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                background: "#006AFF",
                textTransform: "uppercase",
              }}
            >
              {provider?.name.charAt(0) || "3"}
            </Avatar>
            <Typography
              variant="h6"
              sx={{ ml: "16px", fontWeight: "500", fontSize: "1rem" }}
            >
              {provider?.name || "Select Location"}
            </Typography>
            <Typography
              sx={{
                ml: "16px",
                color: "red",
                cursor: "pointer",
                fontWeight: "600",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              onClick={() => setLocationOpen(true)}
            >   
              Change Location
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            {!user?.id || user?.role_id !== 3 ? (
              <Typography
                sx={{
                  color: "#006AFF",
                  fontWeight: "600",

                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={() => setOpenLogin(true)}
              >
                Sign in
              </Typography>
            ) : (
              ""  
            )}
            {user?.id && user?.role_id === 3 ? (
              <Typography
                sx={{
                  ml: "24px",
                  fontWeight: "600",
                  color: "#006AFF",
                }}
              >
                <AccountBalanceWalletIcon  style={{color: "#006AFF",marginRight:"8px",fontSize:"18px",marginBottom:"4px" }} />
                Wallet Balance: {user?.wallet_point || 0}
                
              </Typography>
              
            ) : (
              ""
            )}
            {user?.id && user?.role_id === 3 ? (
              <Typography
                sx={{
                  ml: "24px",
                  color: "#006AFF",
                  cursor: "pointer",
                  fontWeight: "600",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={() => navigate("/user/bookings")}
              >
                <CalendarTodayIcon style={{color: "#006AFF",marginRight:"8px",fontSize:"18px",marginBottom:"4px" }} />
                My appointment
              </Typography>
            ) : (
              ""
            )}  
            {user?.id && user?.role_id === 3 ? (
              <Typography
                sx={{
                  ml: "24px",
                  fontWeight: "600",
                  color: "#006AFF",
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={() => handleLogout()}
              >
                <PowerSettingsNewIcon style={{color: "#006AFF",marginRight:"8px",fontSize:"20px",marginBottom:"4px" }} />
                Sign out
              </Typography>
            ) : (
              ""
            )}
          </Box>     
        </Toolbar>
      </AppBar>
        <CustomModal open={isOpen} setOpen={setIsOpen} background={"#f4ede3"}>
            <Autocomplete
                className={classes.selectParent}
                clearIcon={null}
                disablePortal
                id="combo-box-demo"
                options={clinicOptions}
                value={selectedClinic}
                onChange={(event, newValue) => {
                    handleClinicSelect(event, newValue);
                    if (newValue) {
                        setSelectedClinic(newValue); // Update the selected clinic state
                        setIsOpen(false); // Close the modal
                    }
                }}
                sx={{ mt: 4 }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        sx={{ label: { mt: "-10px", zIndex: 0 } }}
                        label="Select Your Default Clinic"
                    />
                )}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) =>
                    option.id === (value.id || id)
                }
            />
        </CustomModal>
      <Container maxWidth="xs" sx={{ mt:4 }}>

      <Box sx={{ display:{sm:'none', xs:'block'} }}>
        {/*<Autocomplete*/}
        {/* clearIcon={null} */}
        {/*  className={classes.selectParent}*/}
        {/*  disablePortal*/}
        {/*  id="combo-box-demo" */}
        {/*  options={clinicOptions}*/}
        {/*  value={selectedClinic}*/}
        {/*  onChange={handleClinicSelect}*/}
        {/*  renderInput={(params) => (*/}
        {/*    <TextField*/}
        {/*      {...params}*/}
        {/*      sx={{ label: { mt: "-10px" , zIndex:0} }}*/}
        {/*      label="Select Your Clinic Location"*/}
        {/*    />*/}
        {/*  )} */}
        {/*  getOptionLabel={(option) => option.name}*/}
        {/*  getOptionSelected={(option, value) => option.id === value.id}*/}
        {/*/>*/}
 {user?.id && user?.role_id === 3 ? (
          
          <Box sx={{ display: "flex", justifyContent: "space-between", my: 4, }}>
          {/*<Box onClick={backhandler}>*/}
          {/*    <ArrowBackIcon />*/}
          {/*</Box>*/}
          <Typography
                  variant="subtitle1"
                  color="#0274be"
                  sx={{ textDecoration:'underline' }}
                  onClick={() => setIsOpen(true)}
              >
                  <LocationOnIcon sx={{ fontSize: '20px', marginTop: '-5px' }} />
                  {selectedClinic ? selectedClinic.name : "Select Your Default Clinic"}
           </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", }}>
          <Box sx={{ display: "flex", justifyContent: "end", }}>
          <AccountBalanceWalletIcon />
          <sup style={{ fontWeight:'600',left:'-19px',top:'-8px' }}>{user?.wallet_point || 0}</sup>
        </Box>
        <Box onClick={handleprofile} sx={{ backgroundColor:'rgba(235, 213, 194, 1)',width:'30px',height:'30px',borderRadius:'20px',display:'flex',alignItems:'center',justifyContent:'center'  }}>
          <Typography sx={{ color:'#000',fontSize:'16px',fontWeight:'500'}}>{capitalizedUserName.charAt(0)}</Typography>
        </Box>

        
        </Box>
        </Box>
        ) : (
          ""
        )}
 {!user?.id || user?.role_id !== 3 ? (
        
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
        {/*<Box >*/}
        {/*    <KeyboardBackspaceIcon onClick={backhandler} />*/}
        {/*  </Box>*/}
          <Typography
                variant="subtitle1"
                color="#0274be"
                sx={{ textDecoration:'underline' }}
                onClick={() => setIsOpen(true)}
            >
                <LocationOnIcon sx={{ fontSize: '20px', marginTop: '-5px' }} />
                {selectedClinic ? selectedClinic.name : "Select Your Default Clinic"}
          </Typography>
          <PersonIcon  onClick={() => setOpenLogin(true)}/>

        </Box>
    
           
           ) : (
             ""
           )}
      
      </Box>

      </Container>


    </>
  );
};

export default BookingHeader;
